import { createAction } from '@reduxjs/toolkit';

import PeopleService from 'services/peopleService';
import userService from 'services/userService';
import {
  AdminUser,
  ErrorAsyncThunk,
  GoogleAdminUser,
  NewAdminUser,
  UserRequestType,
  LoginResponse,
  UserResponse
} from 'types';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';

export const updateSession = createAction('UPDATE_SESSION', session => ({ payload: session }));

export const login = createAsyncThunk<LoginResponse, void>(
  'LOGIN',
  async (adminUser: AdminUser) => {
    try {
      let resourceResponse = null;

      const {
        data: { adminUser: adminUserResponse }
      } = await userService.login(adminUser);

      if (adminUserResponse.personId) {
        const { data } = await PeopleService.getResource(adminUserResponse.personId);
        resourceResponse = data;
      }

      return { user: adminUserResponse, resource: resourceResponse };
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const loginGoogle = createAsyncThunk<LoginResponse, void>(
  'LOGIN_GOOGLE',
  async (adminUser: GoogleAdminUser) => {
    try {
      let resourceResponse = null;

      const {
        data: { adminUser: adminUserResponse }
      } = await userService.loginGoogle(adminUser);

      if (adminUserResponse.personId) {
        const { data } = await PeopleService.getResource(adminUserResponse.personId);
        resourceResponse = data;
      }

      return { user: adminUserResponse, resource: resourceResponse };
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const logout = createAsyncThunk<boolean, void>('LOGOUT', async () => {
  try {
    await userService.logout();
  } catch (error) {
    const {
      response: { data }
    } = error as ErrorAsyncThunk;
    throw parseError(data);
  }
});

export const signUp = createAsyncThunk<UserResponse, void>('SIGNUP', async (user: NewAdminUser) => {
  try {
    const { data } = await userService.signUp(user);
    return data;
  } catch (error) {
    const {
      response: { data }
    } = error as ErrorAsyncThunk;
    throw parseError(data);
  }
});

export const saveExperience = createAsyncThunk(
  'SAVE_EXPERIENCE',
  async (payload: UserRequestType) => {
    try {
      const { token, userId, experienceId, notes, industries, years, months, skillId } = payload;
      const { data } = await PeopleService.saveExperience(userId, {
        experienceId,
        token,
        notes,
        industries,
        years,
        months,
        skillId
      });
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);
