/**
 * @param  {array} list List of items to filter by `searchTerm`
 * @param  {string} searchTerm String to filter list's items
 * @param  {?function} propertySelector Item's property to search by. Defaults to `item => item.name`
 * @returns {array} filteredList List filtered by the search term
 */

export type PeopleItem = {
  name?: string;
  fullName?: string;
};

export const filterList = <T>(
  list: T[],
  searchTerm: string | null,
  propertySelector: (item: T) => string
) => {
  if (searchTerm) {
    return list.filter(item =>
      propertySelector(item)
        ?.toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(
          searchTerm
            .toLowerCase()
            .trim()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        )
    );
  }
  return list;
};
