import styled from 'styled-components';

export const ExperienceRowContainer = styled.div`
  transition: width 0.1s ease-in-out;
  width: 100%;
`;

export const RowInputWrapper = styled.div`
  transition: width 0.1s ease-in-out;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '48%')};
  margin-right: 0.8rem;
`;

export const ErrorSpan = styled.span`
  ${({ theme }) => theme.typography.italic}
  color: ${props => props.theme.colors.red};
  font-size: 1.4rem;
  line-height: 3rem;
  display: flex;
`;
