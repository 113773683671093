import { AxiosResponse } from 'axios';

import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';
import { Client, Department, Industry, Skill, Role } from 'types';

class TimelineService {
  static async getSkills(allSkills: boolean = true): Promise<AxiosResponse<{ skills: Skill[] }>> {
    return httpClient.get(ENDPOINTS.skills(allSkills));
  }

  static async getCustomer(): Promise<AxiosResponse<{ clients: Client[] }>> {
    return httpClient.get(ENDPOINTS.clients);
  }

  static async getDepartments(): Promise<AxiosResponse<{ departments: Department[] }>> {
    return httpClient.get(ENDPOINTS.departments);
  }

  static async getLocations(): Promise<AxiosResponse<{ locations: Location[] }>> {
    return httpClient.get(ENDPOINTS.locations);
  }

  static async getRoles(): Promise<AxiosResponse<{ roles: Role[] }>> {
    return httpClient.get(ENDPOINTS.roles);
  }

  static async getIndustries(): Promise<AxiosResponse<{ industries: Industry[] }>> {
    return httpClient.get(ENDPOINTS.industries);
  }
}

export default TimelineService;
