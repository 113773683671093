import dayjs from 'dayjs';

type Month = {
  underContract: number;
  actualRevenue: number;
  awaitingStart: number;
};

export const getForecastingGraphData = (yearMonths: Month[]) =>
  yearMonths?.map((month: Month, index: number) => {
    const underContractDiff = month.underContract - month.actualRevenue;
    const awaitingStartDiff = month.awaitingStart - underContractDiff;

    return {
      ...month,
      underContractDiff,
      awaitingStartDiff,
      name: dayjs().set('month', index).format('MMM, YYYY')
    };
  });
