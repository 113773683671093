import localForage from 'localforage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { SessionReducer } from 'types';

import assignmentReducer from './assignmentReducer';
import financialReducer from './financialReducer';
import notificationReducer from './notificationReducer';
import peopleReducer from './peopleReducer';
import projectReducer from './projectReducer';
import sessionReducer from './sessionReducer';
import settingsReducer from './settingsReducer';
import statusReducer from './statusReducer';
import timelineReducer from './timelineReducer';
import uiReducer from './uiReducer';

const sessionPersistConfig = {
  key: 'session',
  storage: localForage,
  whitelist: ['authenticated', 'info', 'user', 'resource'],
  stateReconciler: autoMergeLevel2
};

const rootReducer = () =>
  combineReducers({
    session: persistReducer<SessionReducer>(sessionPersistConfig, sessionReducer),
    statusReducer,
    timeline: timelineReducer,
    project: projectReducer,
    notification: notificationReducer,
    people: peopleReducer,
    assignment: assignmentReducer,
    ui: uiReducer,
    financial: financialReducer,
    settings: settingsReducer
  });

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;

export default rootReducer;
