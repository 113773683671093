import { CAPACITY_UTILIZATION } from 'constants/dashboardConstants';
import { Option, TimeOff } from 'types';

import {
  CommonDateType,
  getDiffDays,
  getRequestFormatDate,
  getWorkingDiffDays,
  maxDate,
  minDate
} from './date';
import { accumulator } from './helpers';
import { Assignment, getCalendarWithWorkHours } from './timelineUtilities';

const extractValue = (field: Option[], key: keyof Option = 'id') =>
  field.filter(option => option.value).map(option => option[key]);

type CommonFilters = {
  startDate: string;
  endDate: string;
} & Record<string, unknown>;

type PeopleFilters = {
  skills: Option[];
  departments: Option[];
  seniorities: Option[];
} & CommonFilters;
export const filtersToParamsPeople = ({
  skills,
  departments,
  startDate,
  endDate,
  seniorities,
  ...filters
}: PeopleFilters) => ({
  ...filters,
  skillIds: extractValue(skills),
  departmentIds: extractValue(departments),
  seniorities: extractValue(seniorities, 'key'),
  startDate: getRequestFormatDate(startDate),
  endDate: getRequestFormatDate(endDate)
});

type ReportsFilters = {
  skills: Option[];
  departments: Option[];
  roles: Option[];
} & CommonFilters;
export const filtersToParamsReports = ({
  skills,
  departments,
  roles,
  startDate,
  endDate,
  ...filters
}: ReportsFilters) => ({
  ...filters,
  skillIds: extractValue(skills),
  departmentIds: extractValue(departments),
  roleIds: extractValue(roles),
  startDate: getRequestFormatDate(startDate),
  endDate: getRequestFormatDate(endDate)
});

export const filtersToParamsAssignment = ({ startDate, endDate, ...filters }: CommonFilters) => ({
  ...filters,
  startDate: getRequestFormatDate(startDate),
  endDate: getRequestFormatDate(endDate)
});

type RevenueGraph = {
  revenue: number;
  percentage: number;
  id: number;
  name: string;
};
export const revenueGraphData = (data: RevenueGraph[]) =>
  data?.map(item => {
    const { revenue, percentage } = item;
    return {
      ...item,
      labelValue: {
        revenue,
        percentage
      }
    };
  });

export const getResourceAvailability = (
  assignments: Assignment[],
  workHours: number,
  availableWorkHours: number,
  startDatePeriod: CommonDateType,
  endDatePeriod: CommonDateType
) => {
  const calendar = getCalendarWithWorkHours(assignments, startDatePeriod, endDatePeriod);
  const assignmentsWorkHours = calendar.reduce((acc, calendarWorkHours) => acc + calendarWorkHours);
  const totalDays = getDiffDays(startDatePeriod, endDatePeriod) + 1;
  const totalCapacity = totalDays * (availableWorkHours || workHours);

  const availableHours = totalCapacity - assignmentsWorkHours;

  return (availableHours * 100) / totalCapacity;
};

type Resource = {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  role: string;
  assignments: Assignment[];
  timeOffs: TimeOff[];
  workHours: number;
  availableWorkHours: number;
  avatarUrl: string;
  hireDate: CommonDateType;
  exitDate: CommonDateType;
} & Record<string, unknown>;
export const convertResourcesDataToTableFormat = (
  resources: Resource[],
  monthFirstDay: CommonDateType,
  monthLastDay: CommonDateType
) =>
  resources?.map(
    ({
      firstName,
      lastName,
      fullName,
      role,
      assignments,
      timeOffs,
      workHours,
      availableWorkHours,
      avatarUrl,
      hireDate,
      exitDate,
      ...rest
    }) => {
      const getFullName =
        fullName ?? (lastName ? `${firstName ? `${firstName} ` : ''}${lastName || ''}` : 'N/A');

      return {
        ...rest,
        mainInfo: {
          role,
          fullName: getFullName,
          avatarUrl
        },
        assignments,
        availableWorkHours,
        workHours,
        availability: { assignments, timeOffs, workHours, availableWorkHours, hireDate, exitDate },
        availablePercentage: getResourceAvailability(
          assignments,
          workHours,
          availableWorkHours,
          monthFirstDay,
          monthLastDay
        )
      };
    }
  );

export type ConvertedResourceToTable = ReturnType<typeof convertResourcesDataToTableFormat>;

type BenchRow = {
  department: string;
  mainInfo?: {
    role: string;
  };
  mainSkills?: string[];
  skills?: string[];
  availablePercentage: number;
  hasPendingAssignments?: boolean;
};

type Filter = {
  field: string;
  label: string;
  key: string;
};
export const filterBenchList = (
  rows: BenchRow[],
  activeFilters: Filter[],
  hidePending: boolean,
  highlighted: boolean
) => {
  if (!rows?.length) return [];

  const filterKeys = ['departments', 'roles', 'skills', 'availability'];
  const [departments, roles, skills] = filterKeys.map(key =>
    activeFilters.filter(({ field }) => field === key)?.map(({ label }) => label)
  );

  const availabilities = activeFilters
    .filter(({ field }) => field === 'availability')
    ?.map(({ key }) => key);

  let filteredRows = [...rows];

  if (departments.length) {
    filteredRows = filteredRows.filter(({ department }) => departments.includes(department));
  }

  if (roles.length) {
    filteredRows = filteredRows.filter(
      ({ mainInfo }) => mainInfo?.role && roles.includes(mainInfo.role)
    );
  }

  if (skills.length) {
    filteredRows = filteredRows.filter(row =>
      skills.some(skill =>
        highlighted
          ? row.mainSkills?.includes(skill)
          : row.mainSkills?.includes(skill) || row.skills?.includes(skill)
      )
    );
  }

  if (availabilities.length) {
    filteredRows = filteredRows.filter(({ availablePercentage }) =>
      availabilities.some(availability => {
        const [rangeStart, rangeEnd] = availability.split('-');

        return (
          availablePercentage <= parseFloat(rangeEnd) &&
          availablePercentage >= parseFloat(rangeStart)
        );
      })
    );
  }

  return hidePending ? filteredRows.filter(row => !row.hasPendingAssignments) : filteredRows;
};

export const getOverviewCurrentDayData = (data: Record<string, string>) => {
  if (!data) return [];
  return [
    {
      label: CAPACITY_UTILIZATION.demand.intl,
      value: data[CAPACITY_UTILIZATION.demand.key],
      color: CAPACITY_UTILIZATION.demand.stroke
    },
    {
      label: CAPACITY_UTILIZATION.scheduled.intl,
      value: data[CAPACITY_UTILIZATION.scheduled.key],
      color: CAPACITY_UTILIZATION.scheduled.stroke
    },
    {
      label: CAPACITY_UTILIZATION.billingCapacity.intl,
      value: data[CAPACITY_UTILIZATION.billingCapacity.key],
      color: CAPACITY_UTILIZATION.billingCapacity.stroke
    }
  ];
};

export const getResourcesAvailability = (
  resources: Resource[],
  startDatePeriod: CommonDateType,
  endDatePeriod: CommonDateType
) => {
  const benchHoursArray: number[] = [];

  const daysOfWorkInPeriod = (
    possibleStartDate: CommonDateType,
    possibleEndDate: CommonDateType
  ) => {
    const startDate = maxDate(possibleStartDate, startDatePeriod);
    const endDate = minDate(possibleEndDate, endDatePeriod);
    return getWorkingDiffDays(startDate, endDate);
  };

  resources.map(resource => {
    const totalPersonHours =
      resource?.availableWorkHours * daysOfWorkInPeriod(resource?.hireDate, resource?.exitDate);
    const allocationHoursArray = resource?.assignments.map(assignment => {
      if (!assignment.support && !assignment.pending) {
        return (
          assignment?.workHours * daysOfWorkInPeriod(assignment?.startDate, assignment?.endDate)
        );
      }
      return 0;
    });
    const allocationHours = accumulator(allocationHoursArray);
    const benchHours = totalPersonHours - allocationHours;

    benchHoursArray.push(benchHours);
  });

  const availableHours = accumulator(benchHoursArray);
  const workingDaysHours = daysOfWorkInPeriod(startDatePeriod, endDatePeriod) * 7.5;

  return Math.round(availableHours / workingDaysHours);
};
