import dayjs from 'dayjs';
import { sortBy } from 'lodash';

import { BILLING_UNITS } from 'constants/constants';
import { REQUEST_DATE } from 'constants/dateFormats';
import { DEFAULT_CAPACITY_HOURS } from 'constants/projectConstants';
import { TimeOff } from 'types';
import {
  addTime,
  CommonDateType,
  dateToString,
  diffTime,
  getTimestamp,
  isAfter,
  isBefore
} from 'utils/date';

import { precisionFormat } from './number';

const { HOURLY, DAILY } = BILLING_UNITS;

const getResourceAssignmentMainState = (
  dateNow: dayjs.Dayjs,
  startDate: CommonDateType,
  endDate: CommonDateType,
  isResourceRepeated: boolean,
  timeOffs: TimeOff[]
) => {
  let vacationData = null;
  const timeRange = dateNow.add(30, 'day');
  const isGettingAssigned = !isResourceRepeated && isAfter(startDate, dateNow);
  const isUpcomingChanges = isResourceRepeated && isAfter(startDate, dateNow);
  const isOut = !isGettingAssigned && isAfter(dateNow, endDate);
  const isLeave =
    !isOut &&
    isAfter(dateNow, startDate) &&
    isBefore(dateNow, endDate) &&
    isBefore(endDate, timeRange);
  !isLeave &&
    timeOffs?.forEach(({ startDate, endDate, timeType }) => {
      if (timeType === 'Vacation' && isAfter(dateNow, startDate) && isBefore(dateNow, endDate)) {
        vacationData = {
          startDate,
          endDate,
          returnDate: dateToString(addTime(endDate, 1, 'day'), REQUEST_DATE),
          duration: diffTime(startDate, endDate, 'day')
        };
      }
    });
  return { isLeave, isOut, isGettingAssigned, isUpcomingChanges, vacationData };
};

const getIsResourceRepeated = (
  resources: Array<{ resourceName: string }>,
  resourceName: string,
  index: number
) => resources.some((resource, i) => resource.resourceName === resourceName && i !== index);

export const getResourceStateVariables = (
  dateNow: dayjs.Dayjs,
  resources: Array<{ resourceName: string }>,
  {
    resourceName,
    startDate,
    endDate,
    index,
    timeOffs
  }: {
    resourceName: string;
    startDate: CommonDateType;
    endDate: CommonDateType;
    index: number;
    timeOffs: TimeOff[];
  }
) => {
  const isResourceRepeated = getIsResourceRepeated(resources, resourceName, index);
  const resourceStates = getResourceAssignmentMainState(
    dateNow,
    startDate,
    endDate,
    isResourceRepeated,
    timeOffs
  );
  const { isOut, isGettingAssigned } = resourceStates;
  const grayRow = isOut || isGettingAssigned;
  return { grayRow, resourceStates };
};

export const composeEditFinancial = ({
  clientBudget,
  costsAdjustment,
  billedAdjustment
}: {
  clientBudget: number | string;
  costsAdjustment: number | string;
  billedAdjustment: number | string;
}) => ({
  clientBudget: precisionFormat(clientBudget),
  costsAdjustment: precisionFormat(costsAdjustment),
  billedAdjustment: precisionFormat(billedAdjustment)
});

export const composedResourceSetup = (resources: Array<Record<string, unknown>>) => ({
  setupsInfo: resources.map(
    ({ setupId, allocationPercentage, billable, rate, discountPercentage }) => ({
      setupId,
      allocationPercentage,
      billable,
      rate,
      discountPercentage
    })
  )
});

export const convertCostsToBillingUnit = (
  {
    from,
    to,
    capacity = DEFAULT_CAPACITY_HOURS
  }: Partial<{ from: BILLING_UNITS; to: BILLING_UNITS; capacity: number }> = {},
  value: number
) => {
  let result;
  switch (true) {
    case from === to:
      result = value;
      break;
    case from === HOURLY && to === DAILY:
      result = value * capacity;
      break;
    case from === DAILY && to === HOURLY:
      result = value / capacity;
      break;
    default:
      return new Error('The from or to params values are not valid');
  }
  return Number(result);
};

export const convertHoursToBillingUnit = (
  {
    from,
    to,
    capacity = DEFAULT_CAPACITY_HOURS,
    allocationPercentage = 100
  }: Partial<{
    from: BILLING_UNITS;
    to: BILLING_UNITS;
    capacity: number;
    allocationPercentage: number;
  }> = {},
  value: number
) => {
  let result;
  switch (true) {
    case from === to:
      result = value;
      break;
    case from === HOURLY && to === DAILY:
      result = (value * 100) / allocationPercentage / capacity;
      break;
    case from === DAILY && to === HOURLY:
      result = value * capacity * (allocationPercentage / 100);
      break;
    default:
      return new Error('The from or to params values are not valid');
  }
  return Number(result);
};

export const addBillingUnitToResourceSetup = (
  resources: Array<{ rate: number; capacity: number }>
) =>
  resources.map(({ rate: rateValue, capacity, ...rest }) => ({
    ...rest,
    rate: {
      [HOURLY]: rateValue,
      [DAILY]: convertCostsToBillingUnit({ from: HOURLY, to: DAILY, capacity }, rateValue)
    },
    capacity
  }));

export const rangeValidator = (values: { floatValue: number }, limit: number) => {
  const { floatValue } = values;
  return (floatValue >= 0 && floatValue <= limit) || !floatValue;
};

export const percentageValidator = (values: { floatValue: number }) => rangeValidator(values, 100);

export const allocationValidator = (values: { floatValue: number }, billingUnit: string) =>
  billingUnit === BILLING_UNITS.DAILY ? rangeValidator(values, 100) : rangeValidator(values, 1000);

export const billValidator = (values: { floatValue: number }, billingUnit: string) =>
  billingUnit === BILLING_UNITS.DAILY
    ? rangeValidator(values, 100000)
    : rangeValidator(values, 1000000);

export const getResourcesUpdatedLists = (
  currentSetup: Record<string, unknown>[] | undefined,
  newSetup: Record<string, unknown>[]
) => {
  const updatedList: Array<{ current: Record<string, unknown>; updated: Record<string, unknown> }> =
    [];
  const resourcesUpdatedList: Array<{ isRemoved: boolean; isAdd: boolean }> = [];

  currentSetup?.forEach(item => {
    const newSetupElement = newSetup.find(element => element.assignmentId === item.assignmentId);
    if (!newSetupElement) {
      resourcesUpdatedList.push({ ...item, isRemoved: true, isAdd: false });
    } else {
      const {
        seniority: newSeniority,
        allocationPercentage: newAllocationPercentage,
        rate: newRate,
        startDate: newStartDate,
        endDate: newEndDate
      } = newSetupElement;
      const { seniority, allocationPercentage, rate, startDate, endDate } = item;
      if (
        seniority !== newSeniority ||
        allocationPercentage !== newAllocationPercentage ||
        rate !== newRate ||
        newStartDate !== startDate ||
        newEndDate !== endDate
      )
        updatedList.push({ current: item, updated: newSetupElement });
    }
  });

  newSetup?.forEach(item => {
    if (!currentSetup?.find(element => element.assignmentId === item.assignmentId))
      resourcesUpdatedList.push({ ...item, isRemoved: false, isAdd: true });
  });

  return [sortBy(updatedList, 'resourceName'), sortBy(resourcesUpdatedList, 'resourceName')];
};

export const handlePriceWithDiscount = (price: number, discount: number) => {
  if (!discount) return;
  if (discount === 100) return 0;
  return price - price * (discount / 100);
};

export const sortByNameAndStartDate = <T extends Record<string, string>>(
  resources: T[],
  nameKey: keyof T
) =>
  [...resources].sort(
    (a, b) =>
      a[nameKey].localeCompare(b[nameKey]) || getTimestamp(a.startDate) - getTimestamp(b.startDate)
  );

export const getConvertedValue = ({
  valueToConvert,
  capacity,
  percentage,
  billingUnit,
  hours = true
}: {
  valueToConvert: number;
  capacity: number;
  percentage: number;
  billingUnit: BILLING_UNITS;
  hours: boolean;
}) => {
  if (!valueToConvert) return 0;
  if (billingUnit === HOURLY) return valueToConvert;
  if (hours) {
    return convertHoursToBillingUnit(
      { from: HOURLY, to: DAILY, capacity, allocationPercentage: percentage },
      valueToConvert
    );
  }
  return convertCostsToBillingUnit({ from: HOURLY, to: DAILY, capacity }, valueToConvert);
};
