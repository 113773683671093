import { AxiosResponse } from 'axios';

import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';
import { NotificationResponse } from 'types/notifications';

class NotificationService {
  static getNotificationsHeader(): Promise<AxiosResponse<NotificationResponse>> {
    return httpClient.get(ENDPOINTS.notifications, {
      params: null
    });
  }

  static markNotificationAsRead({ notificationId }: { notificationId: number }) {
    return httpClient.put(ENDPOINTS.getNotification(notificationId), {
      notification: {
        read: true
      }
    });
  }

  static markNotificationAsSeen() {
    return httpClient.post(ENDPOINTS.markNotification);
  }

  static async deleteNotification(notificationId: number) {
    return httpClient.delete(ENDPOINTS.getNotification(notificationId));
  }

  static async acknowledgeNotification(notificationId: number) {
    return httpClient.post(ENDPOINTS.acknowledgeNotification(notificationId));
  }
}

export default NotificationService;
