import { ReactNode } from 'react';

import { EVENT_TYPE } from 'constants/constants';
import { getRequestFormatDate } from 'utils/date';
import { getTimelineFragments } from 'utils/timelineUtilities';

type Event = {
  startDate: string;
  endDate: string;
  type: EVENT_TYPE;
  children: ReactNode;
};

const showEvent = (value: Event, startDateTimeline: string, endDateTimeline: string) =>
  value &&
  value.startDate < getRequestFormatDate(endDateTimeline) &&
  value.endDate > getRequestFormatDate(startDateTimeline);

export const getResourceEventsTimeLineFragments = (
  events: Event[],
  startDateTimeline: string,
  endDateTimeline: string
) => {
  const personEventsFragments = events.length
    ? getTimelineFragments(
        startDateTimeline,
        endDateTimeline
      )(events.filter(event => showEvent(event, startDateTimeline, endDateTimeline)))
    : [];

  return personEventsFragments;
};
