import axios from 'axios';

const APPLICATION_JSON = 'application/json';
const CONTENT_TYPE = 'Content-Type';

const getQueryParam = (name: string) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
};

const client = axios.create({
  baseURL: (process.env.API_CONFIGURABLE && getQueryParam('api_url')) || process.env.API_URL,
  headers: {
    Accept: APPLICATION_JSON,
    [CONTENT_TYPE]: APPLICATION_JSON
  }
});

export default client;
