import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { COLUMNS, EXPORT_COLUMNS_KEYS, INITIAL_FILTERS } from 'constants/benchInsights';
import { BENCH_INSIGHT_EXPORT_FILENAME, SORT_DIR } from 'constants/constants';
import { getDashboardBenchList } from 'state/actions/dashboardActions';
import {
  convertResourcesDataToTableFormat,
  filterBenchList,
  getResourceAvailability,
  getResourcesAvailability
} from 'utils/dashboardUtilities';
import { getFirstAndLastDayOfMonth } from 'utils/date';
import { downloadCsv } from 'utils/downloadCsv';
import { formatField } from 'utils/export';
import { initializeOptionsWithFieldName } from 'utils/helpers';
import { sortTable } from 'utils/projectsOverviewUtilities';

import useRequest from './useRequest';
import useRoles from './useRoles';
import useSkills from './useSkills';
import useTeams from './useTeams';

const useBenchInsights = () => {
  const intl = useIntl();
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const { departments, roles, skills, availability, startDate, endDate } = filters;

  const [sortConfig, setSortConfig] = useState({
    key: 'mainInfo',
    direction: SORT_DIR.asc
  });

  const { formattedTeams } = useTeams(true);
  const { rolesOptions } = useRoles();
  const { skillOptions } = useSkills();

  const [{ isPending: isLoading, response }, getDashboardList] = useRequest(getDashboardBenchList, {
    initialData: [],
    params: {
      startDate,
      endDate
    }
  });

  const activeFilters = [departments, roles, skills, availability]
    ?.map(filterItem => filterItem?.filter(option => option.value))
    .flat();

  const { startDate: monthFirstDay, endDate: monthLastDay } = getFirstAndLastDayOfMonth(
    dayjs(filters.startDate)
  );

  const formattedRows = useMemo(
    () => convertResourcesDataToTableFormat(response?.list, monthFirstDay, monthLastDay),
    [monthFirstDay, monthLastDay, response?.list]
  );

  const filteredRows = useMemo(
    () => filterBenchList(formattedRows, activeFilters, filters.hidePending, filters.highlighted),
    [formattedRows, activeFilters, filters.hidePending, filters.highlighted]
  );

  const availableFTE = useMemo(
    () => getResourcesAvailability(filteredRows, monthFirstDay, monthLastDay),
    [filteredRows, monthFirstDay, monthLastDay]
  );

  const sortedRows = useMemo(() => {
    if (!sortConfig) return filteredRows;
    return sortTable({ rows: filteredRows, sortConfig, columns: COLUMNS });
  }, [filteredRows, sortConfig]);

  const clearFilters = () => {
    setFilters({
      ...INITIAL_FILTERS,
      skills: initializeOptionsWithFieldName(skillOptions, 'skills'),
      departments: initializeOptionsWithFieldName(formattedTeams, 'departments'),
      roles: initializeOptionsWithFieldName(rolesOptions, 'roles')
    });
  };
  const onSort = ({ key: columnKey }) => {
    let direction = SORT_DIR.desc;
    if (sortConfig && sortConfig.key === columnKey && sortConfig.direction === SORT_DIR.desc) {
      direction = SORT_DIR.asc;
    }
    setSortConfig({
      key: columnKey,
      direction
    });
  };

  const exportTable = () => {
    const exportRows = [];

    sortedRows.forEach(row => {
      const {
        mainInfo,
        department,
        seniority,
        mainSkills,
        currentProjects,
        daysOnBench,
        availability,
        pending
      } = EXPORT_COLUMNS_KEYS;

      const allocationPercentage = getResourceAvailability(
        row.assignments,
        row.workHours,
        row.availableWorkHours,
        startDate,
        endDate
      );

      exportRows.push({
        [mainInfo]: formatField(row[mainInfo].fullName),
        [department]: formatField(row[department]),
        [seniority]: formatField(row[seniority]),
        [mainSkills]: formatField(row[mainSkills]),
        [currentProjects]: formatField(row[currentProjects]),
        [daysOnBench]: formatField(row[daysOnBench], 0),
        [availability]: `${formatField(allocationPercentage)}%`,
        [pending]: row.hasPendingAssignments
      });
    });

    const headers = Object.values(EXPORT_COLUMNS_KEYS).map(columnKey =>
      intl.formatMessage({
        id: `insights.bench.benchList.table.export.columns.${columnKey}`
      })
    );

    const csvContent = generateCSVContent(headers, exportRows);
    downloadCsv(csvContent, BENCH_INSIGHT_EXPORT_FILENAME(startDate, endDate));
  };

  const generateCSVContent = (headers, rows) => {
    const headerString = headers.join(',');
    const rowStrings = rows.map(row =>
      Object.values(row)
        .map(value => `"${value}"`)
        .join(',')
    );
    return [headerString, ...rowStrings].join('\n');
  };

  return {
    rows: sortedRows,
    filters,
    activeFilters,
    sortConfig,
    availableFTE,
    getDashboardList,
    isLoading,
    exportTable,
    clearFilters,
    setFilters,
    onSort
  };
};

export default useBenchInsights;
