import { camelize } from 'humps';

import { ASSIGNMENT_STATUS, projectStatus } from 'constants/projectConstants';

export const initialStatus = ({ name }: { name: string }) => {
  let status = camelize(name || 'confirmed');
  if (status === 'active') status = 'confirmed';
  return projectStatus[status];
};

export const getAssignmentStatus = (personId: number, pending: boolean) => {
  let status = ASSIGNMENT_STATUS.open;
  if (personId) {
    if (pending) {
      status = ASSIGNMENT_STATUS.pending;
    } else {
      status = ASSIGNMENT_STATUS.confirmed;
    }
  }

  return status;
};
