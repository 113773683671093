import styled from 'styled-components';

import { ReactComponent as TickSvg } from 'components/icons/tick.svg';
import { ReactComponent as WarningSvg } from 'components/icons/warning.svg';

export const LoadExperienceFormContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 1.5rem;
  margin: 0 auto auto;
  min-width: 55rem;
  padding: 3rem 5rem 5rem;
  width: fit-content;
`;

export const LoadExperienceTitle = styled.h2`
  ${({ theme }) => theme.typography.medium};
  font-size: 2.1rem;
  margin-bottom: 2rem;
`;

export const ErrorContainer = styled.div`
  ${({ theme }) => theme.typography.semiBold}
  align-content: center;
  background: ${({ theme }) => theme.colors.peach};
  border-radius: 0.8rem;
  color: ${({ theme }) => theme.colors.red};
  display: flex;
  font-size: 1.5rem;
  margin: 0 0 2rem;
  padding: 1.3rem 1rem;
`;

export const SuccessContainer = styled.div`
  ${({ theme }) => theme.typography.semiBold}
  align-content: center;
  background: ${({ theme }) => theme.colors.lightGreen};
  border-radius: 0.8rem;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: 1.5rem;
  margin: 0 0 2rem;
  padding: 1.3rem 1rem;
`;

export const LoadExperienceContainer = styled.div`
  margin: 9.5rem auto auto;
  width: fit-content;
`;

export const StyledWarningSvg = styled(WarningSvg)`
  margin: 0.1rem 1rem auto 6rem;
`;

export const StyledSuccessSvg = styled(TickSvg)`
  margin: auto 1rem auto 6rem;
`;
