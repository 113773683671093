import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'index';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (action: any) => {
  const useAppDispatch = () => useDispatch<AppDispatch>();
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useCallback((...payload: any) => dispatch(action(...payload)), [dispatch, action]);
};
