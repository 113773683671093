import { AxiosResponse } from 'axios';

import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';
import {
  CreateAssignmentParams,
  DeleteAssignmentParams,
  EditAssignmentNotesParams,
  EditAssignmentParams,
  ProjectAssignment
} from 'types';
import { getRequestFormatDate } from 'utils/date';

class AssignmentService {
  static async createAssignment({
    assignment,
    quantity,
    note,
    startDate,
    endDate
  }: Partial<CreateAssignmentParams>): Promise<AxiosResponse<{ assignments: ProjectAssignment }>> {
    const start = getRequestFormatDate(startDate);
    const end = getRequestFormatDate(endDate);
    return httpClient.post(ENDPOINTS.assignments(start, end), {
      assignment,
      quantity,
      note
    });
  }

  static async editAssignment({
    assignmentId,
    assignment,
    note,
    startDate,
    endDate
  }: Partial<EditAssignmentParams>): Promise<AxiosResponse<ProjectAssignment>> {
    const start = getRequestFormatDate(startDate);
    const end = getRequestFormatDate(endDate);

    return httpClient.put(ENDPOINTS.updateAssignment(start, end, assignmentId), {
      assignment,
      note
    });
  }

  static async deleteAssignment({
    assignmentId,
    startDate,
    endDate
  }: Partial<DeleteAssignmentParams>) {
    const start = getRequestFormatDate(startDate);
    const end = getRequestFormatDate(endDate);
    return httpClient.delete(ENDPOINTS.updateAssignment(start, end, assignmentId), {
      data: {}
    });
  }

  static async getAssignmentById(assignmentId: number): Promise<AxiosResponse<ProjectAssignment>> {
    return httpClient.get(ENDPOINTS.getAssignment(assignmentId));
  }

  static async editNotes({
    noteId,
    note
  }: EditAssignmentNotesParams): Promise<AxiosResponse<unknown>> {
    return httpClient.put(ENDPOINTS.getAssignmentNote(noteId), note);
  }
}

export default AssignmentService;
