import { css } from 'styled-components';

export const setSvgColors = ({
  fillColor,
  strokeColor
}: {
  fillColor: string;
  strokeColor: string;
}) => css`
  svg {
    background: ${fillColor};
    fill: ${fillColor};
    stroke: ${strokeColor};
  }
`;
