import dayjs from 'dayjs';
import humps from 'humps';
import { compact, flattenDeep, isEmpty, uniq } from 'lodash';
import queryString from 'query-string';
import { Dispatch, SetStateAction } from 'react';

import { QUERY_OPTIONS } from 'constants/constants';
import routesPaths from 'constants/routesPaths';
import { Department, FilterValue, Option, PeopleFilters, ProjectResources, Skill } from 'types';
import { CommonDateType, stringToDate, timeInWeeks, getTimestamp } from 'utils/date';

import { ONE_WEEK } from './date';

export const parseInputErrors = (error?: string | string[]) => {
  if (!error) {
    return;
  }
  if (Array.isArray(error)) {
    return error[0];
  }
  return error;
};

export const applyQueryParams = (url: string, params = {}) => {
  if (isEmpty(params)) {
    return url;
  }
  const queryParams = queryString.stringify(params);
  return `${url}?${queryParams}`;
};

export const duration = (startDate: CommonDateType, endDate: CommonDateType) =>
  dayjs(endDate).diff(dayjs(startDate), 'day') + 1;

export const weekDays = (start: string, end: string) => {
  const startDate = stringToDate(start);
  const endDate = stringToDate(end);

  const startWeek = startDate.getDay();
  const startOffset = startWeek === 0 ? 0 : startWeek - 1;
  startDate.setDate(startDate.getDate() - startWeek);

  const endWeek = endDate.getDay();
  const endOffset = endWeek === 0 || endWeek === 6 ? 0 : 5 - endWeek;
  endWeek !== 0 && endDate.setDate(endDate.getDate() + (7 - endWeek));
  return (
    Math.floor((getTimestamp(endDate) - getTimestamp(startDate)) / ONE_WEEK + 0.5) * 5 -
    startOffset -
    endOffset
  );
};

export const humanize = (string: string) =>
  string
    ?.split('_')
    .map(str => humps.pascalize(str))
    .join(' ');

export const checkIfIsFiltered = (options?: Array<{ value: boolean }>) =>
  options
    ?.reduce<boolean[]>((optionValues, option) => [option.value, ...optionValues], [])
    .includes(true);

type FilterOptions = Array<{ value: boolean; key: string }>;
export const getOptionsKeys = (options: FilterOptions) =>
  options.filter(({ value }) => value).map(({ key }) => key);

export const getFilterQuery = (filterObject: PeopleFilters) =>
  Object.keys(filterObject).reduce((filterQuery, filterName) => {
    const castedFilterName = filterName as keyof PeopleFilters;
    if (filterName in QUERY_OPTIONS && filterObject[castedFilterName]) {
      const queryFilterName = filterName as keyof typeof QUERY_OPTIONS;
      filterQuery[QUERY_OPTIONS[queryFilterName]] = getOptionsKeys(
        filterObject[castedFilterName] as FilterOptions
      );
    }
    return filterQuery;
  }, {} as Record<QUERY_OPTIONS, string[]>);

export const initializeOptions = <T>(options: Option<T>[]) =>
  options.map(option => ({ ...option, value: false })) as FilterValue[];

export const initializeOptionsWithFieldName = (
  options: Option[],
  fieldName: string,
  hasIntlOptions: boolean
) => options.map(option => ({ ...option, value: false, field: fieldName, hasIntlOptions }));

export const initializeOptionsWithSelected = (options: Option[], defaults = []) =>
  options.map(option => ({
    ...option,
    selected: Boolean(defaults?.find(({ id }) => id === option.id))
  }));

export const initializeOptionsWithDefault = (options: Option[], defaults: string[] = []) =>
  options.map(option => ({ ...option, value: defaults.includes(option.key ?? '') }));

export const initializeOptionsValues = (options: Option[]) =>
  options.map(({ value, key }) => ({
    label: key,
    value: { value },
    key: { key }
  }));

export const initializeTeams = ({
  teams,
  withValues
}: {
  teams: Department[];
  withValues?: boolean;
}) =>
  teams.reduce<FilterValue[]>((formattedTeams, { name, ...team }) => {
    const formattedTeam = {
      ...team,
      label: name,
      key: name,
      value: withValues ? team.id : false
    };

    formattedTeams.push(formattedTeam);

    return formattedTeams;
  }, []);

export const remToNumber = (value: string) => parseFloat(value.replace(/rem$/, ''));

export const remToNumberArray = (values: string[]) => values.map(remToNumber);

export const getFiltersResourcesQuery = (data: { resources: ProjectResources[] }) => {
  const { resources } = data;
  const filterSkills: Array<{ id: number; name: string }> = [];
  const filterSeniority: string[] = [];

  const locations = flattenDeep(uniq(resources.map(resource => resource.location.city)));
  const departments = flattenDeep(uniq(resources.map(resource => resource?.department?.name)));

  resources.forEach(({ seniority, experiences }) => {
    seniority && filterSeniority.push(seniority);
    experiences.forEach(experience => filterSkills.push(experience.skill));
  });

  return {
    locations,
    departments,
    skills: compact(uniq(filterSkills)),
    seniority: compact(uniq(filterSeniority))
  };
};

export const filterSkillsByDepartment = (skills: Skill[], departmentId?: string | number) =>
  skills.filter(skill => skill.departmentId == departmentId);

type ExpSkillValue = {
  value: {
    id: number;
  };
};
type ExperienceSkill = {
  skill: ExpSkillValue;
  years: ExpSkillValue;
  months: ExpSkillValue;
};
type Values = {
  token: string;
  userId: string | number;
  kindOfProject: unknown;
  kindOfIndustry: ExpSkillValue[];
};
export const prepareSkillsRequest = (
  values: Values,
  skills: ExperienceSkill[],
  projectQuestionTitle: string
) => {
  const { token, userId, kindOfProject, kindOfIndustry } = values;
  const experiences = skills
    .map(element => {
      if (element.skill && element.years && element.months) {
        return {
          skill_id: element.skill.value.id,
          weeks: timeInWeeks(element.years.value.id, element.months.value.id)
        };
      }
      return {};
    })
    .filter(element => !isEmpty(element));

  const industries = kindOfIndustry?.map(element => ({ id: element.value.id }));

  const notes = kindOfProject
    ? [
        {
          title: projectQuestionTitle,
          body: kindOfProject,
          person_id: userId,
          writer_id: userId
        }
      ]
    : [];

  return { token, userId, experiences, notes, industries };
};

export const validateSkillsRequest = (
  skillsFields: { skill: unknown; years: unknown; months: unknown }[]
) => {
  const subjectValidation = 'resource.experience.skillsErrorMessage';
  const errors = skillsFields
    .map(element => ({
      ...element,
      error: !element.skill || !element.years || !element.months ? subjectValidation : null
    }))
    .filter(element => !isEmpty(element.error));
  return errors.length === 0;
};

export const monthsToYears = (value: string) => {
  const totalMonths = parseFloat(value.split(' ')?.[0]);
  return {
    years: Math.floor(totalMonths / 12),
    months: totalMonths % 12
  };
};

export const daysToYears = (d: number) => {
  let months = 0;
  let years = 0;
  let days = 0;
  let weeks = 0;

  while (d) {
    if (d >= 365) {
      years += 1;
      d -= 365;
    } else if (d >= 30) {
      months += 1;
      d -= 30;
    } else if (d >= 7) {
      weeks += 1;
      d -= 7;
    } else {
      days += 1;
      d -= 1;
    }
  }

  return {
    years,
    months,
    weeks,
    days
  };
};

export const routeWithProps = (route: string, props: Record<string, string>) => {
  let newRoute = route;
  Object.keys(props).forEach(prop => {
    newRoute = newRoute.replace(`:${prop}?`, props[prop]);
    newRoute = newRoute.replace(`:${prop}`, props[prop]);
  });
  return newRoute;
};

export const estimatePercentage = (capacity: number, newHour: number) =>
  ((100 * newHour) / capacity).toFixed(0);

export const estimateHours = (capacity: number, newPercentage: number) =>
  ((capacity * newPercentage) / 100).toFixed(2);

// TODO: Doesn't seem used anywhere
// export const getObjectValueFromString = (obj, str) =>
//   str.split('.').reduce((o, key) => (o ? o[key] : null), obj);

export const setSelectInitialValue = <T extends object>(
  options: Array<{ value: { id: number } }> | undefined | null,
  field: keyof T,
  initialValues: T,
  setValues: Dispatch<SetStateAction<object>>
) => {
  if (options) {
    initialValues?.[field] &&
      setValues(prevState => ({
        ...prevState,
        [field]: options.find(({ value: { id } }) => id === initialValues?.[field])
      }));
  }
};

export const getSelectInitialValue = <T extends object>(
  options: Array<{ value: { id: number } }> | undefined | null,
  initialValues: T,
  field: keyof T
) => options?.find(({ value: { id } }) => id === initialValues?.[field]) || '';

export const filterById = (targetId: number, group: Array<{ id: number }>) =>
  group ? group.filter(({ id }) => id === targetId) : [];

export const accumulator = (array: number[]) => (array ? array.reduce((a, b) => a + b, 0) : 0);

export const parseUrl = (url: string) =>
  url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;

export const getSkillOptionDepartmentId = (
  skillOptions: (ExpSkillValue & { departmentId: number })[],
  id: number
) => {
  const skill = skillOptions?.find(option => option.value.id === id);
  return skill?.departmentId;
};

export const isMatchingRoute = (path: string, route: string) => {
  const pathParts = path.split('/').filter(Boolean);
  const routeParts = route.split('/').filter(Boolean);

  if (pathParts.length !== routeParts.length) {
    return false;
  }

  return routeParts.every((routePart, index) => {
    if (routePart.startsWith(':')) {
      return true;
    }
    return routePart === pathParts[index];
  });
};

export const findMatchingRoute = (path: string, routes: routesPaths) =>
  Object.values(routes).some(route => isMatchingRoute(path, route));
