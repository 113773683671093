import { useIntl } from 'react-intl';

import { ReactComponent as Discount } from 'assets/icons/discount.svg';

import IconWithTooltip from '../IconWithTooltip';

type DiscountIconProps = {
  discount: number
};

export const DiscountIcon = ({ discount }: DiscountIconProps) => {
  const intl = useIntl();

  return (
    <IconWithTooltip
      icon={<Discount />}
      message={intl.formatMessage({ id: 'common.discountOf' }, { discount })}
    />
  );
};
