import { pickBy } from 'lodash';

import { Errors, FormSection } from 'types';

export const isErrorEmpty = (errors: Errors) =>
  !Object.values(errors)
    .filter(error => !!error)
    .flat().length;

export const getErrorSection = (formSections: FormSection[], errors: Errors): number | null => {
  const errorFields = pickBy(errors, Boolean);
  const firstErrorField = Object.keys(errorFields)[0];

  const errorSection =
    firstErrorField &&
    formSections.find(section => section.sectionErrors?.some(field => field === firstErrorField));

  return errorSection ? errorSection.section : null;
};
