import { createAction } from '@reduxjs/toolkit';

import AssignmentService from 'services/assignmentService';
import {
  AsyncThunkConfig,
  AssignmentRequestType,
  ProjectType,
  EditAssignmentParams,
  DeleteAssignmentRequestType,
  CreateAssignmentParams,
  ProjectAssignment,
  ErrorAsyncThunk,
  CreateAssignmentResponse,
  DeleteAssignmentResponse
} from 'types';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';

import { getPeopleAvailability, getPeopleByAssignment } from './peopleActions';

export const resetResources = createAction('RESET_ASSIGNMENT_RESOURCES');
export const updateNote = createAction('UPDATE_NOTE', data => data);

export const getAssignmentById = createAsyncThunk<ProjectAssignment, AssignmentRequestType>(
  'GET_ASSIGNMENT_BY_ID',
  async ({ assignmentId }: AssignmentRequestType, { dispatch }: AsyncThunkConfig) => {
    try {
      const { data } = await AssignmentService.getAssignmentById(assignmentId);

      dispatch(getPeopleByAssignment(assignmentId));
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);
export const editAssignment = createAsyncThunk<CreateAssignmentResponse, EditAssignmentParams>(
  'EDIT_ASSIGNMENT',
  async (
    { assignmentId, assignment, note }: EditAssignmentParams,
    { getState, dispatch }: AsyncThunkConfig
  ) => {
    try {
      const {
        timeline: { startDate, endDate }
      } = getState();
      const { data } = await AssignmentService.editAssignment({
        assignmentId,
        assignment,
        note
      });
      dispatch(getPeopleAvailability({ startDate, endDate }));
      return { data, startDateTimeline: startDate, endDateTimeline: endDate };
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const editQuickAssignment = createAsyncThunk<ProjectAssignment, AssignmentRequestType>(
  'EDIT_QUICK_ASSIGNMENT',
  async ({ assignment, assignmentId }: AssignmentRequestType) => {
    try {
      const { startDate, endDate } = assignment;

      const { data } = await AssignmentService.editAssignment({
        assignmentId,
        assignment,
        startDate,
        endDate
      });

      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const confirmAssignment = createAsyncThunk(
  'CONFIRM_ASSIGNMENT',
  async ({ payload: { assignment, assignmentId }, startDate, endDate }: AssignmentRequestType) => {
    try {
      const { data } = await AssignmentService.editAssignment({
        assignmentId,
        assignment,
        startDate,
        endDate
      });

      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);
export const deleteAssignment = createAsyncThunk<
  DeleteAssignmentResponse,
  DeleteAssignmentRequestType
>(
  'DELETE_ASSIGNMENT',
  async ({ assignmentId, personId, projectId }: DeleteAssignmentRequestType) => {
    try {
      await AssignmentService.deleteAssignment({
        assignmentId
      });
      return { assignmentId, personId, projectId };
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const createAssignment = createAsyncThunk<CreateAssignmentResponse, CreateAssignmentParams>(
  'CREATE_ASSIGNMENT',
  async (
    { assignment, quantity, note }: CreateAssignmentParams,
    { getState }: AsyncThunkConfig
  ) => {
    try {
      const {
        project: { projects },
        timeline: { startDate, endDate }
      } = getState();

      const { data } = await AssignmentService.createAssignment({
        assignment,
        quantity,
        note
      });

      const projectAssignment: ProjectAssignment = data.assignments;

      const project = projects.find(
        (project: ProjectType) => project.id === projectAssignment.projectId
      );

      return {
        data: projectAssignment,
        project,
        startDateTimeline: startDate,
        endDateTimeline: endDate
      };
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const editNote = createAsyncThunk(
  'EDIT_NOTE',
  async ({ payload: { noteId, note } }: AssignmentRequestType, { dispatch }: AsyncThunkConfig) => {
    try {
      const { data } = await AssignmentService.editNotes({ noteId, note });
      dispatch(updateNote(data));
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const addAssignment = createAsyncThunk(
  'ADD_ASSIGNMENT',
  async (payload: AssignmentRequestType) => {
    try {
      const { data } = await AssignmentService.createAssignment(payload);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const isolatedEditAssignment = createAsyncThunk(
  'EDIT_ASSIGNMENT_ISOLATED',
  async (payload: AssignmentRequestType) => {
    try {
      const { data } = await AssignmentService.editAssignment(payload);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const isolatedDeleteAssignment = createAsyncThunk(
  'DELETE_ASSIGNMENT_ISOLATED',
  async (payload: AssignmentRequestType) => {
    try {
      const { data } = await AssignmentService.deleteAssignment(payload);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const isolatedConfirmAssignment = createAsyncThunk(
  'CONFIRM_ASSIGNMENT_ISOLATED',
  async (payload: AssignmentRequestType) => {
    try {
      const { data } = await AssignmentService.editAssignment(payload);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);
