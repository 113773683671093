import { useState } from 'react';
import { useIntl } from 'react-intl';

import CenteredLoading from 'components/common/Loading/CenteredLoading';
import { BUTTON_VARIANTS } from 'constants/constants';
import { useRequest } from 'hooks';
import { getDashboardFinancialRevenues } from 'state/actions/dashboardActions';
import { revenueGraphData } from 'utils/dashboardUtilities';
import { getOneYearPeriodString } from 'utils/date';

import { TABS } from './constants';
import RevenueGraph from './RevenueGraph';
import RevenueModal from './RevenueModal';
import {
  Container,
  Content,
  RedirectLinkIcon,
  StyledTabButtons,
  Title,
  TopContainer,
  ModalTitle,
  SubTitle,
  VerticalLine,
  StyledButton
} from './styles';

const RevenueBlock = () => {
  const [activeTab, setActiveTab] = useState(TABS[0].id);
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  const getSelectedTab = id => TABS.find(tab => tab.id === id);
  const selectedTab = getSelectedTab(activeTab);
  const name = intl.formatMessage({ id: selectedTab.intlId });

  const [
    {
      isPending,
      response: { revenues }
    },
    getRevenueRequest
  ] = useRequest(getDashboardFinancialRevenues, { params: { group: selectedTab.key } });

  const data = revenueGraphData(revenues?.group);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleChangeTab = id => {
    const selectedTab = getSelectedTab(id);
    getRevenueRequest({ group: selectedTab.key });
    setActiveTab(id);
  };

  return (
    <Container>
      <StyledTabButtons tabs={TABS} setActiveTab={handleChangeTab} activeTab={activeTab} />
      <Content>
        <TopContainer>
          <Title>
            <h3>{intl.formatMessage({ id: 'common.revenue' })}</h3>
            <SubTitle>
              <h4>{intl.formatMessage({ id: 'template.top5Name' }, { name })}</h4>
              <h5>{getOneYearPeriodString()}</h5>
            </SubTitle>
          </Title>
          <StyledButton
            variant={BUTTON_VARIANTS.NEW_SECONDARY}
            icon={<RedirectLinkIcon />}
            onClick={handleToggle}
            padding="1.2rem 2rem"
          >
            <p>{name}</p>
          </StyledButton>
        </TopContainer>
        {isPending ? (
          <CenteredLoading />
        ) : (
          <RevenueGraph
            data={data?.slice(0, 5)}
            limitX={Number(revenues?.totalRevenue)}
            name={name}
          />
        )}
      </Content>
      {isOpen && (
        <RevenueModal
          name={
            <ModalTitle>
              {intl.formatMessage({ id: 'common.revenue' })}
              <VerticalLine />
              <span>{name}</span>
            </ModalTitle>
          }
          data={data}
          limitX={Number(revenues?.totalRevenue)}
          handleToggle={handleToggle}
          isShowing={isOpen}
          tabName={name}
        />
      )}
    </Container>
  );
};

export default RevenueBlock;
