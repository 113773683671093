import dashboardService from 'services/dashboardService';
import {
  DashBoardUtilizationBenchParams,
  DashboardAssignmentSummariesParams,
  DashboardBenchOverviewParams,
  DashboardFinancialOverviewParams,
  DashboardFinancialRevenueParams,
  DashboardRequestType,
  DashboardResourcesTableParams,
  DashboardUtilizationParams,
  DateRange,
  ErrorAsyncThunk
} from 'types';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';

export const getDashboardResources = createAsyncThunk(
  'GET_DASHBOARD_RESOURCES',
  async (params: DateRange) => {
    try {
      const {
        data: { dashboardData }
      } = await dashboardService.getDashboardResources(params);
      return dashboardData;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getDashboardResourcesTable = createAsyncThunk(
  'GET_DASHBOARD_RESOURCES_TABLE',
  async (params: DashboardResourcesTableParams) => {
    try {
      const {
        data: { resources, glossarySummary }
      } = await dashboardService.getDashboardResourcesTable(params);
      return { resources, glossarySummary };
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getAssignmentSummaries = createAsyncThunk(
  'GET_ASSIGNMENT_SUMMARIES',
  async (params: DashboardAssignmentSummariesParams) => {
    try {
      const {
        data: { summaries }
      } = await dashboardService.getAssignmentSummaries(params);
      return summaries;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getDashboardAvailabilitiesTable = createAsyncThunk(
  'GET_DASHBOARD_AVAILABILITIES_TABLE',
  async (params: DashboardResourcesTableParams) => {
    try {
      const {
        data: { resources, workingDays }
      } = await dashboardService.getDashboardAvailabilitiesTable(params);
      return { resources, workingDays };
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getDashboardBenchBreakdownTable = createAsyncThunk(
  'GET_DASHBOARD_BENCH_BREAKDOWN_TABLE',
  async () => {
    try {
      const {
        data: { resources }
      } = await dashboardService.getDashboardBenchBreakdownTable();
      return { resources };
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getDashboardBenchBreakdownSkills = createAsyncThunk(
  'GET_DASHBOARD_BENCH_BREAKDOWN_SKILLS',
  async () => {
    try {
      const {
        data: { skills }
      } = await dashboardService.getDashboardBenchBreakdownSkills();
      return { skills };
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getDashboardUtilization = createAsyncThunk(
  'GET_DASHBOARD_UTILIZATION',
  async (params: DashboardUtilizationParams) => {
    try {
      const { data } = await dashboardService.getDashboardUtilization(params);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getDashboardUtilizationQuarters = createAsyncThunk(
  'GET_DASHBOARD_UTILIZATION_QUARTERS',
  async () => {
    try {
      const { data } = await dashboardService.getDashboardUtilizationQuarters();
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getDashboardUtilizationCorner = createAsyncThunk(
  'GET_DASHBOARD_UTILIZATION_CORNER',
  async () => {
    try {
      const { data } = await dashboardService.getDashboardUtilizationCorner();
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getDashboardSkills = createAsyncThunk('GET_DASHBOARD_SKILLS', async () => {
  try {
    const { data } = await dashboardService.getDashboardSkills();
    return data;
  } catch (error) {
    const {
      response: { data }
    } = error as ErrorAsyncThunk;
    throw parseError(data);
  }
});

export const getDashboardUtilizationBench = createAsyncThunk(
  'GET_DASHBOARD_UTILIZATION_BENCH',
  async (params: DashBoardUtilizationBenchParams) => {
    try {
      const { data } = await dashboardService.getDashboardUtilizationBench(params);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getDashboardFinancialOverviews = createAsyncThunk(
  'GET_DASHBOARD_FINANCIAL_OVERVIEWS',
  async (params: DashboardFinancialOverviewParams) => {
    try {
      const { data } = await dashboardService.getDashboardFinancialOverviews(params);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getDashboardFinancialProjectTypesMargin = createAsyncThunk(
  'GET_DASHBOARD_PROJECT_TYPES_MARGIN',
  async () => {
    try {
      const { data } = await dashboardService.getDashboardFinancialProjectTypesMargin();
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getDashboardFinancialRevenues = createAsyncThunk(
  'GET_DASHBOARD_FINANCIAL_REVENUES',
  async (params: DashboardFinancialRevenueParams) => {
    try {
      const { data } = await dashboardService.getDashboardFinancialRevenue(params);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getDashboardFinancialQuarters = createAsyncThunk(
  'GET_DASHBOARD_FINANCIAL_QUARTERS',
  async () => {
    try {
      const { data } = await dashboardService.getDashboardFinancialQuarters();
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getDashboardBenchOverviews = createAsyncThunk(
  'GET_DASHBOARD_BENCH_OVERVIEWS',
  async (params: DashboardBenchOverviewParams) => {
    try {
      const { data } = await dashboardService.getDashboardBenchOverviews(params);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getDashboardBenchCorner = createAsyncThunk('GET_DASHBOARD_BENCH_CORNER', async () => {
  try {
    const { data } = await dashboardService.getDashboardBenchCorner();
    return data;
  } catch (error) {
    const {
      response: { data }
    } = error as ErrorAsyncThunk;
    throw parseError(data);
  }
});

export const getDashboardBenchQuarters = createAsyncThunk(
  'GET_DASHBOARD_BENCH_QUARTERS',
  async () => {
    try {
      const { data } = await dashboardService.getDashboardBenchQuarters();
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getDashboardBenchList = createAsyncThunk(
  'GET_DASHBOARD_BENCH_LIST',
  async ({ startDate, endDate }: DashboardRequestType) => {
    try {
      const { data } = await dashboardService.getDashboardBenchList(startDate, endDate);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);
