import assignmentTypes from 'constants/assignmentTypes';

import { humanize } from './helpers';

export const groupsToOptions = ({ id, name }: { id: number; name: string }) => ({
  label: humanize(name),
  value: { id, type: assignmentTypes.roles.value },
  key: name,
  id: `role-${id}`
});

export const roleGroupsToOptions = ({ id, group }: { id: number; group: string }) => ({
  label: humanize(group),
  value: { id, type: assignmentTypes.roles.value },
  key: group,
  id: `role-${id}`
});
