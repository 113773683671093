import { ROLES } from 'constants/permissions';
import routesPaths from 'constants/routesPaths';
import {
  Login,
  SignUp,
  LoadExperiences,
  Resource,
  ProjectDetails,
  SearchPeople,
  Financial,
  FinancialDetails,
  Forecasting,
  MyReportsProfile,
  MyReports,
  ProjectsTimeline,
  ProjectsOverview,
  Utilization,
  FinancialInsights,
  BenchInsights,
  Dashboard
} from 'pages';
import AccessMatrix from 'pages/AccessMatrix';
import AccessRoles from 'pages/AccessRoles';
import BasicUser from 'pages/BasicUser/inex';
import MappingRoles from 'pages/MappingRoles';

export const publicRoutes = [
  {
    path: routesPaths.login,
    component: <Login />
  },
  {
    path: routesPaths.signUp,
    component: <SignUp />
  },
  {
    path: routesPaths.loadExperience,
    component: <LoadExperiences />,
    exact: true
  }
];

export const privateRoutes = {
  [ROLES.operations]: [
    {
      path: routesPaths.utilizationInsights,
      component: <Utilization />
    },
    {
      path: routesPaths.financialInsights,
      component: <FinancialInsights />
    },
    {
      path: routesPaths.benchInsights,
      component: <BenchInsights />
    },
    {
      path: routesPaths.projectsTimeline,
      component: <ProjectsTimeline />,
      exact: true
    },
    {
      path: routesPaths.projectsOverview,
      component: <ProjectsOverview />,
      exact: true
    },
    {
      path: routesPaths.assignmentDetails,
      component: <SearchPeople />,
      exact: true
    },
    {
      path: routesPaths.projectDetails,
      component: <ProjectDetails />,
      exact: true
    },
    {
      path: routesPaths.resources,
      component: <Resource />,
      exact: true
    },
    {
      path: routesPaths.myReports,
      component: <MyReports />,
      exact: true
    },
    {
      path: routesPaths.myReportsProfile,
      component: <MyReportsProfile />,
      exact: true
    },
    {
      path: routesPaths.financialProjectsDetails,
      component: <FinancialDetails />,
      exact: true
    },
    {
      path: routesPaths.financial,
      component: <Financial />
    },
    {
      path: routesPaths.forecasting,
      component: <Forecasting />
    },
    {
      path: routesPaths.dashboard,
      component: <Dashboard />
    },
    {
      path: routesPaths.accessRoles,
      component: <AccessRoles />
    },
    {
      path: routesPaths.mappingRoles,
      component: <MappingRoles />
    },
    {
      path: routesPaths.accessMatrix,
      component: <AccessMatrix />
    }
  ],
  [ROLES.leadership]: [
    {
      path: routesPaths.utilizationInsights,
      component: <Utilization />
    },
    {
      path: routesPaths.financialInsights,
      component: <FinancialInsights />
    },
    {
      path: routesPaths.benchInsights,
      component: <BenchInsights />
    },
    {
      path: routesPaths.projectsTimeline,
      component: <ProjectsTimeline />,
      exact: true
    },
    {
      path: routesPaths.projectsOverview,
      component: <ProjectsOverview />,
      exact: true
    },
    {
      path: routesPaths.projectDetails,
      component: <ProjectDetails />,
      exact: true
    },
    {
      path: routesPaths.resources,
      component: <Resource />,
      exact: true
    },
    {
      path: routesPaths.myReports,
      component: <MyReports />,
      exact: true
    },
    {
      path: routesPaths.myReportsProfile,
      component: <MyReportsProfile />,
      exact: true
    },
    {
      path: routesPaths.financialProjectsDetails,
      component: <FinancialDetails />,
      exact: true
    },
    {
      path: routesPaths.financial,
      component: <Financial />
    },
    {
      path: routesPaths.forecasting,
      component: <Forecasting />
    }
  ],
  [ROLES.pm]: [
    {
      path: routesPaths.projectsTimeline,
      component: <ProjectsTimeline />,
      exact: true
    },
    {
      path: routesPaths.projectsOverview,
      component: <ProjectsOverview />,
      exact: true
    },
    {
      path: routesPaths.assignmentDetails,
      component: <SearchPeople />,
      exact: true
    },
    {
      path: routesPaths.projectDetails,
      component: <ProjectDetails />,
      exact: true
    },
    {
      path: routesPaths.resources,
      component: <Resource />,
      exact: true
    },
    {
      path: routesPaths.myReports,
      component: <MyReports />,
      exact: true
    },
    {
      path: routesPaths.myReportsProfile,
      component: <MyReportsProfile />,
      exact: true
    },
    {
      path: routesPaths.financialProjectsDetails,
      component: <FinancialDetails />,
      exact: true
    },
    {
      path: routesPaths.financial,
      component: <Financial />
    },
    {
      path: routesPaths.forecasting,
      component: <Forecasting />
    }
  ],
  [ROLES.resourceManager]: [
    {
      path: routesPaths.utilizationInsights,
      component: <Utilization />
    },
    {
      path: routesPaths.benchInsights,
      component: <BenchInsights />
    },
    {
      path: routesPaths.projectsTimeline,
      component: <ProjectsTimeline />,
      exact: true
    },
    {
      path: routesPaths.projectsOverview,
      component: <ProjectsOverview />,
      exact: true
    },
    {
      path: routesPaths.assignmentDetails,
      component: <SearchPeople />,
      exact: true
    },
    {
      path: routesPaths.projectDetails,
      component: <ProjectDetails />,
      exact: true
    },
    {
      path: routesPaths.resources,
      component: <Resource />,
      exact: true
    },
    {
      path: routesPaths.myReports,
      component: <MyReports />,
      exact: true
    },
    {
      path: routesPaths.myReportsProfile,
      component: <MyReportsProfile />,
      exact: true
    }
  ],
  [ROLES.deliveryOwner]: [
    {
      path: routesPaths.utilizationInsights,
      component: <Utilization />
    },
    {
      path: routesPaths.benchInsights,
      component: <BenchInsights />
    },
    {
      path: routesPaths.projectsTimeline,
      component: <ProjectsTimeline />,
      exact: true
    },
    {
      path: routesPaths.projectsOverview,
      component: <ProjectsOverview />,
      exact: true
    },
    {
      path: routesPaths.projectDetails,
      component: <ProjectDetails />,
      exact: true
    },
    {
      path: routesPaths.resources,
      component: <Resource />,
      exact: true
    },
    {
      path: routesPaths.myReports,
      component: <MyReports />,
      exact: true
    },
    {
      path: routesPaths.myReportsProfile,
      component: <MyReportsProfile />,
      exact: true
    },
    {
      path: routesPaths.financialProjectsDetails,
      component: <FinancialDetails />,
      exact: true
    },
    {
      path: routesPaths.financial,
      component: <Financial />
    }
  ],
  [ROLES.manager]: [
    {
      path: routesPaths.utilizationInsights,
      component: <Utilization />
    },
    {
      path: routesPaths.benchInsights,
      component: <BenchInsights />
    },
    {
      path: routesPaths.projectsTimeline,
      component: <ProjectsTimeline />,
      exact: true
    },
    {
      path: routesPaths.projectsOverview,
      component: <ProjectsOverview />,
      exact: true
    },
    {
      path: routesPaths.assignmentDetails,
      component: <SearchPeople />,
      exact: true
    },
    {
      path: routesPaths.projectDetails,
      component: <ProjectDetails />,
      exact: true
    },
    {
      path: routesPaths.resources,
      component: <Resource />,
      exact: true
    },
    {
      path: routesPaths.myReports,
      component: <MyReports />,
      exact: true
    },
    {
      path: routesPaths.myReportsProfile,
      component: <MyReportsProfile />,
      exact: true
    },
    {
      path: routesPaths.financialProjectsDetails,
      component: <FinancialDetails />,
      exact: true
    },
    {
      path: routesPaths.financial,
      component: <Financial />
    },
    {
      path: routesPaths.dashboard,
      component: <Dashboard />
    }
  ],
  [ROLES.sales]: [
    {
      path: routesPaths.benchInsights,
      component: <BenchInsights />
    },
    {
      path: routesPaths.projectsTimeline,
      component: <ProjectsTimeline />,
      exact: true
    },
    {
      path: routesPaths.projectsOverview,
      component: <ProjectsOverview />,
      exact: true
    },
    {
      path: routesPaths.projectDetails,
      component: <ProjectDetails />,
      exact: true
    }
  ],
  [ROLES.finance]: [
    {
      path: routesPaths.utilizationInsights,
      component: <Utilization />
    },
    {
      path: routesPaths.financialInsights,
      component: <FinancialInsights />
    },
    {
      path: routesPaths.resources,
      component: <Resource />,
      exact: true
    },
    {
      path: routesPaths.financialProjectsDetails,
      component: <FinancialDetails />,
      exact: true
    },
    {
      path: routesPaths.financial,
      component: <Financial />
    },
    {
      path: routesPaths.forecasting,
      component: <Forecasting />
    }
  ],
  [ROLES.projectManager]: [
    {
      path: routesPaths.projectsTimeline,
      component: <ProjectsTimeline />,
      exact: true
    },
    {
      path: routesPaths.projectsOverview,
      component: <ProjectsOverview />,
      exact: true
    },
    {
      path: routesPaths.projectDetails,
      component: <ProjectDetails />,
      exact: true
    },
    {
      path: routesPaths.financialProjectsDetails,
      component: <FinancialDetails />,
      exact: true
    },
    {
      path: routesPaths.financial,
      component: <Financial />
    }
  ],
  [ROLES.people]: [
    {
      path: routesPaths.benchInsights,
      component: <BenchInsights />
    },
    {
      path: routesPaths.projectsTimeline,
      component: <ProjectsTimeline />,
      exact: true
    },
    {
      path: routesPaths.resources,
      component: <Resource />,
      exact: true
    },
    {
      path: routesPaths.myReportsProfile,
      component: <MyReportsProfile />,
      exact: true
    }
  ],
  [ROLES.leader]: [
    {
      path: routesPaths.myReports,
      component: <MyReports />,
      exact: true
    },
    {
      path: routesPaths.myReportsProfile,
      component: <MyReportsProfile />,
      exact: true
    }
  ],
  [ROLES.basic]: [
    {
      path: routesPaths.basicUser,
      component: <BasicUser />,
      exact: true
    }
  ]
};
