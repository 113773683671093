import dayjs from 'dayjs';
import { arrayOf, bool, instanceOf, number, oneOfType, string } from 'prop-types';
import { useMemo } from 'react';

import { specialEventShape, timeOffShape, timelineAssignmentShape } from 'constants/shapes';
import { addMonths, getDiffDays, getMonthsOfPeriod } from 'utils/date';
import {
  getBarsItems,
  getCalendarWithWorkHours,
  getVacationAndSpecialItems,
  getHireAndExitItems
} from 'utils/timelineUtilities';

import Bar from './Bar';
import { Container, StyleBars, DivisionWrapper, Division } from './styles';

const mergeAssignmentsWithVacationsAndHolidays = (assignments = [], holidays = []) => {
  const newAssignments = [...assignments];

  holidays?.forEach(holiday => {
    newAssignments.push({
      startDate: holiday?.startDate,
      endDate: holiday?.endDate,
      isOffDay: true
    });
  });

  return newAssignments;
};

function MiniTimelineAllocation({
  startDate,
  endDate,
  assignments = [],
  timeOffs = [],
  specialEvents = [],
  holidays = [],
  capacity = 7.5,
  width = '100%',
  isRounded = false,
  isAddSeparators = false,
  showTimePeriod = false,
  hireDate,
  exitDate,
  bigBar = false
}) {
  const parseHolidays = items => {
    const list = [];
    items.map(item => {
      list.push({
        startDate: item,
        endDate: item
      });
    });

    return list;
  };

  const calendar = useMemo(
    () =>
      getCalendarWithWorkHours(
        mergeAssignmentsWithVacationsAndHolidays(assignments, [
          ...timeOffs,
          ...specialEvents,
          ...parseHolidays(holidays)
        ]),
        startDate,
        endDate
      ),
    [assignments, endDate, startDate]
  );

  const totalDays = getDiffDays(startDate, endDate) + 1;

  const barsItemsAssignments = getBarsItems(calendar, capacity, startDate, hireDate);
  const { vacations, trainings, conversions, holidaysCountry } = getVacationAndSpecialItems(
    timeOffs,
    specialEvents,
    parseHolidays(holidays),
    startDate,
    endDate
  );
  const hireAndExitItems = getHireAndExitItems(startDate, endDate, hireDate, exitDate);

  const barsItems = [
    ...barsItemsAssignments,
    ...trainings,
    ...conversions,
    ...vacations,
    ...holidaysCountry,
    ...hireAndExitItems
  ].filter(item => {
    const itemStart = dayjs(item.startEvent);
    const itemEnd = dayjs(item.endEvent);
    const timelineStart = dayjs(startDate);
    const timelineEnd = dayjs(endDate);
    return !(itemStart.isAfter(timelineEnd) || itemEnd.isBefore(timelineStart));
  });

  const divisions = showTimePeriod && getMonthsOfPeriod(startDate, addMonths(1, endDate));

  return (
    <Container width={width}>
      <StyleBars totalDays={totalDays}>
        {divisions && (
          <DivisionWrapper count={divisions.length} totalDays={totalDays}>
            {divisions.map((month, index) => {
              const monthPeriod = index * 30 + 1;
              return (
                <Division key={month} start={monthPeriod} column={totalDays - monthPeriod}>
                  <p>{month}</p>
                </Division>
              );
            })}
          </DivisionWrapper>
        )}
        {barsItems.map((item, index) => (
          <Bar
            key={`${item.allocationType.key}-${index}`}
            item={item}
            startDatePeriod={startDate}
            isFirst={dayjs(startDate).isSame(dayjs(item.startEvent), 'day')}
            isLast={dayjs(endDate).isSame(dayjs(item.endEvent), 'day')}
            isRounded={isRounded}
            isAddSeparators={isAddSeparators}
            showDivisions={showTimePeriod}
            bigBar={bigBar}
            totalDays={totalDays}
          />
        ))}
      </StyleBars>
    </Container>
  );
}

MiniTimelineAllocation.propTypes = {
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)]),
  timeOffs: arrayOf(timeOffShape),
  holidays: oneOfType([string, instanceOf(Date)]),
  assignments: arrayOf(timelineAssignmentShape),
  specialEvents: arrayOf(specialEventShape),
  capacity: number,
  width: string,
  isRounded: bool,
  isAddSeparators: bool,
  showTimePeriod: bool,
  bigBar: bool,
  hireDate: oneOfType([string, instanceOf(Date)]),
  exitDate: oneOfType([string, instanceOf(Date)])
};

export default MiniTimelineAllocation;
