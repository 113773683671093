import { createAction } from '@reduxjs/toolkit';

import SettingsService from 'services/settingsService';
import {
  AccessRolesResource,
  AsyncThunkConfig,
  ErrorAsyncThunk,
  RoleGroup,
  RolesResponse,
  SettingsAddRequestType,
  SettingsRequestType,
  AccessRolesResponse,
  RolesGroupResponse,
  AccessRoleGroup
} from 'types';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';

export const updateTemporalRoles = createAction(
  'UPDATE_TEMPORAL_ROLES',
  (temporalRoles: { temporalRoleId: number }) => ({
    payload: temporalRoles
  })
);

export const getResources = createAsyncThunk<AccessRolesResponse<AccessRolesResource[]>, void>(
  'GET_RESOURCES',
  async () => {
    try {
      const { data } = await SettingsService.getResources();
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getGroups = createAsyncThunk<RolesGroupResponse<AccessRoleGroup[]>, void>(
  'GET_GROUPS',
  async () => {
    try {
      const { data } = await SettingsService.getGroups();
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const addTemporalRole = createAsyncThunk(
  'ADD_TEMPORAL_ROLE',
  async ({ body }: SettingsRequestType, { dispatch }: AsyncThunkConfig) => {
    try {
      const { data } = await SettingsService.addTemporalRole(body);
      dispatch(getResources());
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const editResourceRole = createAsyncThunk(
  'EDIT_RESOURCE_ROLE',
  async ({ id, body }: SettingsRequestType, { dispatch }: AsyncThunkConfig) => {
    try {
      const { data } = await SettingsService.editResourceRole(id, body);
      dispatch(getResources());
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const deleteResourceRole = createAsyncThunk(
  'DELETE_RESOURCE_ROLE',
  async ({ id, userId, resourceId }: SettingsRequestType, { dispatch }: AsyncThunkConfig) => {
    try {
      const { data } = await SettingsService.deleteResourceRole(id);
      resourceId === userId && dispatch(updateTemporalRoles({ temporalRoleId: id }));
      dispatch(getResources());
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getRoleGroups = createAsyncThunk<RolesResponse<RoleGroup[]>, void>(
  'GET_ROLE_GROUPS',
  async (_: unknown, { dispatch }: AsyncThunkConfig) => {
    try {
      const { data } = await SettingsService.getRoleGroups();
      dispatch(getResources());
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const addRoleRelation = createAsyncThunk(
  'ADD_ROLE_RELATION',
  async ({ body }: SettingsAddRequestType, { dispatch }: AsyncThunkConfig) => {
    try {
      const { data } = await SettingsService.addRoleRelation(body);
      dispatch(getRoleGroups());
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const editRoleRelation = createAsyncThunk(
  'EDIT_ROLE_RELATION',
  async ({ id, body }: SettingsAddRequestType, { dispatch }: AsyncThunkConfig) => {
    try {
      const { data } = await SettingsService.editRoleRelation(id, body);
      dispatch(getRoleGroups());
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const deleteRoleRelation = createAsyncThunk(
  'DELETE_ROLE_RELATION',
  async ({ id }: SettingsRequestType, { dispatch }: AsyncThunkConfig) => {
    try {
      const { data } = await SettingsService.deleteRoleRelation(id);
      dispatch(getRoleGroups());
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);
