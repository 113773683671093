import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

import { TIME_INTERVALS } from 'constants/constants';
import { DASHBOARD_DATE_TYPES, GROUPING_TYPES } from 'constants/dashboardConstants';
import {
  ONLY_DATE,
  REQUEST_DATE,
  DISPLAY_MONTH_DAY_DATE,
  DISPLAY_MONTH_DAY_YEAR_DATE,
  DISPLAY_DAY_MONTH_YEAR,
  DISPLAY_MONTH_DAY_YEAR,
  DISPLAY_MONTH_DAY_YEAR_STANDARD,
  DISPLAY_YEAR_MONTH,
  MONDAY,
  FRIDAY
} from 'constants/dateFormats';

dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(localeData);
dayjs.extend(quarterOfYear);
dayjs.extend(weekday);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.locale({
  ...en,
  weekStart: 0
});

export type CommonDateType = Date | string | dayjs.Dayjs | null;

const BUSINESS_DAYS = [1, 2, 3, 4, 5];
export const ONE_DAY = 1000 * 60 * 60 * 24;
export const ONE_WEEK = ONE_DAY * 7;

//  TODO try to unify this 3 functions
export const dateToString = (date: CommonDateType, format: string) =>
  dayjs.utc(date).format(format);

export const getRequestFormatDate = (date?: CommonDateType) =>
  dayjs(date).utc(true).format(REQUEST_DATE);

export const getXAxisFormatDate = (date: CommonDateType) =>
  dayjs(date).utc().format(DISPLAY_MONTH_DAY_DATE);

export const getFormatDate = (date: CommonDateType) =>
  dayjs(date, REQUEST_DATE).format(DISPLAY_DAY_MONTH_YEAR).toUpperCase();

export const getFormatDateStandard = (date: CommonDateType) =>
  dayjs(date, REQUEST_DATE).format(DISPLAY_MONTH_DAY_YEAR_STANDARD).toUpperCase();

export const getPeriodFormatDateStandard = (start: CommonDateType, end: CommonDateType) =>
  `From ${dayjs(start).format(DISPLAY_MONTH_DAY_YEAR)} to ${dayjs(end).format(
    DISPLAY_MONTH_DAY_YEAR
  )}`;

export const getPeriodYearMonth = (start: CommonDateType, end: CommonDateType) =>
  `${dayjs(start).format(DISPLAY_YEAR_MONTH)} to ${dayjs(end).format(DISPLAY_YEAR_MONTH)}`;

export const stringToDate = (date: string) => dayjs(date).utc().toDate();

export const getTimestamp = (date?: CommonDateType) => dayjs(date).utc().toDate().getTime();

export const dayJSToDate = (date: CommonDateType) => {
  const dayjsUTC = dayjs(date).utc();
  const dateJsUTC = Date.UTC(
    dayjsUTC.get('year'),
    dayjsUTC.get('month'),
    dayjsUTC.get('date'),
    dayjsUTC.get('hour'),
    dayjsUTC.get('minute'),
    dayjsUTC.get('second'),
    dayjsUTC.get('millisecond')
  );
  return new Date(dateJsUTC);
};

export const getUTCDate = (date: CommonDateType = new Date()) => dayjs(date).utc();

export const startOfWorkingWeek = (date?: CommonDateType) => getUTCDate(date).startOf('week').utc();

export const endOfWorkingWeek = (date: CommonDateType) =>
  startOfWorkingWeek(date).add(6, 'day').utc();

export const getMondayOfWeek = () => dayjs().weekday(1);

export const getFridayOfWeek = () => dayjs().weekday(5);

export const getDayMonthYearFormatDate = (date: CommonDateType) =>
  dayjs(date).utc().format(DISPLAY_MONTH_DAY_YEAR_DATE);

export const startOf = (date: CommonDateType, unit: dayjs.OpUnitType) =>
  getUTCDate(date).startOf(unit).format(REQUEST_DATE);

export const endOf = (date: CommonDateType, unit: dayjs.OpUnitType) =>
  getUTCDate(date).endOf(unit).format(REQUEST_DATE);

export const addTime = (date: CommonDateType, value: number, unit: dayjs.OpUnitType) =>
  dayjs(date).add(value, unit).toDate();

export const subtractTime = (date: CommonDateType, value: number, unit: dayjs.OpUnitType) =>
  dayjs(date).subtract(value, unit).toDate();

export const diffTime = (date1: CommonDateType, date2: CommonDateType, unit: dayjs.OpUnitType) =>
  dayjs(date2).diff(date1, unit);

export const endOfWeek = (date: dayjs.Dayjs) => date.endOf('week').utc();

export const startOfWeekString = (date: dayjs.Dayjs) => date.startOf('week').format(REQUEST_DATE);

type Week = {
  startDate: string;
  endDate: string;
  start: string | Date;
  end: string | Date;
};
type Calendar = {
  month: string;
  weeks: Week[];
  year: number;
};
export const weekCalendar = (startDate: CommonDateType, weeks = 10) => {
  const calendar: Calendar[] = [];
  let date = getUTCDate(startDate);
  for (let i = 0; i < weeks; i += 1) {
    const year = date.year();
    const month = dayjs.months()[date.month()];
    const calendarMonth = calendar.find(i => i.month === month);

    const week = {
      startDate: startOfWorkingWeek(date).format(ONLY_DATE),
      endDate: endOfWorkingWeek(date).format(ONLY_DATE),
      start: getRequestFormatDate(startOfWorkingWeek(date)),
      end:
        i + 1 === weeks
          ? getRequestFormatDate(startOfWorkingWeek(date).add(6, 'day'))
          : getRequestFormatDate(endOfWeek(date))
    };

    if (calendarMonth) {
      calendarMonth.weeks.push(week);
    } else {
      calendar.push({
        month,
        weeks: [week],
        year
      });
    }

    date = date.add(1, 'week');
  }

  return calendar;
};

export const isRequestedDay = (date: CommonDateType, requestedDay: string) => {
  if (!date) return false;
  const parsedDate = getUTCDate(date);
  const day = parsedDate.format('dddd')[0];
  return day === requestedDay;
};

export const addWeeks = (weeks: number, date = new Date()) =>
  dayjs(date).add(weeks, 'weeks').format(REQUEST_DATE);

export const addYears = (years: number, date = new Date()) => dayjs(date).add(years, 'year');

export const addMonths = (months: number, date = new Date()) => dayjs(date).add(months, 'month');

export const addDays = (date: CommonDateType, value: number) => {
  const dateTimestamp = getTimestamp(date);

  return new Date(dateTimestamp + value * ONE_DAY);
};

type Project = {
  startDate: string;
  endDate?: string;
};
export const getProjectMinMaxDates = (project: Project) => [
  stringToDate(project.startDate),
  project.endDate && stringToDate(project.endDate)
];

export const isPastDate = (date: CommonDateType) => dayjs(new Date()).isAfter(dayjs(date));

export const isBefore = (date1: CommonDateType, date2: CommonDateType) =>
  dayjs(date1).isBefore(dayjs(date2));

export const isDateSameOrBefore = (date1: CommonDateType, date2: CommonDateType) =>
  dayjs(date1).isSameOrBefore(dayjs(date2));

export const isAfter = (date1: CommonDateType, date2: CommonDateType) =>
  dayjs(date1).isAfter(dayjs(date2));

export const isDateSameOrAfter = (date1: CommonDateType, date2: CommonDateType) =>
  dayjs(date1).isSameOrAfter(dayjs(date2));

export const isDateSameOrAfterConsideringWeekends = (
  date1: CommonDateType,
  date2: CommonDateType
) => {
  const isFriday = isRequestedDay(date1, FRIDAY);

  if (isFriday) {
    return isDateSameOrAfter(addDays(date1, 2), date2);
  }
  return isDateSameOrAfter(date1, date2);
};

export const isDateSameOrBeforeConsideringWeekends = (
  date1: CommonDateType,
  date2: CommonDateType,
  previousEndDate: CommonDateType
) => {
  const isMonday = isRequestedDay(date1, MONDAY);
  const previousEndsFriday = isRequestedDay(previousEndDate, FRIDAY);

  if (isMonday && previousEndsFriday) {
    return isDateSameOrBefore(addDays(date1, -2), date2);
  }
  return isDateSameOrBefore(date1, date2);
};
export const isBusinessDay = (date: dayjs.Dayjs) => BUSINESS_DAYS.includes(date.day());

export const getFirstAndLastDayOfCurrentMonth = (formatter = getRequestFormatDate) => {
  const currentMonth = dayjs().utc().month();
  const month = dayjs().utc().set('month', currentMonth);
  const nextMonth = dayjs()
    .utc()
    .set('month', currentMonth + 1);
  return {
    startDate: formatter(month.date(1)),
    endDate: formatter(nextMonth.date(0))
  };
};

export const getFirstAndLastDayOfMonth = (
  month: CommonDateType,
  formatter = getRequestFormatDate
) => {
  const monthDate = dayjs(month).utc();
  const nextMonth = monthDate.set('month', monthDate.month() + 1);
  return {
    startDate: formatter(monthDate.date(1)),
    endDate: formatter(nextMonth.date(0))
  };
};

export const getFirstAndFifteenOfMonth = (monthNumber: number) => {
  const month = dayjs().utc().set('month', monthNumber);
  return {
    start: dayjs(month.date(1)).toDate(),
    end: dayjs(month.date(15)).toDate()
  };
};

export const getSixteenAndLastDayOfMonth = (monthNumber: number) => {
  const month = dayjs().utc().set('month', monthNumber);
  const nextMonth = month.set('month', month.month() + 1);
  return {
    start: dayjs(month.date(16)).toDate(),
    end: dayjs(nextMonth.date(0)).toDate()
  };
};

type RangeType = {
  label: JSX.Element;
  value: number;
  unit: dayjs.OpUnitType;
  groupingType: GROUPING_TYPES;
};
export const getFirstAndLastDayOfRange = (
  value: CommonDateType,
  rangeType: RangeType,
  monthsLookAhead: number
) => {
  const { unit } = rangeType;
  const dayjsDate = getUTCDate(value);
  let dayJsStartDate = getUTCDate(value);
  let dayJsEndDate = dayJsStartDate;
  if (rangeType.value === DASHBOARD_DATE_TYPES.week.value) {
    dayJsStartDate = dayJsStartDate.weekday(1);
    dayJsEndDate = dayJsEndDate.weekday(7);
  } else {
    dayJsStartDate = dayjsDate.month(dayjsDate.startOf(unit).month()).date(1);
    const startOfEndDate = dayJsStartDate.add(1, unit).startOf(unit);
    dayJsEndDate = dayJsStartDate.year(startOfEndDate.year()).month(startOfEndDate.month()).date(0);
  }
  return {
    startDate: dayJSToDate(dayJsStartDate),
    endDate: dayJSToDate(
      rangeType.value === DASHBOARD_DATE_TYPES.month.value
        ? dayJsEndDate.add(monthsLookAhead, 'month')
        : dayJsEndDate
    )
  };
};

export const addTimeByRangeType = (date: CommonDateType, value: number, rangeType: RangeType) =>
  getUTCDate(date).add(value, rangeType.unit);

export const getDiffDays = (startDate: CommonDateType, endDate: CommonDateType) =>
  dayjs(endDate).diff(startDate, 'day');

export const getWorkingDiffDays = (startDate: CommonDateType, endDate: CommonDateType) => {
  let count = 0;
  for (let i = dayjs(startDate); isDateSameOrBefore(i, dayjs(endDate)); i = dayjs(addDays(i, 1))) {
    if (isBusinessDay(i)) count += 1;
  }

  return count;
};

export const sortObjectByDateAsc = (
  array: Record<string, CommonDateType | undefined>[],
  propSelector: keyof Record<string, CommonDateType | undefined>
) => array.sort((a, b) => getTimestamp(a[propSelector]) - getTimestamp(b[propSelector]));

export const minDate = (date1: CommonDateType, date2: CommonDateType) => {
  if (!date1) return date2;
  if (!date2) return date1;

  return getTimestamp(date1) < getTimestamp(date2) ? date1 : date2;
};

export const maxDate = (date1: CommonDateType, date2: CommonDateType) => {
  if (!date1) return date2;
  if (!date2) return date1;

  return getTimestamp(date1) > getTimestamp(date2) ? date1 : date2;
};

export const clampDate = (
  date: CommonDateType,
  minDateConstraint: CommonDateType,
  maxDateConstraint: CommonDateType
) => {
  const lowerBound = maxDate(date, minDateConstraint);
  const upperBound = minDate(lowerBound, maxDateConstraint);
  return upperBound;
};

export const minDateFromArray = (dateArray: CommonDateType[]) =>
  dateArray.reduce((min, current) => minDate(min, current));

export const timeInWeeks = (years?: number, months?: number) =>
  (years ? years * TIME_INTERVALS.yearWeeks : 0) +
  (months ? months * TIME_INTERVALS.monthWeeks : 0);

export const getNextMonths = (count: number) => {
  const list = [];

  const currentDate = dayjs();

  for (let i = 0; i <= count; i += 1) {
    const newDate = currentDate.add(i, 'month');

    list.push({
      year: newDate.year(),
      month: newDate.month() + 1,
      date: newDate
    });
  }

  return list;
};

type CalendarDay = {
  dayOfMonth: number;
  month: string;
  day: string;
  isWeekend: boolean;
  isToday: boolean;
};
export const daysCalendar = (
  startDate: CommonDateType,
  endDate: CommonDateType,
  date: CommonDateType
) => {
  const daysArray: CalendarDay[] = [];
  const today = getUTCDate(date);
  let currentDate = getUTCDate(startDate);

  while (endDate && currentDate <= endDate) {
    const day = currentDate.format('dddd')[0];
    const isWeekend = day === 'S';

    daysArray.push({
      dayOfMonth: currentDate.date(),
      month: currentDate.format('MMM YYYY'),
      day,
      isWeekend,
      isToday: today.isSame(currentDate, 'day')
    });
    currentDate = currentDate.add(1, 'day');
  }
  const groupedByMonth = daysArray.reduce<Record<string, CalendarDay[]>>((result, day) => {
    if (!result[day.month]) {
      result[day.month] = [];
    }
    result[day.month].push(day);
    return result;
  }, {});

  return [groupedByMonth, daysArray.length];
};

export const getOneYearPeriodString = (date = new Date()) =>
  `${dayjs(date).subtract(1, 'year').format('MMM YY')} - ${dayjs(date).format('MMM YY')}`;

export const getMonthsOfPeriod = (start: CommonDateType, end: CommonDateType) => {
  const monthPeriod = diffTime(start, end, 'month');
  const months = [];

  for (let i = 0; i <= monthPeriod; i += 1) {
    const month = dayjs(start).add(i, 'month').format('MMM');
    months.push(month);
  }
  return months;
};

export const formatResourceDate = (date: CommonDateType) =>
  dayjs(date).format(DISPLAY_MONTH_DAY_YEAR);

export const yearsMonthsDiff = (date: CommonDateType) => {
  const monthsDiff = dayjs(new Date()).diff(date, 'month');
  return [Math.floor(monthsDiff / 12), monthsDiff % 12];
};

export const getExportDefaultDates = () => {
  const today = dayjs();
  const month = dayjs().month();
  const year = dayjs().year();

  if (isDateSameOrAfter(today, dayjs(`${month + 1}-16-${year}`))) {
    return getFirstAndFifteenOfMonth(month);
  }
  return getSixteenAndLastDayOfMonth(month - 1);
};

export const getPeriodEndDate = (openPeriodEndDate: string, initialDate: CommonDateType) => {
  const startDate = dayjs(initialDate);

  if (openPeriodEndDate) {
    return stringToDate(openPeriodEndDate);
  }

  if (isDateSameOrAfter(startDate, dayjs(`${startDate.month() + 1}-16-${startDate.year()}`))) {
    const getLastDayOfMonth = getSixteenAndLastDayOfMonth(startDate.month()).end;
    return dayjs(getLastDayOfMonth).set('year', startDate.year()).toDate();
  }

  const getFifteenOfMonth = getFirstAndFifteenOfMonth(startDate.month()).end;
  return dayjs(getFifteenOfMonth).set('year', startDate.year()).toDate();
};
