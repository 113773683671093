import { getFirstAndLastDayOfCurrentMonth } from 'utils/date';
import { initializeOptionsWithFieldName } from 'utils/helpers';

import { AVAILABILITY_OPTIONS } from './constants';

type BenchListItem = {
  fullName: string;
  order: number;
};

const greaterFunc = (a: BenchListItem, b: BenchListItem) => {
  if (!b) return true;
  if (a?.fullName && b?.fullName) return a?.fullName > b?.fullName;
  if (a?.order && b?.order) return a.order > b.order;
  return a > b;
};

const lowerFunc = (a: BenchListItem, b: BenchListItem) => {
  if (!a) return true;
  if (a?.fullName && b?.fullName) return a?.fullName < b?.fullName;
  if (a?.order && b?.order) return a.order < b.order;
  return a < b;
};

export const COLUMNS_KEYS = {
  mainInfo: 'mainInfo',
  department: 'department',
  seniority: 'seniority',
  mainSkills: 'mainSkills',
  currentProjects: 'currentProjects',
  daysOnBench: 'daysOnBench',
  availability: 'availability'
};

export const EXPORT_COLUMNS_KEYS = {
  ...COLUMNS_KEYS,
  pending: 'pending'
};

export const COLUMNS = Object.values(COLUMNS_KEYS).map(columnKey => ({
  key: columnKey,
  header: `insights.bench.benchList.table.columns.${columnKey}`,
  greaterFunc,
  lowerFunc
}));

export const INITIAL_FILTERS = {
  departments: [],
  roles: [],
  skills: [],
  availability: initializeOptionsWithFieldName(AVAILABILITY_OPTIONS, 'availability', true),
  startDate: getFirstAndLastDayOfCurrentMonth()?.startDate,
  endDate: getFirstAndLastDayOfCurrentMonth()?.endDate,
  hidePending: false,
  highlighted: true
};
