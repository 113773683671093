import { arrayOf, bool, func } from 'prop-types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import Button from 'components/common/Button';
import EmptyState from 'components/common/EmptyState';
import Cell from 'components/ProjectsOverview/ProjectsTable/Cell';
import { BUTTON_VARIANTS, SORT_DIR } from 'constants/constants';
import { reportsResourceShape, sortableTableColumnShape } from 'constants/shapes';
import { humanize } from 'utils/helpers';
import { sortTable } from 'utils/projectsOverviewUtilities';

import {
  ActionsWrapper,
  ArrowIcon,
  Column,
  HeaderColumnContainer,
  RolePill,
  Row,
  Table,
  TableWrapper
} from './styles';

const ManageRoleTable = ({
  groups,
  columns,
  isLoading,
  showActions = true,
  handleEdit,
  setRolesToDelete
}) => {
  const intl = useIntl();
  const [sortConfig, setSortConfig] = useState({
    key: 'assignments',
    direction: SORT_DIR.desc
  });
  const [tableRows, setTableRows] = useState(groups);

  const onSort = ({ key: columnKey }) => {
    let direction = SORT_DIR.desc;
    if (sortConfig && sortConfig.key === columnKey && sortConfig.direction === SORT_DIR.desc) {
      direction = SORT_DIR.asc;
    }
    setSortConfig({
      key: columnKey,
      direction
    });
  };

  const handleDelete = id => {
    setRolesToDelete(roles => [...roles, id]);
    const newGroups = tableRows?.filter(group => group.id !== id);
    setTableRows(newGroups);
  };

  useEffect(() => {
    if (sortConfig) {
      const sortedRows = sortTable({ columns, sortConfig, rows: groups });
      setTableRows(sortedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortConfig]);

  return (
    <TableWrapper>
      <Table>
        <thead>
          <Row height="5.7rem" isHeader>
            {columns?.map(column => {
              const isSorting = sortConfig && sortConfig.key === column.key;
              return (
                <th key={column.key}>
                  <HeaderColumnContainer
                    onClick={() => !isLoading && onSort(column)}
                    $disabled={isLoading}
                  >
                    <span>{column.header && intl.formatMessage({ id: column.header })}</span>
                    <Column width="1.2rem">
                      <ArrowIcon
                        direction={isSorting ? sortConfig.direction : SORT_DIR.desc}
                        $isSorting={!isLoading && isSorting}
                        disabled={isLoading}
                      />
                    </Column>
                  </HeaderColumnContainer>
                </th>
              );
            })}
            {showActions && (
              <th>
                <HeaderColumnContainer $disabled={isLoading}>
                  <span>{intl.formatMessage({ id: 'settings.table.actions' })}</span>
                </HeaderColumnContainer>
              </th>
            )}
          </Row>
        </thead>
        <tbody>
          {tableRows?.length ? (
            tableRows?.map(group => {
              const groupId = group?.id;

              return (
                <Row key={groupId}>
                  <Cell
                    value={<RolePill>{humanize(group?.group) || '--'}</RolePill>}
                    columnKey="role"
                    key={`${groupId}-${group?.group}`}
                  />
                  <Cell
                    value={group?.startDate}
                    columnKey="startDate"
                    key={`${groupId}-${group?.startDate}`}
                  />
                  <Cell
                    value={group?.endDate ?? 'Current'}
                    columnKey={group?.endDate ? 'endDate' : 'current'}
                    key={`${groupId}-${group?.endDate}`}
                  />
                  <Cell
                    value={
                      <ActionsWrapper>
                        <Button variant={BUTTON_VARIANTS.CLEAR} onClick={() => handleEdit(groupId)}>
                          <EditIcon />
                        </Button>
                        <Button
                          variant={BUTTON_VARIANTS.CLEAR}
                          onClick={() => {
                            handleDelete(group.id);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </ActionsWrapper>
                    }
                    columnKey="actions"
                    key={`${groupId}-actions}`}
                  />
                </Row>
              );
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <EmptyState
                  className="accessRoles"
                  titleIntlId="emptyState.title.roles"
                  subtitleIntlId="emptyState.subtitle.roles"
                  small
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

ManageRoleTable.propTypes = {
  columns: arrayOf(sortableTableColumnShape),
  isLoading: bool,
  showActions: bool,
  groups: arrayOf(reportsResourceShape),
  handleEdit: func,
  setRolesToDelete: func
};

export default ManageRoleTable;
