import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';
import {
  DashBoardUtilizationBenchParams,
  DashboardAssignmentSummariesParams,
  DashboardBenchOverviewParams,
  DashboardFinancialOverviewParams,
  DashboardFinancialRevenueParams,
  DashboardResourcesTableParams,
  DashboardUtilizationParams,
  DateRange
} from 'types';

class DashboardService {
  static getDashboardResources(params: DateRange) {
    return httpClient.get(ENDPOINTS.dashboardPeople, {
      params
    });
  }

  static getDashboardResourcesTable(params: DashboardResourcesTableParams) {
    return httpClient.get(ENDPOINTS.dashboardPeopleTable, {
      params
    });
  }

  static getAssignmentSummaries(params: DashboardAssignmentSummariesParams) {
    return httpClient.get(ENDPOINTS.assignmentSummary, {
      params
    });
  }

  static getDashboardAvailabilitiesTable(params: DashboardResourcesTableParams) {
    return httpClient.get(ENDPOINTS.dashboardPeopleAvailabilitiesTable, {
      params
    });
  }

  static getDashboardBenchBreakdownTable() {
    return httpClient.get(ENDPOINTS.dashboardBenchBreakdownTable);
  }

  static getDashboardBenchBreakdownSkills() {
    return httpClient.get(ENDPOINTS.dashboardBenchBreakdownSkills);
  }

  static getDashboardUtilization(params: DashboardUtilizationParams) {
    return httpClient.get(ENDPOINTS.dashboardUtilization, { params });
  }

  static getDashboardUtilizationQuarters() {
    return httpClient.get(ENDPOINTS.dashboardUtilizationQuarters);
  }

  static getDashboardUtilizationCorner() {
    return httpClient.get(ENDPOINTS.dashboardUtilizationCorner);
  }

  static getDashboardSkills() {
    return httpClient.get(ENDPOINTS.dashboardSkills);
  }

  static getDashboardUtilizationBench(params: DashBoardUtilizationBenchParams) {
    return httpClient.get(ENDPOINTS.dashboardUtilizationBench, { params });
  }

  static getDashboardFinancialOverviews(params: DashboardFinancialOverviewParams) {
    return httpClient.get(ENDPOINTS.dashboardFinancialOverviews, {
      params
    });
  }

  static getDashboardFinancialProjectTypesMargin() {
    return httpClient.get(ENDPOINTS.dashboardFinancialProjectTypesMargin);
  }

  static getDashboardFinancialRevenue(params: DashboardFinancialRevenueParams) {
    return httpClient.get(ENDPOINTS.dashboardFinancialRevenues, {
      params
    });
  }

  static getDashboardFinancialQuarters() {
    return httpClient.get(ENDPOINTS.dashboardFinancialQuarters);
  }

  static getDashboardBenchOverviews(params: DashboardBenchOverviewParams) {
    return httpClient.get(ENDPOINTS.dashboardBenchOverview, {
      params
    });
  }

  static getDashboardBenchCorner() {
    return httpClient.get(ENDPOINTS.dashboardBenchCorner);
  }

  static getDashboardBenchQuarters() {
    return httpClient.get(ENDPOINTS.dashboardBenchQuarters);
  }

  static getDashboardBenchList(startDate: string, endDate: string) {
    return httpClient.get(ENDPOINTS.dashboardBenchList(startDate, endDate));
  }
}

export default DashboardService;
