import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import { ALLOCATION_TIMELINE_TYPES, ColorType, SPECIAL_EVENT_TYPES } from 'constants/constants';
import { REQUEST_DATE } from 'constants/dateFormats';

import {
  getRequestFormatDate,
  dateToString,
  stringToDate,
  addDays,
  maxDate,
  minDate,
  getDiffDays,
  isAfter,
  CommonDateType,
  getTimestamp
} from './date';

type TimelineDate = {
  startDate: string;
  endDate: string;
  isHoliday?: boolean;
};
const removeContainedDates = (dates: TimelineDate[]) => {
  if (!dates?.length) return [];

  dates.sort((a, b) => getTimestamp(a.startDate) - getTimestamp(b.startDate));

  const mergedDates: TimelineDate[] = [];

  dates.forEach(currentDate => {
    if (!mergedDates.length) {
      mergedDates.push(currentDate);
    } else {
      const lastMergedDates = mergedDates[mergedDates.length - 1];

      if (currentDate.startDate <= lastMergedDates.endDate) {
        if (currentDate.endDate > lastMergedDates.endDate) {
          lastMergedDates.endDate = currentDate.endDate;
        }
        if (!currentDate.isHoliday && currentDate.endDate <= lastMergedDates.endDate) {
          mergedDates.push(currentDate);
        }
      } else {
        mergedDates.push(currentDate);
      }
    }
  });

  return mergedDates;
};

type TimelineFragment = {
  startDate: string;
  endDate: string;
  value?: TimelineDate;
} & Record<string, unknown>;
function pushGapOrValue(
  previousEndDate: CommonDateType,
  currentStart: CommonDateType,
  timelineFragments: TimelineFragment[],
  currentEnd?: CommonDateType,
  value?: TimelineDate
) {
  const gapStart = isEmpty(timelineFragments) ? previousEndDate : addDays(previousEndDate, 1);
  const gapEnd = value ? addDays(currentStart, -1) : currentStart;

  if (gapStart && gapEnd && gapStart <= gapEnd)
    timelineFragments.push({
      startDate: getRequestFormatDate(gapStart),
      endDate: getRequestFormatDate(gapEnd)
    });

  if (value)
    timelineFragments.push({
      startDate: getRequestFormatDate(currentStart),
      endDate: getRequestFormatDate(currentEnd),
      value
    });
}

export const getTimelineFragments =
  (periodStart: string, periodEnd: string) => (data: TimelineDate[]) => {
    if (isEmpty(data))
      return [
        { startDate: getRequestFormatDate(periodStart), endDate: getRequestFormatDate(periodEnd) }
      ];

    const timelineFragments: TimelineFragment[] = [];

    let previousEndDate = stringToDate(periodStart);

    const filteredDates = removeContainedDates(data);

    filteredDates.forEach(value => {
      const currentStart =
        value.startDate && maxDate(stringToDate(value.startDate), stringToDate(periodStart));
      const currentEnd =
        value.endDate && minDate(stringToDate(value.endDate), stringToDate(periodEnd));

      if (currentStart === currentEnd) return;

      pushGapOrValue(previousEndDate, currentStart, timelineFragments, currentEnd, value);

      previousEndDate = currentEnd as Date;
    });

    pushGapOrValue(previousEndDate, stringToDate(periodEnd), timelineFragments);

    return timelineFragments;
  };

export const splitFragment = (fragment: TimelineFragment, splitDate: string) => {
  const fragmentStart = stringToDate(fragment.startDate);
  const fragmentEnd = stringToDate(fragment.endDate);
  const split = stringToDate(splitDate);

  if (split < fragmentStart || fragmentEnd < split) return [fragment];

  const part1 = {
    ...fragment,
    startDate: getRequestFormatDate(fragmentStart),
    endDate: getRequestFormatDate(split)
  };
  const part2 = {
    ...fragment,
    startDate: getRequestFormatDate(addDays(split, 1)),
    endDate: getRequestFormatDate(fragmentEnd)
  };

  return [part1, part2];
};

export const trimDates = (
  fragmentStart: CommonDateType,
  fragmentEnd: CommonDateType,
  parentStart: CommonDateType,
  parentEnd: CommonDateType
) => {
  const startDate = maxDate(parentStart, fragmentStart || parentStart);
  const endDate = minDate(parentEnd, fragmentEnd || parentEnd);

  const isStarted = isAfter(parentStart, fragmentStart);
  const isFinished = isAfter(fragmentEnd, parentEnd);

  return {
    startDate,
    endDate,
    isStarted,
    isFinished
  };
};

export const fragmentFlex = (startDate: CommonDateType, endDate: CommonDateType) =>
  getDiffDays(startDate, endDate) + 1;

export const calculateAllocationPercentage = (workHours: number, capacity: number) =>
  Math.round(100 - (workHours / capacity) * 100);

export type Assignment = {
  startDate: CommonDateType;
  endDate: CommonDateType;
  isOffDay?: boolean;
  workHours: number;
  support?: boolean;
  pending?: boolean;
};
export const getCalendarWithWorkHours = (
  assignments: Assignment[],
  startDate: CommonDateType,
  endDate: CommonDateType
) => {
  const totalDays = getDiffDays(startDate, endDate) + 1;
  const calendar = [];

  for (let i = 0; i < totalDays; i += 1) {
    const newDate = addDays(startDate, i);
    let workHours = 0;

    const resultsFound = assignments?.filter(
      assignment =>
        dayjs(assignment.startDate).isSameOrBefore(newDate) &&
        dayjs(assignment.endDate).isSameOrAfter(newDate)
    );

    for (let j = 0; j < resultsFound.length; j += 1) {
      const item = resultsFound[j];

      if (item?.isOffDay) {
        workHours = -1; // -1 indica un día de vacaciones
        break;
      }

      workHours += item?.workHours || 0;
    }

    calendar.push(workHours);
  }

  return calendar;
};

const getAllocationType = (workHours: number, capacity: number): [ColorType, number?] => {
  const { FULL_AVAILABLE, PARTIAL_AVAILABLE, UNAVAILABLE, OVERASSIGNED, VACATIONS } =
    ALLOCATION_TIMELINE_TYPES;

  const allocationPercentage = calculateAllocationPercentage(workHours, capacity);

  if (workHours === 0) return [FULL_AVAILABLE, undefined];
  if (workHours === -1) return [VACATIONS, undefined];
  if (allocationPercentage > 0 && allocationPercentage >= 20)
    return [PARTIAL_AVAILABLE, allocationPercentage];
  if (workHours > capacity || allocationPercentage < 0) return [OVERASSIGNED];
  if (allocationPercentage < 20) return [UNAVAILABLE, allocationPercentage];
  // default
  return [UNAVAILABLE, allocationPercentage];
};

export const getBarsItems = (
  calendar: number[],
  capacity: number,
  startDatePeriod: CommonDateType,
  hireDate: CommonDateType
) => {
  const result = [];
  let start =
    hireDate && isAfter(hireDate, startDatePeriod) ? getDiffDays(startDatePeriod, hireDate) + 1 : 1;
  let end;
  let previousElement;
  let previousType;
  let previousAllocPercentage;

  for (let index = 0; index < calendar.length; index += 1) {
    const element = calendar[index];
    const [currentType, allocationPercentage] = getAllocationType(element, capacity);

    if (previousElement !== element || (index !== 0 && currentType !== previousType)) {
      if (index !== 0) {
        end = index;
        result.push({
          start,
          end,
          allocationPercentage: previousAllocPercentage,
          allocationType: previousType,
          startEvent: addDays(startDatePeriod, start - 1),
          endEvent: addDays(startDatePeriod, end - 1)
        });
        start = index + 1;
      }
      previousType = currentType;
      previousAllocPercentage = allocationPercentage;
    }

    previousElement = element;
  }

  end = calendar.length;
  result.push({
    start,
    end,
    allocationPercentage: previousAllocPercentage,
    allocationType: previousType,
    startEvent: addDays(startDatePeriod, start - 1),
    endEvent: addDays(startDatePeriod, end - 1)
  });

  return result;
};

type EventWithDates = { startDate: string | Date; endDate: string | Date };
type BarProps = {
  allocationType: ColorType;
  start: number;
  end: number;
  startEvent: CommonDateType;
  endEvent: CommonDateType;
};
export const getVacationAndSpecialItems = (
  timeOffs: EventWithDates[],
  specialEvents: Array<EventWithDates & { type: SPECIAL_EVENT_TYPES }>,
  holidays: EventWithDates[],
  startDate: string | Date,
  endDate: string | Date
) => {
  const getBarProps = (event: EventWithDates[], allocationType: ColorType) => {
    const result: BarProps[] = [];
    event.map(item => {
      const endEvent = dateToString(
        minDate(new Date(item.endDate), new Date(endDate)),
        REQUEST_DATE
      );
      const startEvent = dateToString(
        maxDate(new Date(item.startDate), new Date(startDate)),
        REQUEST_DATE
      );

      const newElement = {
        allocationType,
        start: getDiffDays(startDate, startEvent) + 1,
        end: getDiffDays(startDate, endEvent) + 1,
        startEvent,
        endEvent
      };

      result.push(newElement);
    });
    return result;
  };

  const vacations = getBarProps(timeOffs, ALLOCATION_TIMELINE_TYPES.VACATIONS);
  const holidaysCountry = getBarProps(holidays, ALLOCATION_TIMELINE_TYPES.VACATIONS);
  const trainings = getBarProps(
    specialEvents.filter(({ type }) => type === SPECIAL_EVENT_TYPES.training),
    ALLOCATION_TIMELINE_TYPES.TRAINING
  );
  const conversions = getBarProps(
    specialEvents.filter(({ type }) => type === SPECIAL_EVENT_TYPES.conversion),
    ALLOCATION_TIMELINE_TYPES.CONVERSION
  );

  return { vacations, trainings, conversions, holidaysCountry };
};

type Period = {
  allocationType: ColorType;
  start: number;
  end: number;
  startEvent: CommonDateType;
  endEvent: CommonDateType;
  isHireDate?: boolean;
  isExitDate?: boolean;
};
export const getHireAndExitItems = (
  startDate: CommonDateType,
  endDate: CommonDateType,
  hireDate?: CommonDateType,
  exitDate?: CommonDateType
) => {
  const result: Period[] = [];
  if (!hireDate && !exitDate) return result;

  if (hireDate) {
    const hirePeriod = {
      allocationType: ALLOCATION_TIMELINE_TYPES.HIRE_AND_EXIT_DATES,
      start: 1,
      end: getDiffDays(startDate, hireDate) + 1,
      startEvent: startDate,
      endEvent: hireDate,
      isHireDate: true
    };
    result.push(hirePeriod);
  }

  if (exitDate) {
    const exitPeriod = {
      allocationType: ALLOCATION_TIMELINE_TYPES.HIRE_AND_EXIT_DATES,
      start: getDiffDays(startDate, exitDate) + 1,
      end: getDiffDays(startDate, endDate) + 1,
      startEvent: exitDate,
      endEvent: endDate,
      isExitDate: true
    };
    result.push(exitPeriod);
  }
  return result;
};
