import { Option } from 'types';

export const DEFAULT_ASSIGNMENT_HOURS = 0;
export const DEFAULT_CAPACITY_HOURS = 7.5;
export const DEFAULT_EXPECTED_PERCENTAGE = 100;
export const INITIAL_PERCENTAGE_ASSIGNMENT = 100;

export const projectStatus: Record<string, Option<number>> = {
  warranty: { label: 'status.warranty', value: 0 },
  planned: { label: 'status.planned', value: 1 },
  confirmed: { label: 'status.confirmedOrActive', value: 2 },
  maintenance: { label: 'status.maintenance', value: 3 },
  onHold: { label: 'status.onHold', value: 4 }
};

export const projectStatusArray = Object.values(projectStatus);

export const projectServiceTypes: Record<string, Option<number>> = {
  discovery: { label: 'serviceType.discovery', value: 0 },
  fullProduct: { label: 'serviceType.fullProduct', value: 1 },
  staffAugmentation: { label: 'serviceType.staffAugmentation', value: 2 },
  maintenance: { label: 'status.maintenance', value: 3 }
};

export const projectServiceTypeArray = Object.values(projectServiceTypes);

export enum ASSIGNMENT_STATUS {
  open = 'open',
  pending = 'pending',
  confirmed = 'confirmed'
}
