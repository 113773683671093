import { createAction } from '@reduxjs/toolkit';

import notificationService from 'services/notificationService';
import { ErrorAsyncThunk, NotificationRequestType } from 'types';
import { NotificationResponse } from 'types/notifications';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';

export const updateNotificationMarkRead = createAction(
  'UPDATE_NOTIFICATION_MARK_READ',
  payload => ({ payload })
);
export const updateNotificationMarkSeen = createAction('UPDATE_NOTIFICATION_MARK_SEEN');

export const getNotificationHeader = createAsyncThunk<NotificationResponse, void>(
  'GET_NOTIFICATION_HEADER',
  async () => {
    try {
      const { data } = await notificationService.getNotificationsHeader();
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const markNotificationAsRead = createAsyncThunk(
  'MARK_NOTIFICATION_AS_READ',
  async ({ notificationId }: NotificationRequestType) => {
    try {
      const { data } = await notificationService.markNotificationAsRead({ notificationId });
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const markNotificationAsSeen = createAsyncThunk('MARK_NOTIFICATION_AS_SEEN', async () => {
  try {
    const { data } = await notificationService.markNotificationAsSeen();
    return data;
  } catch (error) {
    const {
      response: { data }
    } = error as ErrorAsyncThunk;
    throw parseError(data);
  }
});

export const deleteNotification = createAsyncThunk(
  'DELETE_NOTIFICATION',
  async (notificationId: number) => {
    try {
      await notificationService.deleteNotification(notificationId);
      return notificationId;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const acknowledgeNotification = createAsyncThunk(
  'ACKNOWLEDGE_NOTIFICATION',
  async (notificationId: number) => {
    try {
      await notificationService.acknowledgeNotification(notificationId);
      return notificationId;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);
