import { isEmpty } from 'lodash';

import assignmentTypes from 'constants/assignmentTypes';
import {
  PROJECT_TYPES,
  WORK_KIND,
  SENIORITY_MAP,
  BILLING_UNITS,
  ASSIGNMENTS_MAX_COUNT,
  BUSINESS_TYPES
} from 'constants/constants';
import { DEFAULT_CAPACITY_HOURS, DEFAULT_EXPECTED_PERCENTAGE } from 'constants/projectConstants';
import { DateRange } from 'types';

import { getRequestFormatDate } from './date';
import { convertCostsToBillingUnit } from './financialUtilities';
import { filterById } from './helpers';

const composeNote = (body: string, name: string, userId: number) => ({
  title: `Note for project ${name}`,
  body: body || '',
  writerId: userId
});

type ComposeAssignmentParams = DateRange & {
  workHours: number;
  projectId: number;
  clientId: number;
  ongoing: boolean;
  isNonBillable: boolean;
  pending: boolean;
  percentage: number;
  backfillPosition: boolean;
  rate: number;
  support: boolean;
};

const composeAssignmentBase = ({
  workHours,
  startDate,
  endDate,
  projectId,
  clientId,
  ongoing,
  isNonBillable,
  pending,
  percentage,
  backfillPosition,
  rate,
  support
}: ComposeAssignmentParams) => ({
  workHours: +workHours,
  startDate: getRequestFormatDate(startDate),
  endDate: ongoing ? null : getRequestFormatDate(endDate),
  projectId,
  clientId,
  ongoing,
  assignationType: isNonBillable ? PROJECT_TYPES.NON_BILLABLE : PROJECT_TYPES.BILLABLE,
  pending,
  percentage: +percentage,
  backfillPosition,
  rate: +rate,
  support: !!support
});

type ComposeAssignmentRequestParams = {
  userId: number;
  project: {
    id: number;
    clientId: number;
    name: string;
  };
  values: ComposeAssignmentParams & {
    note: string;
    personId?: {
      value: {
        id: number;
      };
    };
    skillIds?: {
      value: {
        id: number;
      };
    }[];
    role?: {
      value: {
        id: number;
      };
    };
    seniority: {
      value: string;
    };
    rate: number;
    quantity: {
      value: number;
    };
    dates: DateRange;
  };
  personDetail: {
    workHours: number;
  };
};

export const composeAssignmentRequest = ({
  userId,
  project: { id: projectId, clientId, name },
  values: {
    note: body,
    personId,
    skillIds,
    role,
    seniority,
    rate,
    quantity: assignmentQuantity,
    dates: { startDate, endDate },
    ...values
  },
  personDetail
}: ComposeAssignmentRequestParams) => {
  const assignment = {
    ...composeAssignmentBase({
      ...values,
      projectId,
      clientId,
      rate,
      startDate,
      endDate
    }),
    personId: personId?.value?.id || null,
    skillIds: skillIds?.map(({ value }) => value.id),
    roleId: role?.value?.id || null,
    seniority: seniority?.value || null,
    rate:
      Number(
        convertCostsToBillingUnit(
          {
            from: BILLING_UNITS.DAILY,
            to: BILLING_UNITS.HOURLY,
            capacity: personDetail?.workHours
          },
          rate
        )
      ) || null
  };
  const quantity = assignmentQuantity?.value;
  const note = composeNote(body, name, userId);
  return { assignment, quantity, note };
};

type ComposeQuickAssignmentRequestParams = {
  values: DateRange & {
    pending: boolean;
  };
  personId: number;
};

export const composeQuickAssignmentRequest = ({
  values: { pending, startDate, endDate },
  personId
}: ComposeQuickAssignmentRequestParams) => {
  const assignment = {
    personId,
    pending: !pending,
    startDate,
    endDate
  };
  return { assignment };
};

type ComposeConfirmAssignmentRequestParams = {
  values: DateRange;
};

export const composeConfirmAssignmentRequest = ({
  values: { startDate, endDate }
}: ComposeConfirmAssignmentRequestParams) => {
  const assignment = {
    pending: false,
    startDate,
    endDate
  };
  return { assignment };
};

type ComposeProjectRequestParams = {
  values: {
    name: string;
    projectType: PROJECT_TYPES;
    serviceType?: {
      value: string;
    };
    notes: string | null;
    status: {
      value: string;
    };
    industryId?: {
      value: {
        id: number;
      };
    };
    businessType: BUSINESS_TYPES;
    code: string;
    clientId?: {
      value: {
        id: number;
      };
    };
    clientName: string;
    id: number;
    dates: DateRange;
    salesPersonId: {
      value: {
        id: number;
      };
    };
    deliveryOwnerPersonId: {
      value: {
        id: number;
      };
    };
    managerPersonId: {
      value: {
        id: number;
      };
    };
  };
};

export const composeProjectRequest = ({ values }: ComposeProjectRequestParams) => {
  const {
    name,
    projectType,
    serviceType,
    notes,
    status,
    industryId,
    businessType,
    code,
    clientId,
    clientName,
    id,
    dates,
    salesPersonId,
    deliveryOwnerPersonId,
    managerPersonId
  } = values;

  const payload: Record<string, unknown> = {
    id,
    name,
    businessType,
    code,
    clientId: clientId?.value?.id || '',
    industryId: industryId?.value?.id || '',
    clientName: clientId?.value?.id ? '' : clientName.trim(),
    serviceType: serviceType ? serviceType.value : null,
    notes,
    projectType: projectType ? PROJECT_TYPES.NON_BILLABLE : PROJECT_TYPES.BILLABLE,
    salesPersonId: salesPersonId?.value?.id || null,
    deliveryOwnerPersonId: deliveryOwnerPersonId?.value?.id || null,
    managerPersonId: managerPersonId?.value?.id || null
  };

  const { startDate, endDate } = dates || {};

  if (!id) {
    payload.status = status ? status.value : null;

    if (startDate) payload.startDate = startDate && getRequestFormatDate(startDate);

    if (endDate) payload.endDate = endDate && getRequestFormatDate(endDate);
  }

  return payload;
};

const getTimeWorkResource = (fullTime: boolean) =>
  fullTime ? WORK_KIND.FULL_TIME : WORK_KIND.PART_TIME;

type ComposeResourceRequestParams = {
  values: {
    capacityInHours: number;
    location: {
      value: {
        id: number;
      };
    };
    seniority: {
      value: string;
    };
    department: {
      value: {
        value: number;
      };
    };
    email: string;
    firstName: string;
    lastName: string;
    freelancer: boolean;
    fullTime: boolean;
    hourRate: number;
    role: {
      value: { id: number };
    };
  };
  experiencesSelected: {
    skillId: number;
    weeks: number;
  }[];
};

export const composeResourceRequest = ({
  values,
  experiencesSelected
}: ComposeResourceRequestParams) => {
  const {
    capacityInHours: workHours,
    location,
    seniority: senioritySelected,
    department,
    email,
    firstName,
    lastName,
    freelancer,
    fullTime,
    hourRate,
    role
  } = values;

  const { id: locationId } = location.value;
  const { value: departmentId } = department.value;
  const workKind = freelancer ? WORK_KIND.CONTRACTOR : getTimeWorkResource(fullTime);
  const experiences = experiencesSelected.map(({ skillId, weeks }) => ({ skillId, weeks }));
  const { value: seniority } = senioritySelected;
  const { id: roleId } = role.value;
  const roleAssignment = { roleId, seniority };

  const payload = {
    firstName,
    lastName,
    email,
    locationId,
    departmentId,
    workKind,
    workHours,
    hourRate: hourRate || '0',
    experiences,
    roleAssignment: roleAssignment || ''
  };
  return { payload };
};

export const composeConfirmRequest = ({ values }: { values: unknown }) => values;

const toAssignmentOption = (
  ids: number[],
  options: {
    value: {
      id: number;
    };
  }[]
) => options.filter(x => ids.includes(x.value.id));

export const initialAssignmentOptions = (
  originalPersonId: number,
  originalSkillIds: number[],
  assignmentOptions: {
    options: {
      value: {
        id: number;
      };
    }[];
  }[]
) => {
  const [{ options: resourcesOptions }, { options: skillsOptions }] = assignmentOptions;

  const [personId] = toAssignmentOption([originalPersonId], resourcesOptions);
  const skillIds = toAssignmentOption(originalSkillIds, skillsOptions);
  return { personId, skillIds };
};

export const projectToAssignmentOption = (project: { name: string; id: number }) => ({
  label: project.name,
  value: project,
  key: project.id
});

export const skillToAssignmentOption = ({
  id,
  name,
  departmentId
}: {
  id: number;
  name: string;
  departmentId: number;
}) => ({
  label: name,
  value: { id, type: assignmentTypes.skills.value },
  key: name,
  id: `skill-${id}`,
  departmentId
});

export const locationToOption = ({
  id,
  city,
  country
}: {
  id: number;
  city: string;
  country: string;
}) => ({
  label: `${country}, ${city}`,
  value: { id, type: assignmentTypes.location.value },
  key: city
});

export const rolesToOption = ({ id, name }: { id: number; name: string }) => ({
  label: name.charAt(0).toUpperCase() + name.slice(1),
  value: { id, type: assignmentTypes.roles.value },
  key: name,
  id: `role-${id}`
});

export const seniorityToOption = (
  key: string,
  intlFormatMessage: (value: { id?: string }) => string
) => {
  const { label, ...seniority } = SENIORITY_MAP.get(key) || {};
  return {
    label: intlFormatMessage({ id: label }),
    ...seniority
  };
};

export const personToAssignmentOption = ({
  id,
  name,
  fullName
}: {
  id: number;
  name: string;
  fullName: string;
}) => ({
  label: name || fullName,
  value: { id, type: assignmentTypes.resources.value },
  key: `resource-${id}-${name}`,
  id: `resource-${id}`
});

export const customerToAssignmentOption = ({ id, name }: { id: number; name: string }) => ({
  label: name,
  value: { id, type: assignmentTypes.clients.value },
  key: `client-${id}-${name}`,
  id: `client-${id}`
});

export const industriesToOption = ({ id, name }: { id: number; name: string }) => ({
  label: name,
  value: { id, type: assignmentTypes.industries.value },
  key: `industry-${id}-${name}`
});

export const assignmentsToOption = () => {
  const options = [];

  for (let i = 1; i <= ASSIGNMENTS_MAX_COUNT; i += 1) {
    options.push({
      label: `${i}`,
      value: i,
      key: `quantity-${i}`
    });
  }
  return options;
};

export const getExpectedPercentage = (
  roleId: number,
  roles: { id: number }[],
  resourceId: number,
  resources: { id: number }[]
) => {
  const resource = filterById(resourceId, resources);
  const role = filterById(roleId, roles);

  if (!isEmpty(resource)) {
    // @ts-expect-error Fix returned type filterById
    return resource[0].role.expectedPercentage ?? DEFAULT_EXPECTED_PERCENTAGE;
  }

  if (!isEmpty(role)) {
    // @ts-expect-error Fix returned type filterById
    return role[0].expectedPercentage ?? DEFAULT_EXPECTED_PERCENTAGE;
  }

  return DEFAULT_EXPECTED_PERCENTAGE;
};

export const getResourceWorkHours = (resourceId: number, resources: { id: number }[]) => {
  const resource = filterById(resourceId, resources);
  // @ts-expect-error Fix returned type filterById
  return resource[0]?.workHours ?? DEFAULT_CAPACITY_HOURS;
};
