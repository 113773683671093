import isEmpty from 'lodash/isEmpty';

import { fields } from 'components/Financial/ProjectFinancialInfoForm';
import { precisionFormat } from 'utils/number';

import { EXPERIENCE_INPUTS } from './constants';

type ValidateFunction<T> = (value: T) => true | string;

type CustomValidator = {
  [key: string]: {
    validate: ValidateFunction<string> | ValidateFunction<number>;
  };
};

export const assignmentValidator: CustomValidator = {
  role: {
    validate: (value: string) => !isEmpty(value) || 'assignment.role.required'
  },
  percentage: {
    validate: (value: number) => (value >= 1 && value <= 100) || 'assignment.percentage.max'
  }
};

export const editProjectFormValidations = {
  name: {
    required: 'projectName.presence'
  },
  code: {
    required: 'projectCode.presence'
  },
  clientId: {
    required: 'customer.presence'
  },
  serviceType: {
    required: 'serviceType.presence'
  }
};

export const newProjectFormValidations = {
  ...editProjectFormValidations,
  status: {
    required: 'status.presence'
  }
};

export const newResourceFormValidations = {
  email: {
    presence: { message: 'email.presence' },
    email: { message: 'email.invalid' }
  },
  location: {
    presence: { message: 'location.presence' }
  },
  department: {
    presence: { message: 'department.presence' }
  },
  role: {
    presence: { message: 'role.presence' }
  },
  seniority: {
    presence: { message: 'seniority.presence' }
  },
  capacityInHours: {
    numericality: {
      greaterThan: 0,
      lessThanOrEqualTo: 7.5,
      message: 'resource.capacityHours.range'
    }
  },
  experiencesSelected: {
    experiencesSelectedValidator: { message: 'skills.presence' }
  }
};

type ProjectFinancialInfoFields = typeof fields;

export const projectFinancialInfoValidator =
  ({ clientBudget, costsAdjustment, billedAdjustment }: ProjectFinancialInfoFields) =>
  (values: ProjectFinancialInfoFields) => {
    const {
      clientBudget: clientBudgetValue,
      costsAdjustment: costsAdjustmentValue,
      billedAdjustment: billedAdjustmentValue
    } = values;
    const isFieldValid = (value: string) => value && precisionFormat(value) >= 0;
    return {
      ...(!isFieldValid(clientBudgetValue) && { [clientBudget]: ['project.clientBudget.range'] }),
      ...(!isFieldValid(costsAdjustmentValue) && { [costsAdjustment]: ['project.cost.range'] }),
      ...(!isFieldValid(billedAdjustmentValue) && { [billedAdjustment]: ['project.billed.range'] })
    };
  };

export const openPeriodValidator = {
  name: {
    presence: { message: 'periodName.presence' },
    length: { maximum: 40 }
  }
};

export const editResourceDetailsValidations = {
  englishLevel: {
    required: 'englishLevel.presence'
  }
};

export const softSkillValidation = {
  skill: { required: 'softSkill.presence' }
};

export const technicalSkillsValidations = {
  value: { required: 'technicalSkill.presence' },
  years: {
    max: { value: EXPERIENCE_INPUTS.years.max, message: 'years.presence' },
    min: { value: EXPERIENCE_INPUTS.years.min, message: 'years.presence' }
  },
  months: {
    max: { value: EXPERIENCE_INPUTS.months.max, message: 'months.presence' },
    min: { value: EXPERIENCE_INPUTS.months.min, message: 'months.presence' }
  }
};

export const trainingSkillValidation = {
  skill: { required: 'technicalSkill.presence' }
};
