import Zoom from '@mui/material/Zoom';
import { bool, node, oneOf, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { TOOLTIP_VARIANTS } from 'constants/constants';

import { Content, StyledTooltip, TooltipContainer } from './styles';

const MuiTooltip = ({
  children,
  className = '',
  variant = TOOLTIP_VARIANTS.WHITE,
  width,
  textIntlId = '',
  text = '',
  disablePortal = false,
  ...props
}) => {
  const intl = useIntl();
  return (
    <StyledTooltip
      placement="top"
      TransitionComponent={Zoom}
      title={
        <Content variant={variant}>
          {textIntlId ? intl.formatMessage({ id: textIntlId }) : text || children}
        </Content>
      }
      arrow={variant === TOOLTIP_VARIANTS.WHITE}
      variant={variant}
      className={className}
      width={width}
      PopperProps={{
        disablePortal
      }}
      {...props}
    >
      <TooltipContainer />
    </StyledTooltip>
  );
};

MuiTooltip.propTypes = {
  children: node,
  className: string,
  variant: oneOf(Object.values(TOOLTIP_VARIANTS)),
  width: string,
  textIntlId: string,
  text: string,
  disablePortal: bool
};

export default MuiTooltip;
