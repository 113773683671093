export const toCurrency = (
  number: number | string,
  options: Intl.NumberFormatOptions = {
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency'
  }
) => (Number(number) || 0).toLocaleString('en-US', options);

export const precisionFormat = (number: number | string) => {
  if (typeof number === 'string') {
    return parseFloat(number.replaceAll(',', ''));
  }
  return number;
};

export const formatAmount = (amount: number | string, round = false) => {
  if (Number(amount)) {
    const value = Number(amount);
    return round ? Math.round(value) : parseFloat(value.toFixed(2));
  }
  return 0;
};

export const formatFractionDigits = (number: number, maxFractionDigits: number) =>
  number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxFractionDigits
  });
