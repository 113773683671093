import { func, bool, number, string } from 'prop-types';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import CenteredLoading from 'components/common/Loading/CenteredLoading';
import Modal from 'components/common/Modal';
import routesPaths from 'constants/routesPaths';
import { AssignmentShape } from 'constants/shapes';
import { useDispatch, useActionOnSuccess, useStatus } from 'hooks';
import { editQuickAssignment } from 'state/actions/assignmentActions';

import AssignmentQuickForm from '../AssignmentQuickForm';
import { SubTitle, Title } from './styles';

const EditQuickAssignmentModal = ({
  personId,
  isShowing,
  hide,
  name,
  busyPercentage,
  assignment
}) => {
  const intl = useIntl();

  const editAssignmentRequest = useDispatch(editQuickAssignment);
  const resetEditStatusRequest = useDispatch(editQuickAssignment.reset);
  const {
    status: editAssignmentStatus,
    error: editAssignmentError,
    isPending
  } = useStatus(editQuickAssignment);

  const history = useHistory();

  const assignmentId = assignment.id;

  const onSubmit = ({ assignment }) => {
    editAssignmentRequest({ assignment, assignmentId });
  };

  useActionOnSuccess([editAssignmentStatus], () => {
    history.push(routesPaths.projectsOverview);

    hide();
  });

  useEffect(
    () => () => {
      resetEditStatusRequest();
    },
    [resetEditStatusRequest]
  );

  return (
    <Modal isShowing={isShowing} hide={hide} withoutOverflow>
      <Title>
        <FormattedMessage id="template.confirmName" values={{ name }} />
      </Title>
      <SubTitle>{intl.formatMessage({ id: 'editQuickAssignment.subtitle' })}</SubTitle>
      {Object.keys(assignment).length ? (
        <AssignmentQuickForm
          personId={personId}
          onSubmit={onSubmit}
          error={editAssignmentError}
          initialValues={assignment}
          isPending={isPending}
          hide={hide}
          busyPercentage={busyPercentage}
        />
      ) : (
        <CenteredLoading />
      )}
    </Modal>
  );
};

EditQuickAssignmentModal.propTypes = {
  busyPercentage: number,
  hide: func.isRequired,
  isShowing: bool.isRequired,
  name: string,
  personId: number,
  assignment: AssignmentShape
};

export default EditQuickAssignmentModal;
