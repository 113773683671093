/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { RESET } from 'constants/actionStatusConstants';
import { AsyncThunkConfig } from 'types';

export default <Returned, ThunkArg>(type: string, payload: any, options?: any) => {
  const thunk = createAsyncThunk<Returned, ThunkArg, AsyncThunkConfig>(type, payload, options);
  thunk.reset = createAction(`${type}/${RESET}`);
  return thunk;
};
