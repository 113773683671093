import { cloneDeep } from 'lodash';
import { arrayOf, bool, func, number, oneOfType, string } from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as DiscountIcon } from 'assets/icons/discount.svg';
import { ReactComponent as NonBillable } from 'assets/icons/nonBillable.svg';
import IconWithTooltip from 'components/common/IconWithTooltip';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import { AllocationBillInput, PercentageInput, RateInput } from 'components/Financial/Inputs';
import { Container, ContainerScroll, Label } from 'components/Financial/Layout/Tables';
import { BILLING_UNITS } from 'constants/constants';
import { periodDetailShape } from 'constants/shapes';
import { useRequest, useStatus } from 'hooks';
import { getClosedPeriodDetail, updateClosedPeriodInfo } from 'state/actions/financialActions';
import { getConvertedValue } from 'utils/financialUtilities';
import { capitalizeFirstLetter } from 'utils/searchPeopleUtilities';

import { StyledColumn, StyledHeader, StyledRow, StyledCount, ResourceData } from './styles';

const minWidth = '23rem';
const { DAILY } = BILLING_UNITS;

function PeriodDetail({ activeTab, isEdit, periodId, setPeriodDetailCopy, periodDetailCopy }) {
  const intl = useIntl();

  const [periodDetail, setPeriodDetail] = useState(null);
  const [billingUnit, setBillingUnit] = useState(DAILY);

  const handleBillingUnitChange = unit => setBillingUnit(unit.toLowerCase());

  const handleClosedPeriodDetails = useCallback(
    (resourceId, keyField, editedValue) => {
      const resourcePos = periodDetail?.findIndex(resource => resource.id === resourceId);

      const updateClosedPeriodStateCb = prevState =>
        prevState.map((item, index) =>
          index === resourcePos
            ? {
                ...item,
                [keyField]: editedValue
              }
            : item
        );

      setPeriodDetail(prevState => updateClosedPeriodStateCb(prevState));
      setPeriodDetailCopy(prevState => updateClosedPeriodStateCb(prevState ?? periodDetail));
    },
    [periodDetail]
  );

  const [{ isPending }, getPeriodRequest] = useRequest(
    getClosedPeriodDetail,
    {
      fulfilledCallback: ({ periodDetails }) => {
        setPeriodDetail(periodDetails);
        isEdit && setPeriodDetailCopy(cloneDeep(periodDetails));
      },
      params: { periodId }
    },
    false
  );

  const { isPending: isUpdating } = useStatus(updateClosedPeriodInfo);

  useEffect(() => {
    if (!periodDetail && !periodDetailCopy) {
      getPeriodRequest({ periodId });
    }
  }, [periodDetail, periodDetailCopy]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleBillingUnitChange(activeTab);
  }, [activeTab]);

  return (
    <Container>
      <StyledHeader isSticky={isEdit}>
        <StyledColumn minWidth={minWidth} firstColumn>
          <Label>
            {intl.formatMessage({ id: 'common.cap.assignments' })}
            <StyledCount>{`(${periodDetail?.length || 0})`}</StyledCount>
          </Label>
        </StyledColumn>
        <StyledColumn>{intl.formatMessage({ id: 'common.seniority' })}</StyledColumn>
        <StyledColumn>{intl.formatMessage({ id: 'common.cap.allocation' })}</StyledColumn>
        <StyledColumn>{intl.formatMessage({ id: 'common.rate' })}</StyledColumn>
        <StyledColumn>
          {intl.formatMessage({ id: 'financial.table.periodDetail.alloc' })}
        </StyledColumn>
        <StyledColumn>
          {intl.formatMessage({ id: 'financial.table.periodDetail.bill' })}
        </StyledColumn>
      </StyledHeader>
      <ContainerScroll>
        {periodDetail && !isPending && !isUpdating ? (
          periodDetail?.map(period => (
            <StyledRow key={period?.id}>
              <StyledColumn strong minWidth={minWidth} firstColumn>
                <ResourceData>
                  {period?.resource}
                  {!period?.billable && (
                    <IconWithTooltip
                      icon={<NonBillable />}
                      message={intl.formatMessage({ id: 'common.nonBillable' })}
                    />
                  )}
                </ResourceData>
              </StyledColumn>
              <StyledColumn>{capitalizeFirstLetter(period?.seniority) || '-'}</StyledColumn>
              <StyledColumn isEditing={isEdit} leftSeparator={isEdit} paddingLeft="0">
                <PercentageInput
                  key={isEdit}
                  value={period?.allocationPercentage}
                  id={period?.id}
                  field="allocationPercentage"
                  onChange={handleClosedPeriodDetails}
                  disabled={!isEdit}
                />
              </StyledColumn>
              <StyledColumn isEditing={isEdit} paddingLeft="0">
                <RateInput
                  key={isEdit}
                  rate={getConvertedValue({
                    valueToConvert: period?.rate,
                    capacity: period?.personCapacity,
                    billingUnit,
                    hours: false
                  })}
                  billingUnit={billingUnit}
                  id={period?.id}
                  capacity={period?.personCapacity}
                  onChange={handleClosedPeriodDetails}
                  disabled={!isEdit}
                  width="fit-content"
                />
                {period?.discountPercentage ? (
                  <IconWithTooltip
                    icon={<DiscountIcon />}
                    message={intl.formatMessage(
                      { id: 'common.discountOf' },
                      { discount: period?.discountPercentage }
                    )}
                  />
                ) : null}
              </StyledColumn>
              <StyledColumn isEditing={isEdit} paddingLeft="0">
                <AllocationBillInput
                  key={isEdit}
                  value={getConvertedValue({
                    valueToConvert: period?.allocationHours,
                    capacity: period?.personCapacity,
                    percentage: period?.allocationPercentage,
                    billingUnit
                  })}
                  billingUnit={billingUnit}
                  id={period?.id}
                  capacity={period?.personCapacity}
                  allocationPercentage={period?.allocationPercentage}
                  field="allocationHours"
                  onChange={handleClosedPeriodDetails}
                  disabled={!isEdit}
                />
              </StyledColumn>
              <StyledColumn isEditing={isEdit} rightSeparator={isEdit} paddingLeft="0">
                <AllocationBillInput
                  key={isEdit}
                  value={getConvertedValue({
                    valueToConvert: period?.bill,
                    capacity: period?.personCapacity,
                    percentage: period?.allocationPercentage,
                    billingUnit
                  })}
                  billingUnit={billingUnit}
                  id={period?.id}
                  capacity={period?.personCapacity}
                  allocationPercentage={period?.allocationPercentage}
                  field="bill"
                  onChange={handleClosedPeriodDetails}
                  disabled={!isEdit}
                />
              </StyledColumn>
            </StyledRow>
          ))
        ) : (
          <CenteredLoading />
        )}
      </ContainerScroll>
    </Container>
  );
}

PeriodDetail.propTypes = {
  activeTab: string,
  isEdit: bool,
  periodId: oneOfType([string, number]),
  setPeriodDetailCopy: func,
  periodDetailCopy: arrayOf(periodDetailShape)
};

export default PeriodDetail;
