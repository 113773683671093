import { useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { Row } from 'components/common/Layout/Row';
import Select from 'components/common/Select';
import { FULFILLED } from 'constants/actionStatusConstants';
import { YEARS_OPTIONS, MONTHS_OPTIONS } from 'constants/constants';
import { experienceRowShape } from 'constants/shapes';
import { useSkills, useHeights, useDispatch } from 'hooks';
import { filterSkillsByTeam } from 'state/actions/uiActions';
import { parseInputErrors } from 'utils/helpers';

import DeleteButton from '../DeleteButton';
import { ExperienceRowContainer, RowInputWrapper, ErrorSpan } from './styles';

const ExperienceRow = ({
  skillsFields,
  departmentId,
  handleSkillSelectChange,
  handleRemoveSkill
}) => {
  const intl = useIntl();
  const { inputHeight } = useHeights();
  const { skillOptions, skillsStatus } = useSkills();
  const filterSkillsRequest = useDispatch(filterSkillsByTeam);

  useEffect(() => {
    if (skillsStatus === FULFILLED) {
      filterSkillsRequest(departmentId);
    }
  }, [departmentId, filterSkillsRequest, skillsStatus]);

  return skillsFields.map((field, index) => (
    <ExperienceRowContainer key={index}>
      {field.error && (
        <ErrorSpan>
          <FormattedMessage
            id={parseInputErrors(field.error)}
            defaultMessage={parseInputErrors(field.error)}
          />
        </ErrorSpan>
      )}
      <Row>
        <RowInputWrapper>
          <Select
            name="skillId"
            label={intl.formatMessage({ id: 'common.skill' })}
            options={skillOptions}
            height={inputHeight}
            onChange={event => handleSkillSelectChange('skill', index, event)}
            value={field.skill}
            isSearchable
            withoutHighlight
          />
        </RowInputWrapper>
        <RowInputWrapper>
          <Select
            name="years"
            label={intl.formatMessage({ id: 'resource.years' })}
            options={YEARS_OPTIONS}
            onChange={event => handleSkillSelectChange('years', index, event)}
            height={inputHeight}
            value={field.years}
            withoutHighlight
          />
        </RowInputWrapper>
        <RowInputWrapper>
          <Select
            name="months"
            label={intl.formatMessage({ id: 'resource.months' })}
            options={MONTHS_OPTIONS}
            onChange={event => handleSkillSelectChange('months', index, event)}
            height={inputHeight}
            value={field.months}
            withoutHighlight
          />
        </RowInputWrapper>
        <DeleteButton onClick={event => handleRemoveSkill(index, event)} />
      </Row>
    </ExperienceRowContainer>
  ));
};

ExperienceRow.propTypes = experienceRowShape;

export default ExperienceRow;
