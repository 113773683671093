import { AxiosResponse } from 'axios';

import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';
import {
  ClosedPeriodDetails,
  EditFinancialProjectParams,
  FinancialProject,
  FinancialProjectBase,
  OpenPeriodDetails,
  OpenPeriodParams,
  OpenedResource,
  Period,
  ResourceSetup,
  ResourceSetupParams,
  SuccessResponse,
  UpdateFinancialPeriodInfoParams
} from 'types';

class FinancialServices {
  static async getProjects(): Promise<AxiosResponse<{ projects: FinancialProjectBase[] }>> {
    return httpClient.get(ENDPOINTS.financialProjects);
  }

  static async getProject(id: number): Promise<AxiosResponse<FinancialProject>> {
    return httpClient.get(ENDPOINTS.getFinancialProject(id));
  }

  static async deletePeriod(periodId: number) {
    return httpClient.delete(ENDPOINTS.getFinancialPeriod(periodId));
  }

  static async editProject({ id, ...values }: EditFinancialProjectParams) {
    return httpClient.put(ENDPOINTS.getFinancialProject(id), values);
  }

  static async getResourceSetup(
    id: number
  ): Promise<AxiosResponse<{ periodSetup: ResourceSetup[] }>> {
    return httpClient.get(ENDPOINTS.getFinancialResourceSetup(id));
  }

  static async getProjectClosedPeriods(
    id: number
  ): Promise<AxiosResponse<{ financialPeriods: Period[] }>> {
    return httpClient.get(ENDPOINTS.getProjectClosedPeriods(id));
  }

  static async getClosedPeriod(
    id: number
  ): Promise<AxiosResponse<{ id: number; periodDetails: ClosedPeriodDetails }>> {
    return httpClient.get(ENDPOINTS.getFinancialPeriod(id));
  }

  static async getProjectOpenedPeriods(id: number): Promise<AxiosResponse<{ periods: Period[] }>> {
    return httpClient.get(ENDPOINTS.getProjectOpenedPeriods(id));
  }

  static async getProjectOpenedResources(
    id: number
  ): Promise<AxiosResponse<{ resources: OpenedResource[] }>> {
    return httpClient.get(ENDPOINTS.getProjectOpenedResources(id));
  }

  static async createOpenPeriod(data: OpenPeriodParams): Promise<AxiosResponse<OpenPeriodDetails>> {
    return httpClient.post(ENDPOINTS.financialPeriod, data);
  }

  static async closeOpenPeriod(id: number): Promise<AxiosResponse<SuccessResponse>> {
    return httpClient.put(ENDPOINTS.closeOpenPeriod(id));
  }

  static async updatePeriodInfo({
    periodId,
    ...values
  }: UpdateFinancialPeriodInfoParams): Promise<AxiosResponse<SuccessResponse>> {
    return httpClient.put(ENDPOINTS.getFinancialPeriod(periodId), values);
  }

  static async updateResourceSetup(
    values: ResourceSetupParams
  ): Promise<AxiosResponse<SuccessResponse>> {
    return httpClient.put(ENDPOINTS.financialSetups, values);
  }

  static async refreshResourceSetup(
    id: number
  ): Promise<AxiosResponse<{ periodSetup: ResourceSetup[] }>> {
    return httpClient.put(ENDPOINTS.refreshFinancialResourceSetup(id));
  }

  static async getRefreshResourceSetup(
    id: number
  ): Promise<AxiosResponse<{ periodSetup: ResourceSetup[] }>> {
    return httpClient.get(ENDPOINTS.getRefreshedFinancialResourceSetup(id));
  }

  static async getExportData(startDate: string, endDate: string): Promise<AxiosResponse<unknown>> {
    return httpClient.get(ENDPOINTS.getExportData(startDate, endDate));
  }
}

export default FinancialServices;
