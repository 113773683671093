import { FormattedMessage, MessageDescriptor } from 'react-intl';

type IntlFormatter = (descriptor: MessageDescriptor) => string;

export enum GROUPING_TYPES {
  weekly = 'weekly',
  monthly = 'monthly'
}

export const DASHBOARD_DATE_TYPES = {
  week: { label: <FormattedMessage id="dashboard.time.week" />, value: 0, unit: 'week' },
  month: {
    label: <FormattedMessage id="common.month" />,
    value: 1,
    unit: 'month',
    groupingType: GROUPING_TYPES.weekly
  },
  quarter: {
    label: <FormattedMessage id="common.cap.quarter" />,
    value: 2,
    unit: 'quarter',
    groupingType: GROUPING_TYPES.monthly
  },
  year: {
    label: <FormattedMessage id="dashboard.time.year" />,
    value: 3,
    unit: 'year',
    groupingType: GROUPING_TYPES.monthly
  },
  custom: { label: <FormattedMessage id="dashboard.time.custom" />, value: 4 }
};

export const dashboardDateTypesArray = Object.values(DASHBOARD_DATE_TYPES);

export const LOOK_AHEAD_OPTIONS = [
  { key: 'current', label: 'dashboard.time.lookAhead.current', value: 0 },
  { key: '1M', label: 'dashboard.time.lookAhead.1m', value: 1 },
  { key: '2MS', label: 'dashboard.time.lookAhead.2ms', value: 2 },
  { key: '3MS', label: 'dashboard.time.lookAhead.3ms', value: 3 },
  { key: '6MS', label: 'dashboard.time.lookAhead.6ms', value: 6 }
];

export const getLookAheadOptions = (intlFormatter: IntlFormatter) =>
  LOOK_AHEAD_OPTIONS.map(option => ({
    ...option,
    label: option?.label && intlFormatter({ id: option.label })
  }));

export const UTILIZATION = {
  available: { key: 'available', color: '#4089F8', intl: 'dashboard.availableCapacity' },
  billable: { key: 'billable', color: '#FF8540', intl: 'dashboard.billableCapacity' },
  scheduled: { key: 'scheduled', color: '#26E7A6', intl: 'common.scheduledUtilization' },
  demand: { key: 'demand', color: '#C46AFB', intl: 'common.demand' },
  open: { key: 'open', color: '', intl: 'common.open' },
  pending: { key: 'pending', color: '', intl: 'common.pending' }
};

export enum REPORT_SECTIONS {
  INSIGHTS = 'insights',
  BENCH_BREAKDOWN = 'benchBreakdown'
}

export const REPORTS_SECTIONS_OPTIONS = Object.values(REPORT_SECTIONS).map(type => ({
  key: type,
  label: `dashboard.reports.sections.${type}`,
  value: type
}));

export const getReportSectionsOptions = (intlFormatter: IntlFormatter) =>
  REPORTS_SECTIONS_OPTIONS.map(option => ({
    ...option,
    label: option?.label && intlFormatter({ id: option.label })
  }));

export const CAPACITY_UTILIZATION = {
  demand: {
    key: 'demand',
    color: 'transparent',
    stroke: '#D20D38',
    intl: 'common.demand'
  },
  scheduled: {
    key: 'scheduled',
    color: 'url(#scheduledColor)',
    stroke: '#6C3DF7',
    intl: 'common.scheduled'
  },
  billingCapacity: {
    key: 'billingCapacity',
    color: 'url(#billingCapacityColor)',
    intl: 'common.billingCapacity',
    stroke: '#0090D7'
  }
};

export const TOTAL_BENCH = {
  key: 'totalBenchPercentage',
  color: 'url(#benchColor)',
  stroke: '#6C3DF7',
  intl: 'common.totalBench'
};
