import { AxiosResponse } from 'axios';

import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';
import { AccessRoleGroup, AccessRolesResource, RoleGroup, RoleRelation, TemporalRole } from 'types';

class SettingsService {
  static async getResources(): Promise<AxiosResponse<{ accessRoles: AccessRolesResource[] }>> {
    return httpClient.get(ENDPOINTS.settingsResources);
  }

  static async getGroups(): Promise<AxiosResponse<{ groups: AccessRoleGroup[] }>> {
    return httpClient.get(ENDPOINTS.settingsGroups);
  }

  static async addTemporalRole(
    payload: TemporalRole
  ): Promise<AxiosResponse<{ accessRole: AccessRolesResource }>> {
    return httpClient.post(ENDPOINTS.settingsResources, payload);
  }

  static async editResourceRole(
    id: number,
    payload: TemporalRole
  ): Promise<AxiosResponse<unknown>> {
    return httpClient.put(ENDPOINTS.settingsEditRole(id), payload);
  }

  static async deleteResourceRole(id: number) {
    return httpClient.delete(ENDPOINTS.settingsEditRole(id));
  }

  static async getRoleGroups(): Promise<AxiosResponse<{ roleGroups: RoleGroup[] }>> {
    return httpClient.get(ENDPOINTS.roleGroups);
  }

  static async addRoleRelation(
    payload: RoleRelation
  ): Promise<AxiosResponse<{ roleGroup: RoleGroup }>> {
    return httpClient.post(ENDPOINTS.roleGroups, payload);
  }

  static async editRoleRelation(
    id: number,
    payload: RoleRelation
  ): Promise<AxiosResponse<unknown>> {
    return httpClient.put(ENDPOINTS.editRoleRelation(id), payload);
  }

  static async deleteRoleRelation(id: number) {
    return httpClient.delete(ENDPOINTS.editRoleRelation(id));
  }
}

export default SettingsService;
