import { createReducer } from '@reduxjs/toolkit';

import {
  ASSIGNMENTS_STATUS_OPTIONS,
  PROJECT_STATUS_OPTIONS,
  SERVICE_TYPES_OPTIONS
} from 'constants/constants';
import {
  filterSkillsByTeam,
  getSkills,
  getDepartments,
  getLocations,
  getRoles,
  getIndustries,
  getCustomer
} from 'state/actions/uiActions';
import { UIReducer } from 'types/ui';
import { filterSkillsByDepartment } from 'utils/helpers';

const initialState: UIReducer = {
  options: {
    people: [],
    skills: [],
    skillsByTeam: [],
    departments: [],
    locations: [],
    skillsAssignments: [],
    roles: [],
    industries: [],
    clients: [],
    projectStatus: PROJECT_STATUS_OPTIONS,
    assignmentStatus: ASSIGNMENTS_STATUS_OPTIONS,
    serviceType: SERVICE_TYPES_OPTIONS
  }
};

export default createReducer(initialState, builder =>
  builder
    .addCase(getSkills.fulfilled, (state, { payload: { skills } }) => {
      state.options.skills = skills;
    })
    .addCase(filterSkillsByTeam, (state, { payload: departmentId }) => {
      const { skills } = state.options;
      state.options.skillsByTeam = filterSkillsByDepartment(skills, departmentId);
    })
    .addCase(getDepartments.fulfilled, (state, { payload: { departments } }) => {
      state.options.departments = departments;
    })
    .addCase(getLocations.fulfilled, (state, { payload: { locations } }) => {
      state.options.locations = locations;
    })
    .addCase(getRoles.fulfilled, (state, { payload: { roles } }) => {
      state.options.roles = roles.filter(role => role.availableForAssignment);
    })
    .addCase(getIndustries.fulfilled, (state, { payload: { industries } }) => {
      state.options.industries = industries;
    })
    .addCase(getCustomer.fulfilled, (state, { payload: { clients } }) => {
      state.options.clients = clients;
    })
);
