import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import {
  FilterResources,
  ProjectResources,
  ResourcesTimeline,
  FilterValue,
  PeopleFilters,
  AssignmentSearchResource,
  SearchAssignmentFilters
} from 'types';

import { getFilterQuery, getFiltersResourcesQuery } from './helpers';
import { filterList } from './reducersUtilities';
import { parseAvailabilityRange, isActive, isInRange } from './searchPeopleUtilities';

const getResourceFilter = (filter: string[], resources: ProjectResources[]) =>
  isEmpty(filter) ? resources : [];

type FilterKeys =
  | 'filterLocation'
  | 'filterDepartment'
  | 'filterSkills'
  | 'filterSeniority'
  | 'filterAvailability'
  | 'filterRole'
  | 'filterEnglishLevel';
export const filtersResources = (
  resourcesTimeline: ResourcesTimeline,
  people: ProjectResources[],
  payload: FilterResources
) => {
  const { options, key, onlyMainSkills: onlyMainSkillsParam } = payload;

  const resourceFilters = resourcesTimeline.filters;
  const { onlyMainSkills: onlyMainSkillsOption } = resourceFilters;
  const onlyMainSkills = onlyMainSkillsParam ?? onlyMainSkillsOption;

  const newResourceFilters = cloneDeep(resourceFilters);
  (newResourceFilters[key as keyof PeopleFilters] as FilterValue[]) = options;
  const filterQuery = getFilterQuery(newResourceFilters);
  const { locations, departments, skills, seniorities, availabilityRanges, roles, englishLevel } =
    filterQuery;

  const filters: Record<FilterKeys, ProjectResources[]> = {
    filterLocation: [],
    filterDepartment: [],
    filterSkills: [],
    filterSeniority: [],
    filterAvailability: [],
    filterRole: [],
    filterEnglishLevel: []
  };

  let {
    filterLocation,
    filterDepartment,
    filterSkills,
    filterSeniority,
    filterAvailability,
    filterRole,
    filterEnglishLevel
  } = filters;

  people.forEach(resource => {
    const { location: locationResource } = resource;
    if (!isEmpty(locations)) {
      locations.forEach(location => {
        const { country } = locationResource;
        country === location && filterLocation.push(resource);
      });
    }
  });

  filterLocation = !isEmpty(filterLocation) ? filterLocation : getResourceFilter(locations, people);

  filterLocation.forEach(resource => {
    const { department: departmentResource } = resource;
    if (!isEmpty(departments)) {
      departments.forEach(department => {
        departmentResource?.name === department && filterDepartment.push(resource);
      });
    }
  });

  filterDepartment = !isEmpty(filterDepartment)
    ? filterDepartment
    : getResourceFilter(departments, filterLocation);

  filterDepartment.forEach(resource => {
    const { experiences } = resource;
    if (!isEmpty(skills)) {
      experiences.map(({ skill, highlighted }) => {
        skills.forEach(skillFilter => {
          if (skillFilter === skill?.name) {
            if (!onlyMainSkills || highlighted) {
              filterSkills.push(resource);
            }
          }
        });
      });
    }
  });

  filterSkills = !isEmpty(filterSkills)
    ? filterSkills
    : getResourceFilter(skills, filterDepartment);

  filterSkills.forEach(resource => {
    const { seniority: seniorityResource } = resource;
    if (!isEmpty(seniorities)) {
      seniorities.forEach(seniorityOption => {
        seniorityResource === seniorityOption && filterSeniority.push(resource);
      });
    }
  });

  filterSeniority = !isEmpty(filterSeniority)
    ? filterSeniority
    : getResourceFilter(seniorities, filterSkills);

  filterSeniority.forEach(resource => {
    const { availability: availabilityResource } = resource;
    if (!isEmpty(availabilityRanges)) {
      availabilityRanges.forEach(availability => {
        const range = availability.split('-');
        const { busyPercentage } = availabilityResource[0]?.value;
        let availabilityPercentage = 100 - busyPercentage;
        availabilityPercentage = availabilityPercentage > 0 ? availabilityPercentage : 0;

        availabilityPercentage >= parseFloat(range[0]) &&
          availabilityPercentage <= parseFloat(range[1]) &&
          filterAvailability.push(resource);
      });
    }
  });

  filterAvailability = !isEmpty(filterAvailability)
    ? filterAvailability
    : getResourceFilter(availabilityRanges, filterSeniority);

  filterAvailability.forEach(resource => {
    const { role: roleResource } = resource;
    if (!isEmpty(roles)) {
      roles.forEach(roleOption => {
        roleResource?.name === roleOption && filterRole.push(resource);
      });
    }
  });

  filterRole = !isEmpty(filterRole) ? filterRole : getResourceFilter(roles, filterAvailability);

  filterAvailability.forEach(resource => {
    const { englishLevel: englishLevelResource } = resource;
    if (!isEmpty(englishLevel)) {
      englishLevel.forEach(englishLevelOption => {
        englishLevelResource === englishLevelOption && filterEnglishLevel.push(resource);
      });
    }
  });

  filterEnglishLevel = !isEmpty(filterEnglishLevel)
    ? filterEnglishLevel
    : getResourceFilter(englishLevel, filterRole);

  const filterName = filterList(filterEnglishLevel, newResourceFilters.name, item => item.fullName);

  const resourcesResult = compact(uniq(flatten(filterName)));
  const resourcesFiltersOptionsQuery = getFiltersResourcesQuery({ resources: resourcesResult });

  return { resourcesResult, newResourceFilters, resourcesFiltersOptionsQuery };
};

export const getActiveData = (filterData: { value: boolean | number; key: string }[]) =>
  filterData.filter(item => item.value).map(item => item.key);

export const applyFilters = (
  resources: AssignmentSearchResource[],
  filters: SearchAssignmentFilters
) => {
  const { roles, seniority, skills, englishLevel, availability, name } = filters;

  const activeRoles = getActiveData(roles);
  const activeSeniorities = getActiveData(seniority);
  const activeSkills = getActiveData(skills);
  const activeEnglishLevels = getActiveData(englishLevel);
  const activeAvailabilityRanges = availability

    .filter(item => item.value)

    .map(item => parseAvailabilityRange(item.key));

  return resources.filter(resource => {
    const matchesSkills =
      activeSkills.length === 0 ||
      resource.mainSkills.some(skill => activeSkills.includes(skill.skillName));
    const matchesAvailability =
      activeAvailabilityRanges.length === 0 ||
      activeAvailabilityRanges.some(range => isInRange(resource.availablePercentage, range));

    return (
      isActive(activeRoles, resource.mainInfo.role.name) &&
      isActive(activeSeniorities, resource.seniority) &&
      matchesSkills &&
      matchesAvailability &&
      isActive(activeEnglishLevels, resource.englishLevel) &&
      (!name || resource.mainInfo?.fullName?.toLowerCase().includes(name.toLowerCase()))
    );
  });
};

type FiltersWithValue = Pick<
  SearchAssignmentFilters,
  'availability' | 'englishLevel' | 'roles' | 'seniority' | 'skills'
>;
export const getActiveFilters = (selectedFilters: FiltersWithValue) => {
  const activeFilters: FilterValue[] = [];
  Object.keys(selectedFilters).forEach(key => {
    const castedKey = key as keyof FiltersWithValue;
    if (Array.isArray(selectedFilters[castedKey])) {
      selectedFilters[castedKey].forEach(filter => {
        if (filter.value) {
          activeFilters.push(filter);
        }
      });
    }
  });
  return activeFilters;
};

export const resetFilters = (data?: FilterValue[] | null) =>
  data ? data.map(option => ({ ...option, value: false })) : [];

export const filterItemsByProperties = (
  items: Array<{
    assignments: Record<string, unknown>;
    status: string;
  }>,
  filters: {
    search: string;
    hideFinished: boolean;
    projectStatus: Array<{ value: boolean; key: string }>;
    assignmentStatus: Array<{ value: boolean; key: string }>;
    serviceType: Array<{ value: boolean; key: string }>;
  }
) => {
  if (!items?.length) return [];

  const filterValues = {
    status: filters.projectStatus.filter(item => item.value).map(item => item.key),
    assignmentsValues: filters.assignmentStatus.filter(item => item.value).map(item => item.key),
    projectType: filters.serviceType.filter(item => item.value).map(item => item.key)
  };

  const newItems = items.map(item => ({
    ...item,
    assignmentsValues: Object.keys(item.assignments).filter(
      property => (item.assignments[property] as number) > 0
    )
  }));

  let filterItems = items;

  if (
    filterValues.status.length ||
    filterValues.assignmentsValues.length ||
    filterValues.projectType.length
  ) {
    filterItems = newItems.filter(item =>
      Object.keys(filterValues).every(property => {
        const filterOptions = filterValues[property as keyof typeof filterValues];

        if (filterOptions.length) {
          const castedProperty = property as keyof typeof item;
          if (Array.isArray(item[castedProperty])) {
            const castedItem = item[castedProperty] as string[];
            return filterOptions.some(option => castedItem.includes(option));
          }
          return filterOptions.includes(item[castedProperty] as string);
        }
        return true;
      })
    );
  }

  return filters.hideFinished
    ? filterItems.filter(item => item.status !== 'finished')
    : filterItems;
};

type ComparedResource = Record<string, unknown> & {
  matchOverall?: number;
  margin?: number;
  mainInfo: { fullName: string };
};
export const compareResources = (a: ComparedResource, b: ComparedResource) =>
  (b.matchOverall ?? 0) - (a.matchOverall ?? 0) ||
  (b.margin ?? 0) - (a.margin ?? 0) ||
  a.mainInfo.fullName.localeCompare(b.mainInfo.fullName);

export const sortOptions = (options: FilterValue[], key: keyof FilterValue = 'label') =>
  options?.length
    ? [...options].sort((a, b) => (a[key] as string).localeCompare(b[key] as string))
    : options;
