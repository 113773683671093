/* eslint-disable no-bitwise */
import clamp from 'lodash/clamp';

const clampColor = (color: number) => clamp(color, 0, 255);

/**
 * @param  {string} hexColor Color in hexadecimal
 * @param  {number} alpha 0-255 alpha number
 * @returns {string} Hex string with alpha channel
 */
export const hexColorAlpha = (hexColor: string, alpha: number) =>
  `${hexColor}${alpha.toString(16)}`;

/**
 * @param {string} hexColor Color in hexadecimal format
 * @param {number} percent Percentage to lighten
 * @returns {string} Hex string with lightened color
 */
export const lightenColor = (hexColor: string, percent = 0) => {
  const usePound = hexColor[0] === '#';

  let color = hexColor.replace('#', '');
  let alpha = '';

  if (color.length > 6) {
    alpha = color.substr(6, 2);
    color = color.substr(0, 6);
  }

  const colorNumber = parseInt(color, 16);
  const amount = Math.round(2.55 * percent);

  const R = clampColor(((colorNumber >> 16) & 0xff) + amount);
  const G = clampColor(((colorNumber >> 8) & 0x00ff) + amount);
  const B = clampColor((colorNumber & 0x0000ff) + amount);

  return `${usePound && '#'}${(
    0x1000000 +
    clampColor(R) * 0x10000 +
    clampColor(G) * 0x100 +
    clampColor(B) * 0x1
  )
    .toString(16)
    .slice(1)}${alpha}`;
};

/**
 * @param {string} hexColor Color in hexadecimal
 * @param {number} percent Percentage to darken
 * @returns {string} Hex string with darkened color
 */
export const darkenColor = (hexColor: string, percent = 0) => lightenColor(hexColor, -percent);
