import { createReducer } from '@reduxjs/toolkit';

import { editQuickAssignment } from 'state/actions/assignmentActions';
import {
  getProjectAssignments,
  getProjectsAvailability,
  resetProjectId
} from 'state/actions/projectActions';
import { OpenAssigments, PeopleReducer } from 'types/people';
import { sortObjectByDateAsc } from 'utils/date';

const initialState: PeopleReducer = {
  projects: [],
  assignments: {},
  projectId: null
};

const projectReducer = createReducer(initialState, builder =>
  builder
    .addCase(getProjectsAvailability.fulfilled, (state, { payload: { projects } }) => {
      state.projects = projects.map(project => {
        if ('allStatuses' in project) {
          const sortedStatuses = sortObjectByDateAsc(
            project.allStatuses
              .filter(status => status.startDate !== undefined)
              .map(status => ({ startDate: status.startDate })),
            'startDate'
          );
          return { ...project, allStatuses: sortedStatuses };
        }
        return project;
      });
    })
    .addCase(
      getProjectAssignments.fulfilled,
      (
        state,
        {
          payload: {
            data: { resources, openAssignments },
            projectId
          }
        }
      ) => {
        const newAssignments = {
          ...state.assignments,
          [projectId]: {
            resources: resources?.sort((a: { fullName: string }, b: { fullName: string }) =>
              a.fullName > b.fullName ? 1 : -1
            ),
            openAssignments: (openAssignments as OpenAssigments[])?.sort(
              (a: { role: string }, b: { role: string }) => (a.role > b.role ? 1 : -1)
            )
          }
        };
        state.assignments = newAssignments;
      }
    )
    .addCase(editQuickAssignment.fulfilled, (state, { payload: { projectId } }) => {
      state.projectId = projectId;
    })
    .addCase(resetProjectId, state => {
      state.projectId = initialState?.projectId;
    })
);

export default projectReducer;
