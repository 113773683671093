import { AxiosResponse } from 'axios';

import { SORTING_PROJECT_OPTIONS } from 'constants/constants';
import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';
import {
  MinimalProject,
  Project,
  ProjectAssignmentsParams,
  OverviewProject,
  ProjectOverviewAssignments,
  ProjectOverviewTotals,
  ProjectParams,
  ProjectStatus,
  ProjectStatusParams,
  ProjectTimelineResources,
  TimelineProject,
  ProjectFinishesAssignments
} from 'types';
import { getRequestFormatDate } from 'utils/date';

class ProjectService {
  static async getProjectsAvailability(
    startDate: string,
    endDate: string,
    filterQuery?: Record<string, string>,
    minimal: boolean = false
  ): Promise<AxiosResponse<{ projects: TimelineProject[] | MinimalProject[] }>> {
    const start = getRequestFormatDate(startDate);
    const end = endDate && getRequestFormatDate(endDate);

    const endpoint = minimal ? ENDPOINTS.minimalProjects : ENDPOINTS.projects;

    // TODO: Remove sort_by when implement sorting options
    return httpClient.get(endpoint, {
      params: {
        start_date: start,
        ...(end && { end_date: end }),
        [SORTING_PROJECT_OPTIONS.status]: 'asc',
        [SORTING_PROJECT_OPTIONS.name]: 'asc',
        ...filterQuery
      }
    });
  }

  static async archiveProject(projectId: number): Promise<AxiosResponse<Project>> {
    return httpClient.put(ENDPOINTS.getProject(projectId), {
      archived: true
    });
  }

  static async newProjectStatus(
    projectStatus: ProjectStatus
  ): Promise<AxiosResponse<ProjectStatus>> {
    return httpClient.post(ENDPOINTS.projectStatuses, { projectStatus });
  }

  static async editProjectStatus(
    statusId: number,
    projectStatus: ProjectStatusParams
  ): Promise<AxiosResponse<ProjectStatus>> {
    return httpClient.put(ENDPOINTS.getProjectStatus(statusId), { projectStatus });
  }

  static async deleteProjectStatus(statusId: number) {
    return httpClient.delete(ENDPOINTS.getProjectStatus(statusId));
  }

  static async createProject(project: ProjectParams): Promise<AxiosResponse<Project>> {
    return httpClient.post(ENDPOINTS.projects, { project });
  }

  static async editProject(project: ProjectParams): Promise<AxiosResponse<Project>> {
    return httpClient.put(ENDPOINTS.getProject(project.id), project);
  }

  static async deleteProject(projectId: number) {
    return httpClient.delete(ENDPOINTS.getProject(projectId));
  }

  static async getProjectById(projectId: number): Promise<AxiosResponse<Project>> {
    return httpClient.get(ENDPOINTS.getProject(projectId));
  }

  static async getProjectsOverviewTotals(): Promise<AxiosResponse<ProjectOverviewTotals>> {
    return httpClient.get(ENDPOINTS.getProjectsOverviewTotals);
  }

  static async getProjectsOverview(params?: {
    startDate: string;
    endDate: string;
  }): Promise<AxiosResponse<{ projects: OverviewProject[] }>> {
    return httpClient.get(ENDPOINTS.getProjectsOverview, { params });
  }

  static async getProjectOverviewDetail(
    projectId: number
  ): Promise<AxiosResponse<{ assignments: ProjectOverviewAssignments[] }>> {
    return httpClient.get(ENDPOINTS.getProjectOverviewDetail(projectId));
  }

  static async getFinishedAssignments(
    projectId: number
  ): Promise<AxiosResponse<ProjectFinishesAssignments>> {
    return httpClient.get(ENDPOINTS.getFinishedAssignments(projectId));
  }

  static async getProjectAssignments({
    projectId,
    startDate,
    endDate
  }: ProjectAssignmentsParams): Promise<
    AxiosResponse<{ resources: ProjectTimelineResources[]; openAssignments: unknown[] }>
  > {
    return httpClient.get(ENDPOINTS.getProjectAssignments(projectId), {
      params: {
        startDate,
        endDate
      }
    });
  }
}

export default ProjectService;
