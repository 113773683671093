import { AxiosResponse } from 'axios';

import { QUERY_OPTIONS } from 'constants/constants';
import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';
import {
  AddResourceSoftSkillsParams,
  AddResourceTechnicalSkillsParams,
  ExperienceParams,
  GetResourceSoftSkillsParams,
  Resource,
  ResourceExperiencesParams,
  ResourceParams,
  SearchPeopleResource,
  SoftSkill,
  SpecialEvent,
  SpecialEventParams,
  TechnicalSkill,
  ProjectResources,
  UpdateResourceSoftSkillsParams,
  UpdateResourceTechnicalSkillsParams
} from 'types';
import { getRequestFormatDate } from 'utils/date';

class PeopleService {
  static async getPeopleAvailability(
    startDate: string,
    endDate: string,
    filterQuery?: Partial<Record<QUERY_OPTIONS, string[]>> | ''
  ): Promise<AxiosResponse<{ resources: ProjectResources[] }>> {
    const start = getRequestFormatDate(startDate);
    const end = getRequestFormatDate(endDate);

    return httpClient.get(ENDPOINTS.availabilities, {
      params: {
        start_date: start,
        end_date: end,
        ...filterQuery
      }
    });
  }

  static async createResource(person: ResourceParams): Promise<AxiosResponse<Resource>> {
    return httpClient.post(ENDPOINTS.people, { person });
  }

  static async resourceExperiences({
    personId,
    highlighted
  }: ResourceExperiencesParams): Promise<AxiosResponse<{ experiences: TechnicalSkill[] }>> {
    return httpClient.get(ENDPOINTS.getPeopleExperience(personId, highlighted));
  }

  static saveExperience(userId: number, data: ExperienceParams) {
    return httpClient.post(ENDPOINTS.getPeopleExperience(userId), data);
  }

  static async getMinimalResources(
    userGroup: string
  ): Promise<AxiosResponse<{ resources: Resource[] }>> {
    return httpClient.get(ENDPOINTS.people, {
      params: {
        group: userGroup
      }
    });
  }

  static async getResource(resourceId: number) {
    return httpClient.get(ENDPOINTS.getPerson(resourceId));
  }

  static async getResourceSoftSkills({
    personId
  }: GetResourceSoftSkillsParams): Promise<AxiosResponse<{ softSkills: SoftSkill[] }>> {
    return httpClient.get(ENDPOINTS.getPeopleSoftSkills(personId));
  }

  static async updateResourceSoftSkills({
    personId,
    payload
  }: UpdateResourceSoftSkillsParams): Promise<AxiosResponse<{ softSkills: SoftSkill[] }>> {
    return httpClient.put(ENDPOINTS.getPeopleSoftSkills(personId), payload);
  }

  static async addResourceSoftSkills({
    personId,
    payload
  }: AddResourceSoftSkillsParams): Promise<AxiosResponse<{ softSkills: SoftSkill[] }>> {
    return httpClient.post(ENDPOINTS.getPeopleSoftSkills(personId), payload);
  }

  static async updateResourceTechnicalSkills({
    personId,
    payload
  }: UpdateResourceTechnicalSkillsParams): Promise<
    AxiosResponse<{ experiences: TechnicalSkill[] }>
  > {
    return httpClient.put(ENDPOINTS.gerPersonTechnicalSkill(personId), payload);
  }

  static async addResourceTechnicalSkills({
    personId,
    payload
  }: AddResourceTechnicalSkillsParams): Promise<AxiosResponse<{ experiences: TechnicalSkill[] }>> {
    return httpClient.post(ENDPOINTS.gerPersonTechnicalSkill(personId), payload);
  }

  static async addSpecialEvents(
    id: number,
    payload: SpecialEventParams
  ): Promise<AxiosResponse<SpecialEvent>> {
    return httpClient.post(ENDPOINTS.getPersonSpecialEvents(id), payload);
  }

  static async updateSpecialEvents(
    personId: number,
    specialEventId: number,
    payload: SpecialEventParams
  ): Promise<AxiosResponse<SpecialEvent>> {
    return httpClient.put(ENDPOINTS.updatePersonSpecialEvents(personId, specialEventId), payload);
  }

  static async getPersonSpecialEvents(
    id: number
  ): Promise<AxiosResponse<{ specialEvents: SpecialEvent[] }>> {
    return httpClient.get(ENDPOINTS.getPersonSpecialEvents(id));
  }

  static async deleteSpecialEvents(personId: number, specialEventId: number) {
    return httpClient.delete(ENDPOINTS.updatePersonSpecialEvents(personId, specialEventId));
  }

  static async getAssigmentPeople(
    assignmentId: number
  ): Promise<AxiosResponse<{ resources: SearchPeopleResource[] }>> {
    return httpClient.get(ENDPOINTS.getPeopleByAssignment(assignmentId));
  }
}

export default PeopleService;
