import { memo, useState, useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl';

import Button from 'components/common/Button';
import ExperienceRow from 'components/common/ExperienceRow';
import Input from 'components/common/Input';
import { Row } from 'components/common/Layout/Row';
import Select from 'components/common/Select';
import { ENGINEERING_TEAM_ID, BUTTON_VARIANTS } from 'constants/constants';
import { loadExperienceShape } from 'constants/shapes';
import { useForm, useTextInputProps, useSelectorProps, useIndustries } from 'hooks';
import { prepareSkillsRequest, validateSkillsRequest } from 'utils/helpers';

import { StyledButton, SelectorContainer } from './styles';

const messages = defineMessages({
  kindOfProject: { id: 'resource.experience.kindOfProject' },
  kindOfIndustry: { id: 'resource.experience.kindOfIndustry' }
});

const fields = {
  kindOfProject: 'kindOfProject',
  kindOfIndustry: 'kindOfIndustry',
  skillsFields: 'skillsFields'
};

export const LoadExperience = ({ token, userId, departmentId, onSubmit, isLoading }) => {
  const intl = useIntl();
  const extendedForm = departmentId == ENGINEERING_TEAM_ID;
  const { industriesOptions } = useIndustries();
  const [skillsFields, setSkillsFields] = useState([]);
  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = validateSkillsRequest(skillsFields);
    setErrors(errors);
    return errors;
  };

  const onSubmitOverride = values =>
    validate() &&
    onSubmit(
      prepareSkillsRequest(values, skillsFields, intl.formatMessage(messages.kindOfProject))
    );

  const { values, setValues, handleValueChange, handleSubmit, handleBlur } = useForm(
    {
      initialValues: { token, userId, skillsFields },
      onSubmit: onSubmitOverride,
      validateOnBlur: false
    },
    [onSubmitOverride]
  );

  const addNewSkill = () => {
    if (validate()) {
      const values = [...skillsFields];
      values.push({ skill: '', skillId: '', years: '', months: '', error: '' });
      setSkillsFields(values);
    }
  };

  const handleRemoveSkill = (index, event) => {
    if (index !== 0) {
      const values = [...skillsFields];
      values.splice(index, 1);
      setSkillsFields(values);
    }
    event.preventDefault();
  };

  const handleSkillSelectChange = (field, index, event) => {
    const values = [...skillsFields];
    values[index][field] = event;
    setSkillsFields(values);
  };

  useEffect(() => {
    addNewSkill();
  }, []);

  const inputProps = useTextInputProps({ handleValueChange, handleBlur, values, errors });
  const selectorProps = useSelectorProps({ inputProps, values, setValues });

  return (
    <form onSubmit={handleSubmit}>
      {extendedForm && (
        <Input
          name="kindOfProject"
          type="text"
          label={intl.formatMessage(messages.kindOfProject)}
          required
          {...inputProps(fields.kindOfProject)}
        />
      )}
      <SelectorContainer>
        <Select
          name="kindOfIndustry"
          label={intl.formatMessage(messages.kindOfIndustry)}
          options={industriesOptions}
          {...selectorProps(fields.kindOfIndustry)}
          isMulti
          isClearable
          isSearchable
          withoutHighlight
        />
      </SelectorContainer>
      <ExperienceRow
        skillsFields={skillsFields}
        departmentId={departmentId}
        handleSkillSelectChange={handleSkillSelectChange}
        handleRemoveSkill={handleRemoveSkill}
      />
      <Row>
        <StyledButton onClick={addNewSkill}>
          {intl.formatMessage({ id: 'resource.addSkills' })}
        </StyledButton>
      </Row>
      <Button
        type="submit"
        variant={BUTTON_VARIANTS.SUBMIT}
        isLoading={isLoading}
        textIntlId="resources.saveExperience"
        fullWidth
      />
    </form>
  );
};

LoadExperience.propTypes = loadExperienceShape;

export default memo(LoadExperience);
