import { createAction } from '@reduxjs/toolkit';

import timelineService from 'services/timelineService';
import { Client, Department, ErrorAsyncThunk, Industry, Location, Role, Skill } from 'types';
import { CustomersResponse, IndustriesResponse, RolesResponse } from 'types/ui';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';

export const filterSkillsByTeam = createAction('FILTER_SKILLS_BY_TEAM');

export const getSkills = createAsyncThunk<{ skills: Skill[] }, void>('GET_SKILLS', async () => {
  try {
    const { data } = await timelineService.getSkills();
    return data;
  } catch (error) {
    const {
      response: { data }
    } = error as ErrorAsyncThunk;
    throw parseError(data);
  }
});

export const getDepartments = createAsyncThunk<
  {
    departments: Department[];
  },
  void
>('GET_DEPARTMENTS', async () => {
  try {
    const { data } = await timelineService.getDepartments();
    return data;
  } catch (error) {
    const {
      response: { data }
    } = error as ErrorAsyncThunk;
    throw parseError(data);
  }
});

export const getLocations = createAsyncThunk<
  {
    locations: Location[];
  },
  void
>('GET_LOCATIONS', async () => {
  try {
    const { data } = await timelineService.getLocations();
    return data;
  } catch (error) {
    const {
      response: { data }
    } = error as ErrorAsyncThunk;
    throw parseError(data);
  }
});

export const getRoles = createAsyncThunk<RolesResponse<Role[]>, void>('GET_ROLES', async () => {
  try {
    const { data } = await timelineService.getRoles();
    return data;
  } catch (error) {
    const {
      response: { data }
    } = error as ErrorAsyncThunk;
    throw parseError(data);
  }
});

export const getIndustries = createAsyncThunk<IndustriesResponse<Industry[]>, void>(
  'GET_INDUSTRIES',
  async () => {
    try {
      const { data } = await timelineService.getIndustries();
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getCustomer = createAsyncThunk<CustomersResponse<Client[]>, void>(
  'GET_CUSTOMER',
  async () => {
    try {
      const { data } = await timelineService.getCustomer();
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);
