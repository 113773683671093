import { AxiosResponse } from 'axios';

import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';
import {
  ResourceAssignment,
  Disciple,
  ResourceProfile,
  ResourceProfileParams,
  TimelineData
} from 'types';

class ResourceProfilesService {
  static async getDisciples(): Promise<AxiosResponse<{ disciples: Disciple[] }>> {
    return httpClient.get(ENDPOINTS.getDisciples);
  }

  static async getResourceProfile(id: number): Promise<AxiosResponse<ResourceProfile>> {
    return httpClient.get(ENDPOINTS.getResourceProfile(id));
  }

  static async getTimeOffs(id: number): Promise<AxiosResponse<{ timeOffs: unknown[] }>> {
    return httpClient.get(ENDPOINTS.getResourceProfileTimeOffs(id));
  }

  static async getProjects(
    id: number
  ): Promise<AxiosResponse<{ assignments: ResourceAssignment[] }>> {
    return httpClient.get(ENDPOINTS.getResourceProfileProjects(id));
  }

  static async editResourceProfile(id: number, payload: ResourceProfileParams) {
    return httpClient.put(ENDPOINTS.getResourceProfile(id), payload);
  }

  static async getMiniTimeline(
    id: number,
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<{ timelineData: TimelineData[] }>> {
    return httpClient.get(ENDPOINTS.getResourceProfileTimeline(id, startDate, endDate));
  }

  static async getResourceBio(id: number): Promise<AxiosResponse<{ process: unknown }>> {
    return httpClient.post(ENDPOINTS.getResourceProfileBio(id));
  }
}

export default ResourceProfilesService;
