const sortBy = (condition: boolean) => (condition ? -1 : 1);

type RowItem = {
  capacity: number;
  scheduled: number;
  billable: number;
  benchHours: number;
  harvestHours: number;
  pending: number;
};

type Row = { original: RowItem };

const totalUtilizationSelector = (row: RowItem) => row.capacity / row.scheduled;
const assignmentsSelector = (row: RowItem) => row.capacity / row.billable;
const benchUtilizationSelector = (row: RowItem) => row.capacity / row.benchHours;
const benchPendingSelector = (row: RowItem) => (row.benchHours + row.pending) / row.capacity;

const realHoursSelector = (row: RowItem) => row.scheduled / row.harvestHours;

export const TOTAL_UTILIZATION_SORT = ({ original: rowA }: Row, { original: rowB }: Row) =>
  sortBy(totalUtilizationSelector(rowA) < totalUtilizationSelector(rowB));

export const ASSIGNMENTS_SORT = ({ original: rowA }: Row, { original: rowB }: Row) =>
  sortBy(assignmentsSelector(rowA) < assignmentsSelector(rowB));

export const BENCH_UTILIZATION_SORT = ({ original: rowA }: Row, { original: rowB }: Row) =>
  sortBy(benchUtilizationSelector(rowA) < benchUtilizationSelector(rowB));

export const REAL_HOURS_SORT = ({ original: rowA }: Row, { original: rowB }: Row) =>
  sortBy(realHoursSelector(rowA) < realHoursSelector(rowB));

export const BENCH_PENDING_SORT = ({ original: rowA }: Row, { original: rowB }: Row) =>
  sortBy(benchPendingSelector(rowA) < benchPendingSelector(rowB));
