import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

import reducer from 'state/reducers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (initialState: any, isServerSide = false) => {
  const store = configureStore({
    reducer: reducer(),
    preloadedState: initialState
  });

  if (isServerSide) {
    return { store };
  }

  const persistor = persistStore(store);

  return { store, persistor };
};
