import dayjs from 'dayjs';

import { QUARTER_TYPES } from 'constants/constants';
import { areasInformation } from 'constants/utilizationConstants';

const getAreaInfoByUtilizationId = (id: string) =>
  Object.values(areasInformation).find(({ utilizationId }) => id === utilizationId);

type Utilizations = {
  [key: string]: {
    [key: string]: unknown;
    info?: Record<string, string>;
    utilizationPercentage: number;
  };
};
export const mergeAreasUtilizationInfo = (utilizations: Utilizations) => {
  const utilizationsList = Object.entries(utilizations);

  const mergedInfo = {} as Utilizations;
  utilizationsList.map(([utilizationId, value]) => {
    mergedInfo[utilizationId] = {
      ...value,
      info: getAreaInfoByUtilizationId(utilizationId)
    };
  });

  return mergedInfo;
};

export const getMaxUtilizationPercentage = (utilizations: Utilizations) => {
  const utilizationsList = Object.values(mergeAreasUtilizationInfo(utilizations));
  const maxUtilizationPercentage = Math.max(
    ...utilizationsList.map(({ utilizationPercentage }) => utilizationPercentage)
  );
  return Math.max(maxUtilizationPercentage, 100);
};

export const getQuarter = (quarter: number) => {
  const currentQuarter = dayjs().quarter();
  let quarterType = QUARTER_TYPES.PAST;
  if (quarter === currentQuarter) quarterType = QUARTER_TYPES.CURRENT;
  if (quarter > currentQuarter) quarterType = QUARTER_TYPES.FUTURE;

  return quarterType;
};

export const getDepartmentsGraphData = ({
  departmentData,
  totalFteInMonth
}: {
  departmentData?: { benchInFte: number }[];
  totalFteInMonth: number;
}) =>
  departmentData?.map(item => ({
    ...item,
    value: item.benchInFte / totalFteInMonth
  }));
