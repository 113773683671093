import { createAction, PrepareAction } from '@reduxjs/toolkit';

import peopleService from 'services/peopleService';
import { RootState } from 'state/reducers';
import {
  AddResourceSoftSkillsParams,
  AddResourceTechnicalSkillsParams,
  AsyncThunkConfig,
  ErrorAsyncThunk,
  MinimalResourcesResponse,
  PeopleRequestType,
  ResourceResponse,
  SearchPeopleResource,
  SpecialEventType,
  Timeline,
  ProjectResources,
  UpdateResourceSoftSkillsParams,
  UpdateResourceTechnicalSkillsParams,
  FilterResources
} from 'types';
import createAsyncThunk from 'utils/createAsyncThunk';
import { getFilterQuery } from 'utils/helpers';
import parseError from 'utils/parseError';

import { getProjectsAvailability } from './projectActions';

export const filterResources = createAction('FILTER_RESOURCES', (resources: FilterResources) => ({
  payload: resources
}));

export const filterResourcesByName = createAction<PrepareAction<string>>(
  'FILTER_RESOURCE_BY_NAME',
  (resourceName: string) => ({
    payload: resourceName
  })
);
export const resetResourceFilters = createAction('RESET_RESOURCE_FILTERS');
export const toggleResourceExpanded = createAction(
  'TOGGLE_RESOURCE_EXPANDED',
  (personId: number) => ({
    payload: personId
  })
);
export const resetExpandedResources = createAction('RESET_EXPANDED_RESOURCES');
export const toggleUnassignedSkillsShown = createAction('TOGGLE_UNASSIGNED_SKILLS_SHOWN');
export const toggleUnassignedSkillExpanded = createAction(
  'TOGGLE_UNASSIGNED_SKILL_EXPANDED',
  unassignedSkills => ({
    payload: unassignedSkills
  })
);

export const getPeopleAvailability = createAsyncThunk<
  ResourceResponse<ProjectResources[]>,
  Timeline
>(
  'GET_PEOPLE_AVAILABILITY',
  async (
    { startDate, endDate, withFilters }: PeopleRequestType,
    { getState }: AsyncThunkConfig
  ) => {
    try {
      const {
        people: {
          resourcesTimeline: { filters }
        }
      } = getState() as RootState;

      const filterQuery = withFilters ? getFilterQuery(filters) : '';
      const { data } = await peopleService.getPeopleAvailability(startDate, endDate, filterQuery);

      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getPeopleByAssignment = createAsyncThunk<SearchPeopleResource[], number>(
  'GET_PEOPLE_ASSIGNMENT',
  async (assignmentId: number) => {
    try {
      const { data } = await peopleService.getAssigmentPeople(assignmentId);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const createResource = createAsyncThunk(
  'CREATE_RESOURCE',
  async (
    { payload: { payload: person }, startDate, endDate }: PeopleRequestType,
    { dispatch }: AsyncThunkConfig
  ) => {
    try {
      const { data } = await peopleService.createResource(person);
      dispatch(getProjectsAvailability({ startDate, endDate }));
      dispatch(getPeopleAvailability({ startDate, endDate }));
      dispatch(filterResourcesByName(`${person.firstName} ${person.lastName}`));
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getMinimalResources = createAsyncThunk<MinimalResourcesResponse, PeopleRequestType>(
  'GET_MINIMAL_RESOURCES',
  async ({ userGroup }: PeopleRequestType) => {
    try {
      const {
        data: { resources }
      } = await peopleService.getMinimalResources(userGroup);
      return { resources, userGroup };
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getResource = createAsyncThunk(
  'GET_RESOURCE',
  async ({ resourceId }: PeopleRequestType) => {
    try {
      const { data } = await peopleService.getResource(resourceId);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getResourceSoftSkills = createAsyncThunk(
  'GET_RESOURCE_SOFT_SKILLS ',
  async ({ personId }: PeopleRequestType) => {
    try {
      const { data } = await peopleService.getResourceSoftSkills({ personId });
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const updateResourceSoftSkills = createAsyncThunk(
  'UPDATE_RESOURCE_SOFT_SKILLS ',
  async ({ personId, payload }: UpdateResourceSoftSkillsParams) => {
    try {
      const { data } = await peopleService.updateResourceSoftSkills({ personId, payload });
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const addResourceSoftSkills = createAsyncThunk(
  'ADD_RESOURCE_SOFT_SKILLS ',
  async ({ personId, payload }: AddResourceSoftSkillsParams) => {
    try {
      const { data } = await peopleService.addResourceSoftSkills({ personId, payload });
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getResourceTechnicalSkills = createAsyncThunk(
  'GET_RESOURCE_TECHNICAL_SKILLS ',
  async ({ personId, highlighted = false }: PeopleRequestType) => {
    try {
      const { data } = await peopleService.resourceExperiences({ personId, highlighted });
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const updateResourceTechnicalSkills = createAsyncThunk(
  'UPDATE_RESOURCE_TECHNICAL_SKILLS ',
  async ({ personId, payload }: UpdateResourceTechnicalSkillsParams) => {
    try {
      const { data } = await peopleService.updateResourceTechnicalSkills({ personId, payload });
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const addResourceTechnicalSkills = createAsyncThunk(
  'ADD_RESOURCE_TECHNICAL_SKILLS ',
  async ({ personId, payload }: AddResourceTechnicalSkillsParams) => {
    try {
      const { data } = await peopleService.addResourceTechnicalSkills({ personId, payload });
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const addSpecialEvent = createAsyncThunk(
  'ADD_SPECIAL_EVENT',
  async ({ personId, payload }: SpecialEventType) => {
    try {
      const { data } = await peopleService.addSpecialEvents(personId, payload);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const updateSpecialEvent = createAsyncThunk(
  'UPDATE_SPECIAL_EVENT',
  async ({ personId, specialEventId, payload }: SpecialEventType) => {
    try {
      const { data } = await peopleService.updateSpecialEvents(personId, specialEventId, payload);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getPersonSpecialEvents = createAsyncThunk(
  'GET_SPECIAL_EVENTS',
  async ({ personId }: PeopleRequestType) => {
    try {
      const { data } = await peopleService.getPersonSpecialEvents(personId);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const deleteSpecialEvent = createAsyncThunk(
  'DELETE_SPECIAL_EVENT',
  async ({ personId, specialEventId }: PeopleRequestType) => {
    try {
      const { data } = await peopleService.deleteSpecialEvents(personId, specialEventId);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);
