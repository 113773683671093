import { AxiosResponse } from 'axios';

import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';
import { ForecastingGraph, ForecastingProject, ForecastingProjectTotals } from 'types';

class ForecastingServices {
  static async getProjectsTotals(): Promise<AxiosResponse<ForecastingProjectTotals>> {
    return httpClient.get(ENDPOINTS.getProjectsTotals);
  }

  static async getProjects(
    forecastedMonthsAhead: number
  ): Promise<AxiosResponse<{ projects: ForecastingProject[] }>> {
    return httpClient.get(ENDPOINTS.getProjectsForecasting, {
      params: {
        forecastedMonthsAhead
      }
    });
  }

  static async getGraph(): Promise<AxiosResponse<ForecastingGraph>> {
    return httpClient.get(ENDPOINTS.getForecastingGraph);
  }
}

export default ForecastingServices;
