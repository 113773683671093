import { bool, func, number } from 'prop-types';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { NumericFormat } from 'react-number-format';

import NewInput from 'components/common/NewInput';
import { BUTTON_VARIANTS } from 'constants/constants';
import { projectFinancialFormShape } from 'constants/shapes';
import { projectFinancialInfoValidator } from 'constants/validators';
import { useDispatch, useForm, useStatus, useTextInputProps, useActionOnSuccess } from 'hooks';
import { editFinancialProjectDetails } from 'state/actions/financialActions';
import { composeEditFinancial } from 'utils/financialUtilities';

import { ButtonsRow, Form, StyledButton } from './styles';

export const fields = {
  clientBudget: 'clientBudget',
  costsAdjustment: 'costsAdjustment',
  billedAdjustment: 'billedAdjustment'
};

const numericFormatProps = { thousandSeparator: ',', decimalSeparator: '.', decimalScale: 2 };

const ProjectFinancialInfoForm = ({
  hideModal,
  initialValues,
  id,
  financialProjectDetailsRequest,
  disabled
}) => {
  const intl = useIntl();
  const editFinancialProjectDetailsRequest = useDispatch(editFinancialProjectDetails);
  const resetEditFinancialProjectDetails = useDispatch(editFinancialProjectDetails.reset);
  const { status, isPending: isLoadingEdit } = useStatus(editFinancialProjectDetails);

  const { values, errors, handleValueChange, handleSubmit, handleBlur } = useForm(
    {
      onSubmit: values =>
        editFinancialProjectDetailsRequest({
          id,
          ...composeEditFinancial(values)
        }),
      initialValues,
      validator: projectFinancialInfoValidator(fields),
      validateOnBlur: true
    },
    []
  );

  const inputProps = useTextInputProps({ handleValueChange, handleBlur, values, errors });

  useActionOnSuccess([status], () => {
    financialProjectDetailsRequest({ id });
    hideModal();
  });

  useEffect(() => () => resetEditFinancialProjectDetails(), [resetEditFinancialProjectDetails]);
  return (
    <Form onSubmit={handleSubmit}>
      <NumericFormat
        customInput={NewInput}
        name="soldRevenue"
        type="text"
        label={intl.formatMessage({ id: 'projectFinancialInfo.form.clientBudget.label' })}
        placeholder={intl.formatMessage({ id: 'projectFinancialInfo.form.amount.placeholder' })}
        fullWidth
        required
        {...inputProps(fields.clientBudget)}
        {...numericFormatProps}
      />
      <NumericFormat
        customInput={NewInput}
        name="costsAdjustment"
        type="text"
        label={intl.formatMessage({ id: 'projectFinancialInfo.form.cost.label' })}
        placeholder={intl.formatMessage({ id: 'projectFinancialInfo.form.cost.placeholder' })}
        fullWidth
        required
        disabled={disabled}
        {...inputProps(fields.costsAdjustment)}
        {...numericFormatProps}
      />
      <NumericFormat
        customInput={NewInput}
        name="billedAdjustment"
        type="text"
        label={intl.formatMessage({ id: 'projectFinancialInfo.form.billed.label' })}
        placeholder={intl.formatMessage({ id: 'projectFinancialInfo.form.amount.placeholder' })}
        fullWidth
        required
        disabled={disabled}
        {...inputProps(fields.billedAdjustment)}
        {...numericFormatProps}
      />
      <ButtonsRow>
        <StyledButton
          id="cancelBtn"
          variant={BUTTON_VARIANTS.NEW_SECONDARY}
          isLoading={isLoadingEdit}
          textIntlId="common.cancel"
          onClick={() => hideModal()}
        />
        <StyledButton
          type="submit"
          id="saveBtn"
          variant={BUTTON_VARIANTS.NEW_PRIMARY}
          isLoading={isLoadingEdit}
          textIntlId="common.save"
        />
      </ButtonsRow>
    </Form>
  );
};

ProjectFinancialInfoForm.propTypes = {
  disabled: bool,
  financialProjectDetailsRequest: func,
  hideModal: func,
  id: number,
  initialValues: projectFinancialFormShape
};

export default ProjectFinancialInfoForm;
