import { createReducer } from '@reduxjs/toolkit';

import { getGroups, getResources, getRoleGroups } from 'state/actions/settingsActions';
import { SettingsReducer } from 'types';

const initialState: SettingsReducer = {
  groups: [],
  resources: [],
  roleGroups: undefined
};

export default createReducer(initialState, builder =>
  builder
    .addCase(getGroups.fulfilled, (state, { payload: { groups } }) => {
      state.groups = groups;
    })
    .addCase(getResources.fulfilled, (state, { payload: { accessRoles } }) => {
      state.resources = accessRoles;
    })
    .addCase(getRoleGroups.fulfilled, (state, { payload: { roleGroups } }) => {
      state.roleGroups = roleGroups;
    })
);
