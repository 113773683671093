const ADMIN_USERS_ENDPOINTS = {
  login: '/admin_users/sign_in',
  loginGoogle: '/admin_users/google_sign_in',
  logout: '/admin_users/sign_out',
  signUp: '/admin_users'
};

const NOTIFICATIONS_ENDPOINTS = {
  notifications: '/notifications',
  getNotification: (id: number) => `/notifications/${id}`,
  markNotification: '/notifications/mark_user_notifications_seen',
  acknowledgeNotification: (id: number) => `/notifications/${id}/mark_notification_acknowledged`
};

const ASSIGNMENTS_ENDPOINTS = {
  assignments: (start: string, end: string) => `/assignments?start_date=${start}&end_date=${end}`,
  updateAssignment: (start: string, end: string, id?: number) =>
    `/assignments/${id}?start_date=${start}&end_date=${end}`,
  getAssignment: (id: number) => `/assignments/${id}`,
  getAssignmentNote: (id: number) => `/assignments/notes/${id}`
};

const PEOPLE_ENDPOINTS = {
  people: '/people',
  getPeopleExperience: (id: number, highlighted?: boolean) =>
    `/people/${id}/experiences?highlighted=${highlighted}`,
  getPeopleSoftSkills: (id: number) => `/people/${id}/soft_skills`,
  getPerson: (id: number) => `/people/${id}`,
  gerPersonTechnicalSkill: (id: number) => `/people/${id}/technical_skills`,
  getPersonSpecialEvents: (id: number) => `/people/${id}/special_events`,
  updatePersonSpecialEvents: (personId: number, specialEventId: number) =>
    `/people/${personId}/special_events/${specialEventId}`,
  getPeopleByAssignment: (id: number) => `/search_people/assignments/${id}`
};

const PROJECTS_ENDPOINTS = {
  projects: '/projects',
  minimalProjects: '/projects/minimal',
  getProject: (id?: number) => `/projects/${id}`,
  projectStatuses: '/project_statuses',
  getProjectStatus: (id: number) => `/project_statuses/${id}`,
  getProjectsOverviewTotals: '/project_overview/projects/totals/',
  getProjectsOverview: '/project_overview/projects',
  getProjectOverviewDetail: (id: number) => `/project_overview/projects/${id}`,
  getFinishedAssignments: (id: number) => `/project_details/finished_assignments?project_id=${id}`,
  getProjectAssignments: (id: number) => `/project_timeline/projects/${id}/project_assignments`
};

const FINANCIAL_ENDPOINTS = {
  financialProjects: '/financial/projects',
  getFinancialProject: (id: number) => `/financial/projects/${id}`,
  getFinancialResourceSetup: (id: number) => `/financial/projects/${id}/periods/setups`,
  getProjectClosedPeriods: (id: number) => `/financial/projects/${id}/periods/closed`,
  getProjectOpenedPeriods: (id: number) => `/financial/projects/${id}/periods/opened`,
  getProjectOpenedResources: (id: number) => `/financial/projects/${id}/periods/resources`,
  financialPeriod: '/financial/periods',
  getFinancialPeriod: (id: number) => `/financial/periods/${id}`,
  closeOpenPeriod: (id: number) => `/financial/periods/${id}/close`,
  financialSetups: `/financial/setups`,
  refreshFinancialResourceSetup: (id: number) => `/financial/projects/${id}/setups/refresh`,
  getRefreshedFinancialResourceSetup: (id: number) => `/financial/projects/${id}/setups/refreshed`,
  getExportData: (startDate: string, endDate: string) =>
    `/financial/exports?start_date=${startDate}&end_date=${endDate}`
};

const FORECASTING_ENDPOINTS = {
  getProjectsTotals: '/forecasting/project_totals',
  getProjectsForecasting: '/forecasting/projects',
  getForecastingGraph: '/forecasting/graph'
};

const RESOURCE_PROFILES_ENDPOINTS = {
  getDisciples: '/resource_profiles/disciples',
  getResourceProfile: (id: number) => `/resource_profiles/profiles/${id}`,
  getResourceProfileTimeOffs: (id: number) => `/resource_profiles/profiles/${id}/time_offs`,
  getResourceProfileProjects: (id: number) => `/resource_profiles/profiles/${id}/assignments`,
  getResourceProfileBio: (id: number) => `/integrations/bios?person_id=${id}`,
  getResourceProfileTimeline: (id: number, startDate: string, endDate: string) =>
    `/resource_profiles/profiles/${id}/timeline?start_date=${startDate}&end_date=${endDate}`
};

const DASHBOARD_ENDPOINTS = {
  dashboardPeopleTable: '/allocations_dashboard/people',
  dashboardPeopleAvailabilitiesTable: '/allocations_dashboard/people/availabilities',
  dashboardBenchBreakdownTable: '/bench_breakdown/people',
  dashboardBenchBreakdownSkills: '/bench_breakdown/skills',
  dashboardUtilization: '/dashboard/utilizations',
  dashboardUtilizationQuarters: '/dashboard/utilizations/quarter_blocks',
  dashboardUtilizationCorner: '/dashboard/utilizations/corner',
  dashboardSkills: '/dashboard/skills',
  dashboardUtilizationBench: '/dashboard/utilizations/bench_overview',
  dashboardFinancialOverviews: '/dashboard/financial/overviews',
  dashboardFinancialProjectTypesMargin: '/dashboard/financial/project_types_margins',
  dashboardFinancialRevenues: '/dashboard/financial/revenues',
  dashboardFinancialQuarters: '/dashboard/financial/quarter_blocks',
  dashboardBenchOverview: '/dashboard/bench/overviews',
  dashboardBenchCorner: '/dashboard/bench/corner',
  dashboardBenchQuarters: '/dashboard/bench/quarter_blocks',
  dashboardBenchList: (startDate: string, endDate: string) =>
    `/dashboard/bench/list?start_date=${startDate}&end_date=${endDate}`
};

const SETTINGS_ENDPOINTS = {
  settingsResources: '/settings/access_roles',
  settingsEditRole: (id: number) => `/settings/access_roles/${id}`,
  settingsGroups: '/groups',
  roleGroups: '/settings/role_groups',
  editRoleRelation: (id: number) => `/settings/role_groups/${id}`
};

export const ENDPOINTS = {
  availabilities: '/resource_timeline/resources',
  skills: (allSkills: boolean) => `/skills?all_skill=${allSkills}`,
  clients: '/clients',
  departments: '/departments',
  locations: '/locations',
  roles: '/roles',
  industries: '/industries',
  dashboardPeople: '/dashboard_people',
  assignmentSummary: '/assignment_summaries',
  ...DASHBOARD_ENDPOINTS,
  ...ADMIN_USERS_ENDPOINTS,
  ...ASSIGNMENTS_ENDPOINTS,
  ...NOTIFICATIONS_ENDPOINTS,
  ...PEOPLE_ENDPOINTS,
  ...PROJECTS_ENDPOINTS,
  ...FINANCIAL_ENDPOINTS,
  ...FORECASTING_ENDPOINTS,
  ...RESOURCE_PROFILES_ENDPOINTS,
  ...SETTINGS_ENDPOINTS
};
