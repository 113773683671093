type InputBorderProps = {
  baseColor: string;
  value: string;
  errors: string;
  theme: {
    colors: Record<string, string>;
  };
};

export const inputBorder = ({ baseColor, value, errors, theme: { colors } }: InputBorderProps) => {
  let color = baseColor || colors.gray30;
  if (errors) color = colors.red;
  else if (value) color = colors.gray60;

  return `1px ${color} solid`;
};
export const newInputBorder = ({ baseColor, errors, theme: { colors } }: InputBorderProps) => {
  let color = baseColor || colors.neutral400;
  if (errors) color = colors.feedbackError;
  return `1px ${color} solid`;
};
