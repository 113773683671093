import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import LoadExperience from 'components/user/LoadExperience';
import { useDispatch, useStatus } from 'hooks';
import { saveExperience } from 'state/actions/userActions';

import {
  LoadExperienceContainer,
  LoadExperienceFormContainer,
  LoadExperienceTitle,
  ErrorContainer,
  SuccessContainer,
  StyledWarningSvg,
  StyledSuccessSvg
} from './styles';

const LoadExperiences = () => {
  const saveExperienceRequest = useDispatch(saveExperience);
  const { isPending, isFulfilled, isRejected } = useStatus(saveExperience);
  const { userId, token, departmentId } = useParams();

  return (
    <LoadExperienceContainer>
      {isRejected && (
        <ErrorContainer>
          <StyledWarningSvg />
          <FormattedMessage id="resource.experience.genericErrorMessage" />
        </ErrorContainer>
      )}
      {isFulfilled && (
        <SuccessContainer>
          <StyledSuccessSvg />
          <FormattedMessage id="resource.experience.genericSuccessMessage" />
        </SuccessContainer>
      )}
      <LoadExperienceFormContainer>
        <LoadExperienceTitle>
          <FormattedMessage id="resource.experience.title" />
        </LoadExperienceTitle>
        <LoadExperience
          userId={userId}
          token={token}
          departmentId={departmentId}
          onSubmit={saveExperienceRequest}
          isLoading={isPending}
          supressErrorMessage
        />
      </LoadExperienceFormContainer>
    </LoadExperienceContainer>
  );
};

export default LoadExperiences;
