import { AxiosResponse } from 'axios';

import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';
import { AdminUser, GoogleAdminUser, UserResponse, NewAdminUser, SuccessResponse } from 'types';

class UserService {
  static login(adminUser: AdminUser): Promise<AxiosResponse<{ adminUser: UserResponse }>> {
    return httpClient.post(ENDPOINTS.login, { adminUser });
  }

  static loginGoogle(
    adminUser: GoogleAdminUser
  ): Promise<AxiosResponse<{ adminUser: UserResponse }>> {
    return httpClient.post(ENDPOINTS.loginGoogle, adminUser);
  }

  static logout(): Promise<AxiosResponse<SuccessResponse>> {
    return httpClient.delete(ENDPOINTS.logout, { data: {} });
  }

  static signUp(user: NewAdminUser): Promise<AxiosResponse<UserResponse>> {
    return httpClient.post(ENDPOINTS.signUp, user);
  }
}

export default UserService;
