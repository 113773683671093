import styled from 'styled-components';

export const StyledButton = styled.button`
  ${({ theme }) => theme.typography.regular};
  color: ${({ theme }) => theme.colors.gray80};
  font-size: 1.4rem;
  display: flex;
  align-content: center;
  padding: 1rem;
  border-radius: 5rem;
  border: ${({ theme }) => `1px ${theme.colors.gray30} solid`};
  cursor: pointer;
`;

export const SelectorContainer = styled.div`
  width: 50rem;
`;
