import ResourceProfilesService from 'services/resourceProfiles';
import { AsyncThunkConfig, ErrorAsyncThunk, ResourceRequestType } from 'types';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';

export const getDisciples = createAsyncThunk('GET_DISCIPLES', async () => {
  try {
    const { data } = await ResourceProfilesService.getDisciples();
    return data;
  } catch (error) {
    const {
      response: { data }
    } = error as ErrorAsyncThunk;
    throw parseError(data);
  }
});

export const getResourceProfile = createAsyncThunk(
  'GET_RESOURCE_PROFILE',
  async ({ id }: ResourceRequestType) => {
    try {
      const { data } = await ResourceProfilesService.getResourceProfile(id);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getResourceProfileTimeOffs = createAsyncThunk(
  'GET_RESOURCE_PROFILE_TIME_OFFS',
  async ({ id }: ResourceRequestType) => {
    try {
      const { data } = await ResourceProfilesService.getTimeOffs(id);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getResourceProfileProjects = createAsyncThunk(
  'GET_RESOURCE_PROFILE_PROJECTS',
  async ({ id }: ResourceRequestType) => {
    try {
      const { data } = await ResourceProfilesService.getProjects(id);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const editResourceProfile = createAsyncThunk(
  'EDIT_RESOURCE_PROFILE',
  async ({ id, payload }: ResourceRequestType, { dispatch }: AsyncThunkConfig) => {
    try {
      const { data } = await ResourceProfilesService.editResourceProfile(id, payload);
      dispatch(getResourceProfile({ id }));
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getResourceMiniTimeline = createAsyncThunk(
  'GET_RESOURCE_PROFILE_TIMELINE',
  async ({ id, startDate, endDate }: ResourceRequestType) => {
    try {
      const {
        data: { timelineData: data }
      } = await ResourceProfilesService.getMiniTimeline(id, startDate, endDate);
      return data;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);

export const getResourceBio = createAsyncThunk(
  'GET_RESOURCE_BIO',
  async ({ id }: ResourceRequestType, { dispatch }: AsyncThunkConfig) => {
    try {
      const {
        data: { process }
      } = await ResourceProfilesService.getResourceBio(id);
      if (process) {
        const { payload } = await dispatch(getResourceProfile({ id }));
        return payload;
      }
      return process;
    } catch (error) {
      const {
        response: { data }
      } = error as ErrorAsyncThunk;
      throw parseError(data);
    }
  }
);
