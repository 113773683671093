import { arrayOf, bool, func, number, object, oneOfType, shape, string } from 'prop-types';
import { Fragment, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import CenteredLoading from 'components/common/Loading/CenteredLoading';
import PeriodDetail from 'components/Financial/PeriodDetail';
import Cell from 'components/ProjectsOverview/ProjectsTable/Cell';
import { SORT_DIR } from 'constants/constants';
import {
  closePeriodHeaderShape,
  periodDetailShape,
  sortableTableColumnShape
} from 'constants/shapes';
import { sortTable } from 'utils/projectsOverviewUtilities';

import OptionsClosePeriodButton from '../OptionsClosePeriodButton';
import {
  ArrowButton,
  ArrowColumn,
  ArrowIcon,
  Column,
  ExpandedRow,
  HeaderColumnContainer,
  OptionsColumn,
  Row,
  Table,
  TableWrapper
} from './styles';

const PeriodNewTable = ({
  expandedRows,
  setExpandedRows,
  activeTab,
  periods = [],
  columns,
  isLoading,
  handleEditPeriod,
  projectId,
  periodDetailCopy,
  setPeriodDetailCopy,
  editId,
  setEditId
}) => {
  const intl = useIntl();
  const [sortConfig, setSortConfig] = useState({
    key: 'assignments',
    direction: SORT_DIR.desc
  });

  const bodyColumns = columns.slice(1);

  const expandedDetail = periodId => {
    setEditId(null);
    setExpandedRows(current => ({
      ...current,
      [periodId]: !expandedRows?.[periodId]
    }));
  };

  const handlePeriodDetail = periodId => {
    if (!expandedRows || (expandedRows && !expandedRows[periodId])) expandedDetail(periodId);
    setEditId(periodId);
  };

  const onSort = ({ key: columnKey }) => {
    let direction = SORT_DIR.desc;
    if (sortConfig && sortConfig.key === columnKey && sortConfig.direction === SORT_DIR.desc) {
      direction = SORT_DIR.asc;
    }
    setSortConfig({
      key: columnKey,
      direction
    });
  };

  const sortedProjects = useMemo(() => {
    if (!sortConfig) return periods;
    const sortedRows = sortTable({ columns, sortConfig, rows: periods });
    return sortedRows;
  }, [columns, periods, sortConfig]);

  return (
    <>
      <TableWrapper>
        <Table>
          <thead>
            <Row height="5.7rem" isHeader>
              {columns?.map(column => {
                const isSorting = sortConfig && sortConfig.key === column.key;
                return (
                  <th key={column.key}>
                    <HeaderColumnContainer
                      onClick={() => !isLoading && onSort(column)}
                      $disabled={isLoading}
                    >
                      <span>{column.header && intl.formatMessage({ id: column.header })}</span>
                      <Column width="1.2rem">
                        <ArrowIcon
                          direction={isSorting ? sortConfig.direction : SORT_DIR.desc}
                          $isSorting={!isLoading && isSorting}
                          disabled={isLoading}
                        />
                      </Column>
                    </HeaderColumnContainer>
                  </th>
                );
              })}
              <td />
            </Row>
          </thead>
          <tbody>
            {(!isLoading || sortedProjects) &&
              sortedProjects?.map(sortedPeriod => {
                const periodId = sortedPeriod?.id;
                const isExpanded = expandedRows?.[periodId] || false;

                return (
                  <Fragment key={periodId}>
                    <Row key={periodId} $isExpanded={isExpanded}>
                      <ArrowColumn>
                        <ArrowButton
                          $isExpanded={isExpanded}
                          onClick={() => expandedDetail(periodId)}
                        />
                        <Cell
                          value={sortedPeriod?.name}
                          columnKey="name"
                          key={`${periodId}-name`}
                        />
                      </ArrowColumn>
                      {Object.values(bodyColumns).map(({ key }) => (
                        <Cell
                          value={
                            // eslint-disable-next-line no-nested-ternary
                            key === 'periodLength'
                              ? {
                                  startDate: sortedPeriod?.startDate,
                                  endDate: sortedPeriod?.endDate
                                }
                              : key === 'billed'
                              ? {
                                  billed: sortedPeriod?.billed,
                                  discountIcon: sortedPeriod?.globalDiscountPercentage,
                                  additionalCharges:
                                    sortedPeriod?.fixedPrice + sortedPeriod?.additional || 0
                                }
                              : sortedPeriod[key]
                          }
                          columnKey={key}
                          key={`${periodId}-${key}`}
                        />
                      ))}
                      <OptionsColumn $isExpanded={isExpanded}>
                        <OptionsClosePeriodButton
                          projectId={projectId}
                          periodId={periodId}
                          onEditPeriod={handleEditPeriod}
                          onOpenPeriodDetails={handlePeriodDetail}
                        />
                      </OptionsColumn>
                    </Row>
                    <ExpandedRow $isExpanded={isExpanded}>
                      {isExpanded && (
                        <Column colSpan={columns.length + 2} height="100%" padding="0">
                          <PeriodDetail
                            activeTab={activeTab}
                            periodId={periodId}
                            setPeriodDetailCopy={setPeriodDetailCopy}
                            periodDetailCopy={periodDetailCopy}
                            isEdit={editId === periodId}
                          />
                        </Column>
                      )}
                    </ExpandedRow>
                  </Fragment>
                );
              })}
          </tbody>
        </Table>
      </TableWrapper>
      {isLoading && <CenteredLoading />}
    </>
  );
};

PeriodNewTable.propTypes = {
  expandedRows: object,
  setExpandedRows: func,
  activeTab: string,
  periods: arrayOf(closePeriodHeaderShape),
  columns: arrayOf(sortableTableColumnShape),
  isLoading: bool,
  handleEditPeriod: func,
  projectId: oneOfType([func, shape({ current: number })]),
  periodDetailCopy: arrayOf(periodDetailShape),
  setPeriodDetailCopy: func,
  editId: oneOfType([number, string]),
  setEditId: func
};
export default PeriodNewTable;
