import { DateRange } from 'types';

import { dayJSToDate } from './date';

type ComposeEditDetailsParams = {
  englishLevel: {
    value: string;
  };
  bioLink: string;
  skills: {
    skillValue: {
      value: { id: number };
    };
    years: number;
    months: number;
    experienceId: number;
  }[];
};

export const composeEditDetails = ({
  englishLevel,
  bioLink,
  skills
}: ComposeEditDetailsParams) => ({
  englishLevel: englishLevel?.value,
  figmaBio: bioLink,
  experiences: skills?.map(({ skillValue, years, months, experienceId }) => ({
    years: +years,
    months: +months,
    skillId: skillValue?.value?.id,
    ...(experienceId && { experienceId })
  }))
});

type SoftSkill = {
  name: string;
  skillName: string;
};

export const composeUpdateSoftSkills = (skills: SoftSkill[]) => ({
  softSkills: skills.map(skill => ({ name: skill?.name || skill?.skillName }))
});

export const composeAddSoftSkill = ({ skill }: { skill: SoftSkill }) => ({
  softSkills: [{ name: skill }]
});

type TechnicalSkill = {
  id: number;
};

export const composeUpdateTechnicalSkills = (skills: TechnicalSkill[]) => ({
  experiences: skills.map(skill => ({ ...skill, experienceId: skill.id }))
});

type ComposeAddTechnicalSkillParams = {
  months: number;
  years: number;
  value: {
    value: {
      id: number;
    };
  };
};

export const composeAddTechnicalSkill = ({
  months,
  years,
  value
}: ComposeAddTechnicalSkillParams) => ({
  experiences: [{ months: +months, years: +years, skillId: value?.value?.id }]
});

type ComposeTrainingConversionSkillParams = {
  dates: DateRange;
  skill: {
    value: {
      id: number;
    };
  };
  value: {
    value: {
      id: number;
    };
  };
  specialEvent: string;
};

export const composeTrainingConversionSkill = ({
  dates,
  skill,
  specialEvent
}: ComposeTrainingConversionSkillParams) => ({
  specialEventType: specialEvent,
  skillId: skill.value.id,
  startDate: dates.startDate,
  endDate: dates.endDate
});

type ComposeEditTrainingSkillParams = DateRange & {
  id: number;
  skillId: number;
  skillName: string;
  specialEventType: string;
};

export const composeEditTrainingSkill = ({
  startDate,
  endDate,
  skillName,
  specialEventType,
  skillId,
  id
}: ComposeEditTrainingSkillParams) => ({
  dates: { startDate: dayJSToDate(startDate), endDate: dayJSToDate(endDate) },
  skill: { value: { id: skillId }, label: skillName },
  specialEvent: specialEventType,
  specialEventId: id
});
